import React, { useLayoutEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Hidden,
  Button,
  Divider,
  CardContent
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavSecondary from '../../components/Navigation/NavSecondary';
import Banner from '../../components/Banner/Banner'
// import FluidBanner from '../../components/Banner/FluidBanner'
import VisibilitySensor from 'react-visibility-sensor';
import TreeView from '../../components/TreeView/TreeView';
import Pricing from '../../components/Pricing/Pricing';
import AnimatedElement from './SalesActivity/AnimatedElement.js';
import { Pause, WindupChildren } from "windups";
import imac2ipad from '../../static/public/mockups/imac-2ipad-reduce.png'
import privateTarget from '../../static/public/3d-private-target.png'
import targetEditModal from '../../static/public/target-edit.png'
import ipadMockups from '../../static/public/mockups/ipad-mockups-reduce.png'
// import Home1 from "../../static/public/mockups/home1.png";
import Home2 from "../../static/public/mockups/home2.png";
import slogen from "../../static/public/szlogen_1.png";
import resultOriented from "../../static/public/eredmenyorientaltan.png";
import focus_new from "../../static/public/focus_new.png";
import focus2 from "../../static/public/focus2.png";
import PhoneIcon from '@material-ui/icons/Phone';
import TodayIcon from '@material-ui/icons/Today';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles(theme => ({
  targetCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 225,
    width: 175,
    backgroundColor: 'white',
    border: '2px dashed lightgrey',
    position: 'absolute',
    top: 500,
    right: 415,
    boxShadow: '10px 12px 84px -3px rgba(0,0,0,0.24)',
    transform: 'rotate(-3deg)',
    //transform: 'rotate3d(3, 45, 1, 180deg)',
    transition: '1s',
    '&:hover': {
      transform: 'scale(1.05) rotate(-4deg)',
      transition: '0.15s',
    }
  },
  targetCardDefault: {
    transition: '1s',
    transform: 'scale(0.45) rotate3d(13, 45, 1, 37deg) perspective(700px)',
    animationDelay: '2s',
    transitionDelay: '2s',
    opacity: 0.75,
    boxShadow: 'none',
    width: 150,
    top: 325,
    right: 450,
    pointerEvents: 'none'
  },
  circle: {
    border: '2px dashed grey',
    transition: '0.35s',
    opacity: 0.3,
    height: 80,
    width: 80,
    borderRadius: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svgLine: {
    position: 'absolute',
    top: 550,
    left: 650,
    width: 500,
    height: 1500,
  },
  parent: {
    position: 'relative',
  },
  chalk: {
    position: 'absolute',
    transform: 'rotate(5deg)',
    top: 510,
    left: 820,
    fontFamily: 'Indie Flower',
  },
  targetEdit: {
    transform: 'rotate(5deg)'
  },
  image: {
    width: '100%',
    height: '100%',
  },
  imageLayout: {
    width: '100%',
    height: '100%',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  gradientText: {
    background: "-webkit-linear-gradient(45deg, #56cac6 25%, #ffac3f 40%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  gradientTitle: {
    background: "-webkit-linear-gradient(45deg, #56cac6 30%, #07B1AB 50%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  button: {
    background: '#FFAC3F',
    borderRadius: 30,
    border: 0,
    color: 'white',
    padding: '10px 30px',
  }
}));

const prices = [
  {
    title: 'Egyéni',
    price: {
      monthly: '€ 22 / hó',
      yearly: '€ 21 / hó',
    },
    note: 'Az előfizetői díj nettó ár, az ÁFÁ-t nem tartalmazza',
    description: [
      'Céleszköz, napi használat',
      'Egyszerű és hatékony',
      'Mobiliroda a zsebemben',
      'Professzionális munkavégzés',
      'Tökéletes folyamatok'
    ],
    buttonText: 'Vásárlás',
    buttonVariant: 'contained',
    href: "https://my.aocsystem.com/ac/registration",
    delay: '0',
    dialog: {
      title: 'Értékesítői nézet',
      descriptions: [
        'Tervezz és valósítsd meg céljaidat. Élvezd a rendszer nyújtotta számtalan funkciót. Egyszerű, gyors, könnyen kezelhető. Folyamatos sikerek és eredmények.',
      ],
      monthlyPlan: 'Havi előfizetési díj',
      yearlyPlan: 'Éves előfizetési díj',
      notes: ["Az előfizetői díj felhasználónként nettó ár, az ÁFÁ-t nem tartalmazza. Éves előfizetés esetén 5% kedvezményt biztosítunk."],
      tables: [{
        columns: [
          { title: "", variant: "h6", align: "" },
          { title: "Funkciók", variant: "h5", align: "left" },
        ],
        rows: [
          {
            name: "Dashboard",
            details: [true]
          },
          {
            name: "Naptár",
            details: [true]
          },
          {
            name: "Feladatmenedzsment",
            details: [true]
          },
          {
            name: "Ügyfélnyilvántartás",
            details: [true]
          },
          {
            name: "Sales Activity Basic tárgyalási folyamat",
            details: [true]
          },
          {
            name: "Dokumentumtár",
            details: [true]
          },
          {
            name: "Statisztika",
            details: [true]
          },
          {
            name: "Valós idejű adatok",
            details: [true]
          },
          {
            name: "Értesítési központ",
            details: [true]
          },
          {
            name: "Szoftverfelhasználói támogatás",
            details: [true]
          },
        ]
      }]
    }
  },
  {
    title: 'Csoport',
    //subheader: 'Legnépszerűbb',
    price: {
      monthly: '€ 380 / hó',
      yearly: '€ 360 / hó',
    },
    note: 'Az előfizetői díj nettó ár, az ÁFÁ-t nem tartalmazza',
    description: [
      'Tökéletes csapatmunka',
      '20 felhasználó',
      'Tervezz és fejlessz',
      'Fókuszban az aktivitás',
      'Azonnali visszacsatolás'
    ],
    buttonText: 'Érdekel',
    buttonVariant: 'contained',
    href: "https://my.aocsystem.com/ac/company?type=company",
    delay: '150',
    dialog: {
      title: 'Csoport',
      descriptions: [
        'A rendszer egy teljes körű, felhő alapú szoftvermegoldás, amely elősegíti a hatékony és professzionális értékesítői munkát. A szoftver alkalmas kis vállalati környezetben dolgozók irányítására, menedzselésére, ösztönzésére, fejlesztésére, követi és támogatja az összes alkalmazott és külső értekesítő teljes munkafolyamatát. A vezető számára valós idejű statisztikákat és üzleti kimutatásokat készít, automatikusan a teljes csoportra vagy egyénre bontva egyaránt. Támogatunk, hogy az egekbe repítsd az eladási mutatókat.',
      ],
      monthlyPlan: 'Havi előfizetési díj',
      yearlyPlan: 'Éves előfizetési díj',
      notes: [
        "Az előfizetői csomagdíj nettó ár, az ÁFÁ-t nem tartalmazza.",
        "* Éves díjfizetés esetén a havi előfizetési díj csak € 360 / hó, továbbá ajándékba adunk egy a cégedre szabott egyedi tárgyalási folyamatot. Az alapot mi adjuk, tetszőlegesen kiegészítheted további lépésekkel és tartalommal.",
        "Az előfizetői csomag 20 fő nevesített felhasználót tartalmaz, igény esetén további + 20 fő felhasználóval bővíthető."
      ],
      tables: [
        {
          columns: [
            { title: "Funkciók", variant: "h6", align: "left" },
            { title: "Felsővezetői nézet", variant: "h5", align: "right" },
            { title: "Csoportvezetői nézet", variant: "h5", align: "right" },
            { title: "Értékesítői nézet", variant: "h5", align: "right" },
            { title: "Call-Center nézet", variant: "h5", align: "right" },
          ],
          rows: [
            {
              name: "Felhasználók száma",
              tooltip: "Az előfizetésben foglalt felhasználói fiókok száma",
              details: ['1 fő', '3 fő', '15 fő', '1 fő']
            },
            {
              name: "További felhasználók",
              tooltip: "Hozzáadható további felhasználók száma",
              details: ['igény esetén további + 20 fővel bővíthető']
            },
            {
              name: "Dashboard",
              details: [true, true, true, true]
            },
            {
              name: "Naptár",
              details: [false, true, true, true]
            },
            {
              name: "Irodafoglalás",
              details: [false, true, true, true]
            },
            {
              name: "Feladatmenedzsment",
              details: [false, true, true, true]
            },
            {
              name: "Ügyfélnyilvántartás",
              details: [false, true, true, true]
            },
            {
              name: "Egyedi tárgyalási folyamat",
              details: ['1 db cégre szabott tárgyalási folyamat*']
            },
            {
              name: "További egyedi tárgyalási folyamatok",
              details: ['opcionális']
            },
            {
              name: "Dokumentumtár",
              details: [true, true, true, true]
            },
            {
              name: "Dokumentumfeltöltés",
              details: [true, true, true, true]
            },
            {
              name: "Export, import",
              details: [true, true, true, true]
            },
            {
              name: "Videótár",
              details: [true, true, true, true]
            },
            {
              name: "Értesítési központ",
              details: [true, true, true, true]
            },
            {
              name: "Értesítési-, üzenetküldés",
              details: [true, true, true, true]
            },
            {
              name: "Valós idejű adatok",
              details: [true, true, true, true]
            },
            {
              name: "Csoportkialakítás",
              details: ['tetszőleges virtuális struktura kialakítása']
            },
            {
              name: "Vállalati hierarchia",
              details: [true, true, true, true]
            },
            {
              name: "Jogosultsági rendszer",
              details: [true, true, true, true]
            },
            {
              name: "Egyéni statisztika",
              details: [true, true, true, true]
            },
            {
              name: "Strukturált statisztika",
              details: [true, true, true, 'hamarosan']
            },
            {
              name: "Egyéni tervezés",
              details: [true, true, true, 'hamarosan']
            },
            {
              name: "Strukturált tervezés",
              details: [true, true, true, 'hamarosan']
            },
            {
              name: "Asszisztensi nézet",
              details: ['hamarosan']
            },
            {
              name: "Külső felhasználók bekapcsolása",
              details: ['hamarosan']
            },
            {
              name: "Oktatás, képzés, támogatás",
              details: ['opcionális']
            },
            {
              name: "Rendszerintegráció",
              details: ['opcionális']
            },
            {
              name: "Egyedi idények, további fejlesztések",
              details: ['opcionális']
            },
          ]
        }
      ]
    }
  },
  {
    title: 'Vállalat',
    titleColor: '#f5f5f7',
    backgroundColor: 'linear-gradient(360deg, rgba(255,255,255,0.75) 0%, rgba(7,177,171,0.20) 90%)',
    note: 'Cégre szabott ajánlat nagyobb vállalatoknak, minimum 50 fő felett',
    price: {
      monthly: 'Egyedi árképzés',
      yearly: 'Egyedi árképzés',
    },
    description: [
      'Rendszerben való gondolkodás',
      'Tetszőleges struktúra',
      'Egyedi tárgyalási folyamatok',
      'Valós idejű adatok',
      'Hosszú távú ügyfélelégedettség'
    ],
    buttonText: 'Érdekel',
    buttonVariant: 'contained',
    href: "https://my.aocsystem.com/ac/company?type=enterprise",
    delay: '250',
    dialog: {
      title: 'Vállalat',
      descriptions: [
        'A rendszer egy teljes körű, felhő alapú szoftvermegoldás, amely elősegíti a hatékony és professzionális értékesítői munkát.',
        'A szoftver alkalmas kis vállalati környezetben dolgozók irányítására, menedzselésére, ösztönzésére, fejlesztésére, követi és támogatja az összes alkalmazott és külső értekesítő teljes munkafolyamatát. A vezető számára valós idejű statisztikákat és üzleti kimutatásokat készít, automatikusan a teljes csoportra vagy egyénre bontva egyaránt. \nA Vállalati csomag cégre szabott, egyedi megoldásokat kínál, min. 50 felhasználótól igényelhető.',
        'A Vállalati csomag cégre szabott, egyedi megoldásokat kínál, min. 50 felhasználótól igényelhető.'
      ],
      monthlyPlan: 'Havi előfizetési díj',
      yearlyPlan: 'Éves előfizetési díj',
      notes: [
        'A Vállalati csomag cégre szabott, egyedi megoldásokat kínál, minimum 50 felhasználótól igényelhető. Vedd fel a kapcsolatot az értékesítési osztályunkkal.',
      ],
      tables: [{
        columns: [
          { title: "Funkciók", variant: "h6", align: "left" },
          { title: "Felsővezetői nézet", variant: "h5", align: "right" },
          { title: "Csoportvezetői nézet", variant: "h5", align: "right" },
          { title: "Értékesítői nézet", variant: "h5", align: "right" },
          { title: "Call-Center nézet", variant: "h5", align: "right" },
        ],
        rows: [
          {
            name: "Felhasználók száma",
            details: ['egyedi megoldás, tetszőleges struktúra kialakítása, min. 50 főtől']
          },
          {
            name: "További felhasználók",
            details: ['igény esetén, korlátlan felhasználóval bővítető']
          },
          {
            name: "Dashboard",
            details: [true, true, true, true]
          },
          {
            name: "Naptár",
            details: [false, true, true, true]
          },
          {
            name: "Irodafoglalás",
            details: [false, true, true, true]
          },
          {
            name: "Feladatmenedzsment",
            details: [false, true, true, true]
          },
          {
            name: "Ügyfélnyilvántartás",
            details: [false, true, true, true]
          },
          {
            name: "Egyedi tárgyalási folyamat",
            details: ['opcionális']
          },
          {
            name: "További egyedi tárgyalási folyamatok",
            details: ['opcionális']
          },
          {
            name: "Dokumentumtár",
            details: [true, true, true, true]
          },
          {
            name: "Dokumentumfeltöltés",
            details: [true, true, true, true]
          },
          {
            name: "Export, import",
            details: [true, true, true, true]
          },
          {
            name: "Videótár",
            details: [true, true, true, true]
          },
          {
            name: "Értesítési központ",
            details: [true, true, true, true]
          },
          {
            name: "Értesítési-, üzenetküldés",
            details: [true, true, true, true]
          },
          {
            name: "Valós idejű adatok",
            details: [true, true, true, true]
          },
          {
            name: "Csoportkialakítás",
            details: ['tetszőleges virtuális csoport kialakítása']
          },
          {
            name: "Vállalati hierarchia",
            details: ['tetszőleges virtuális hierarchia kialakítása']
          },
          {
            name: "Jogosultsági rendszer",
            details: [true, true, true, true]
          },
          {
            name: "Egyéni statisztika",
            details: [true, true, true, true]
          },
          {
            name: "Strukturált statisztika",
            details: [true, true, true, 'hamarosan']
          },
          {
            name: "Egyéni tervezés",
            details: [true, true, true, 'hamarosan']
          },
          {
            name: "Strukturált tervezés",
            details: [true, true, true, 'hamarosan']
          },
          {
            name: "Asszisztensi nézet",
            details: ['hamarosan']
          },
          {
            name: "Külső felhasználók bekapcsolása",
            details: ['hamarosan']
          },
          {
            name: "Oktatás, képzés, támogatás",
            details: ['opcionális']
          },
          {
            name: "Rendszerintegráció",
            details: ['opcionális']
          },
          {
            name: "Egyedi idények, további fejlesztések",
            details: ['opcionális']
          },
        ]
      }
      ]
    }
  },
];

const dataFlow = {
  name: 'Tárgyalási folyamatok',
  hover: false,
  attributes: {
  },
  children: [
    {
      name: '',
      children: [
        {
          name: '',
          children: [
            {
              name: '',
              children: [
                {
                  name: '',
                  last: true,
                  children: [
                  ],
                }
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Hozzájáruló nyilatkozat',
      children: [
        {
          name: 'Személyes adatok',
          children: [
            {
              name: 'Pénzügyi áttekintés',
              children: [
                {
                  name: 'Célok',
                  children: [
                    {
                      name: 'Befektetési időtáv',
                      children: [
                        {
                          name: 'Portfólió',
                          children: [
                            {
                              name: 'Pénzügyi javaslat',
                              children: [
                                {
                                  name: 'Ajánlás',
                                  children: [
                                    {
                                      name: 'Összegzés',
                                      children: [
                                        {
                                          name: 'Tárgyalási összefoglaló',
                                          last: true
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

function SalesActivity() {
  const { t, } = useTranslation();
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1200px');
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const ref = useRef();
  let targetDevice = useRef(null)
  let product = useRef(null)
  ScrollTrigger.saveStyles(targetDevice)

  document.title = t('Title.main') + " | " + t('Title.subTitle.salesActivity')

  useLayoutEffect(() => {
    // ScrollTrigger.refresh(true)
    setTimeout(() => {
      ScrollTrigger.refresh()
    }, 500);

    gsap.to(".horizontalImage", {
      xPercent: -100,
      x: () => window.innerWidth - 500,
      ease: "none",
      scrollTrigger: {
        markers: false,
        trigger: ".horizontalImage",
        start: "center center",
        end: () => window.innerWidth * 3.5 + 1500,
        scrub: 0.5,
        // invalidateOnRefresh: true,
        pin: true,
        // anticipatePin: 1
      }
    })

    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh()
      } else {
        img.addEventListener('load', imgLoaded => ScrollTrigger.refresh())
      }
    })
  }, [])

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  return (
    <Paper
      style={{ overflow: 'hidden', }}
    >
      <Box
        className={classes.box}
        sx={{
          background: 'linear-gradient(150deg, rgba(255,255,255,1) 0%, rgba(86,202,198,0.25) 20%, rgba(255,255,255,1) 58%, rgba(86,202,198,0.25) 79%, rgba(255,255,255,1) 98%)',
          //minHeight: '100vh',
        }}
        py={{
          lg: 25,
          md: 20,
          xs: 15,
        }}
      >
        <NavSecondary
          title={t('products.salesActivity.title')}
          color='secondary'
          buttonColor='primary'
          buyUrl="https://my.aocsystem.com/signup"
          salesActivity
          anchors={
            [
              { text: t('navigation.home'), href: 'description', offset: -280 },
              { text: t('products.salesActivity.navigation.advantages'), href: 'advantages', offset: -100 },
              { text: t('products.salesActivity.navigation.workflow'), href: 'customWorkflow', offset: -250 },
              { text: t('products.salesActivity.navigation.prices'), href: 'prices', offset: 0 },
            ]
          }
        />
        <Container>
          <Grid
            container
            className='main'
            spacing={5}
            alignItems='flex-start'
            justifyContent='center'
          >
            <Grid
              item
              xl={7}
              lg={7}
              md={6}
              sm={12}
              xs={12}
              mt={2}
            >
              <Typography
                marginBottom={8}
                variant='h1'
                className={classes.gradientTitle}
                fontWeight={'bold'}
                data-aos="fade-right"
              >
                {t('products.salesActivity.subtitle')}
              </Typography>
              <Typography
                variant='h4'
                data-aos-delay={150}>{t('products.salesActivity.mainDescription')}
              </Typography>
            </Grid>
            <Grid
              item
              xl={5}
              lg={5}
              md={6}
              sm={8}
              xs={10}
              mt={{
                md: -10
              }}
              data-aos="fade-left"
            >
              <img
                alt={'imac-macbook-mockup'}
                src={imac2ipad}
                style={
                  matches ? {
                    width: '165%',
                    minHeight: 600,
                  } : {
                    width: '165%',
                  }
                }
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            justifyContent="center"
            textAlign="center"
            id="description"
            mt={matches && 10}
          >
            <Grid
              item
              xs={10}
            >
              <Divider variant="middle" sx={{ margin: '80px auto', maxWidth: '450px', }} />
              <Typography variant='h4' marginBottom='30'>
                A szoftver alkalmas kis-, közép-, és nagyvállalati környezetben dolgozók irányítására, menedzselésére, ösztönzésére, fejlesztésére, követi és támogatja az összes alkalmazott és külső értékesítő teljes munkafolyamatát.
              </Typography>
              <Divider variant="middle" sx={{ margin: '80px auto', maxWidth: '450px', }} />
            </Grid>
          </Grid>
        </Container>
        <Hidden smDown>
          <Grid item mt={10}>
            {/* <FluidBanner></FluidBanner> */}
          </Grid>
        </Hidden>
        <Container>
          <Grid
            className='main'
            container
            maxWidth
            // style={{ minHeight: '100vh' }}
            direction='column'
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              py={10}
            >
              <Grid
                container
                display="flex"
                justifyContent={smDown ? 'center' : 'flex-end'}
              >
                <Typography
                  marginBottom={10}
                  variant="h2"
                  fontSize={smDown && '2em'}
                  color="secondary"
                  fontWeight="bold"
                // textAlign={mdDown ? 'flex-start' : 'flex-end'}
                >
                  Eredményorientáltan
                </Typography>
              </Grid>
              <Grid
                container
                mb={matches ? 40 : 20}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  mr={matches ? 5 : 0}
                  data-aos="fade-right"
                >
                  <img
                    alt="Home1"
                    src={resultOriented}
                    width={matches ? '125%' : '100%'}
                    style={{ marginLeft: matches ? -80 : 0, borderRadius: '24px' }}
                  />
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  mt={!matches && 5}
                >
                  <Grid display='flex' alignItems='center' marginBottom={3}>
                    <Typography variant="h4">
                      A rendszer a vállalati hierarchia minden vezetője számára valós idejű statisztikákat és üzleti kimutatásokat készít, automatikusan a teljes cégre, csoportra vagy egyénre bontva.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* IMAGE_01 END */}

              {/* IMAGE_02 */}
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between'
                }}
              >
                <Grid
                  item
                  data-aos="fade-up"
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                >
                  <Typography
                    marginBottom={5}
                    variant="h2"
                    color='primary'
                    fontWeight='bold'
                    data-aos="fade-up"
                  >
                    Fókusz
                  </Typography>
                  <Grid
                    display='flex'
                    alignItems='center'
                    marginBottom={3}
                  >
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                    >
                      Minden pillanat számít.
                    </Typography>
                  </Grid>
                  <Grid
                    display='flex'
                    alignItems='center'
                    marginBottom={3}
                  >
                    <Typography variant="h4">
                      Élvezd a szoftver nyújtotta számtalan intelligens és automatizált funkciót, amelyek támogatnak, hogy elérd a célodat.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                >
                  <img
                    alt="focus"
                    src={focus_new}
                    dataAos='fade-left'
                    dataAosDelay='300'
                    width={matches ? '120%' : '100%'}
                    style={{ marginLeft: matches ? -50 : 0 }}
                  />
                </Grid>
                <Grid
                  item
                  display='flex'
                  alignItems='center'
                  marginBottom={3}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={12}
                  mt={10}
                >
                  <Typography variant="h4">
                    Koncentrálj azokra a feladatokra, amelyek hozzájárulnak céged növekedéséhez. Amikor a munka folyik, a bevétel nő!
                  </Typography>
                </Grid>
                <Grid
                  container
                  mt={5}
                  spacing={2}
                  display="flex"
                  justifyContent={smDown ? 'center' : 'flex-start'}
                >
                  <Grid item>
                    <Card
                      sx={{
                        boxShadow: 0,
                        border: '1px solid #FFAC3F',
                        borderRadius: '24px',
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          height={150}
                          width={215}
                        >
                          <TodayIcon
                            color="primary"
                            sx={{ border: '1px solid #FFAC3F', borderRadius: '24px', padding: '10px', fontSize: '3em', mb: 2 }}
                          />
                          <Typography>
                            Időpontegyeztetés
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      sx={{
                        boxShadow: 0,
                        border: '1px solid #FFAC3F',
                        borderRadius: '24px',
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          height={150}
                          width={215}
                        >
                          <BusinessCenterIcon
                            color="primary"
                            sx={{ border: '1px solid #FFAC3F', borderRadius: '24px', padding: '10px', fontSize: '3em', mb: 2 }}
                          />
                          <Typography>
                            Találkozók megszervezése
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      sx={{
                        boxShadow: 0,
                        border: '1px solid #FFAC3F',
                        borderRadius: '24px',
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          height={150}
                          width={215}
                        >
                          <PeopleAltIcon
                            color="primary"
                            sx={{ border: '1px solid #FFAC3F', borderRadius: '24px', padding: '10px', fontSize: '3em', mb: 2 }}
                          />
                          <Typography>
                            Tárgyalási folyamatok
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      sx={{
                        boxShadow: 0,
                        border: '1px solid #FFAC3F',
                        borderRadius: '24px',
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          height={150}
                          width={215}
                        >
                          <PhoneIcon
                            color="primary"
                            sx={{ border: '1px solid #FFAC3F', borderRadius: '24px', padding: '10px', fontSize: '3em', mb: 2 }}
                          />
                          <Typography>
                            Ajánlás
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mt={matches ? 30 : 20} id="advantages">
                <Typography marginBottom={2} variant="h2" color='secondary' fontWeight='bold' data-aos="fade-up">
                  Előnyei
                </Typography>
                <Grid item data-aos="fade-up">
                  <Grid display='flex' alignItems='center' marginBottom={5}>
                    {/* <CheckCircleIcon color='secondary' style={{ marginRight: 20 }} /> */}
                    <Typography variant="h4">
                      A Sales Activity nem csupán egy szoftver, hanem egy teljes körű megoldás a pénzügyi értékesítők számára. Használata egyszerű és gyors.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item data-aos="fade-up">
                  <Grid display='flex' alignItems='center' marginBottom={3}>
                    <CheckCircleIcon color='secondary' style={{ marginRight: 20 }} />
                    <Typography variant="h4">
                      Munkád mostantól okosabbá válik, levesszük a felesleges terhet a válladról.
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center' marginBottom={3}>
                    <CheckCircleIcon color='secondary' style={{ marginRight: 20 }} />
                    <Typography variant="h4">
                      Használd a tökéletes folyamatokat. Nézd meg, hogyan illeszkednek egymáshoz.
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center' marginBottom={3}>
                    <CheckCircleIcon color='secondary' style={{ marginRight: 20 }} />
                    <Typography variant="h4">
                      A szoftver segít a nagyobb eredmények elérésében, teljesítményed növelésében.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden lgUp>
                <Grid container>
                  <AnimatedElement></AnimatedElement>
                </Grid>
              </Hidden>
              <Hidden lgDown>
                <div className={classes.parent} id='parent'>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    data-aos="fade-up"
                  >
                    <img
                      alt={'3d-target-layout'}
                      src={privateTarget}
                      style={
                        matches ?
                          {
                            width: '90%',
                            marginLeft: '-20%',
                            minHeight: 700,
                          } :
                          {
                            width: '90%',
                            marginLeft: '-20%',
                          }
                        // matches
                        //   ? { width: '90%', marginLeft: '-20%' }
                        //   : { width: '100%', marginLeft: '-20%' }
                      }
                    />
                  </Grid>
                  <ScrollLink to="targetCard" smooth={true} offset={250} duration={1000}>
                    <div className={classes.child} id='child'>
                      {visible &&
                        <div>
                          <svg className={classes.svgLine} pointerEvents='none'>
                            <path class="path" d="M 49.793 65.353 C 49.793 65.353 251.556 38.901 223.029 235.477 C 194.502 432.053 -23.341 879.149 390.041 828.838" />
                          </svg>
                          <WindupChildren>
                            <Typography className={classes.chalk}></Typography>
                            <Pause ms={1200} />
                            <Typography textAlign='left' className={classes.chalk} color='primary' variant='h4'
                            //class="chalk line-1 anim-typewriter"
                            >
                              Motiváld munkatársaid céljaik elérésében!
                            </Typography>
                          </WindupChildren>
                          <svg className={classes.svgLine} overflow='visible' style={{ transform: 'scale(0.5)', marginTop: -475, marginLeft: 100, zIndex: 10, }} pointerEvents='none'>
                            {/* <path class='arrow' d="M 202.906 -55.647 C 303.855 -111.593 357.919 -102.614 442.236 -91.233"></path>
                            <path class='arrow' d="M 236.041 -14.43"></path>
                            <path class='arrow' d="M 220.686 -16.855"></path>
                          <path class='arrow' d="M 235.868 -56.853 L 202.576 -55.317 L 217.922 -88.258"></path> */}
                            <path class='arrow-head' d="M 356.055 155.522 L 324.69 156.659"></path>
                            <path class='arrow-head' d="M 356.51 155.75 C 349.691 146.886 345.828 138.174 339.009 128.704"></path>
                            <path class='arrow' d="M 149.458 107.844 C 232.869 101.859 293.553 117.692 356.282 155.8"></path>
                          </svg>
                        </div>
                      }
                      <Grid
                        item
                        lg={4}
                        sm={4}
                        xl={4}
                        xs={4}
                        className={`${classes.targetCard} ${!visible ? classes.targetCardDefault : classes.targetCard}`}
                        id='targetCard'
                      >
                        <div className={classes.circle}><AddIcon></AddIcon> </div>
                      </Grid>
                    </div>
                  </ScrollLink>
                </div>
              </Hidden>
            </Grid>
          </Grid>
          <VisibilitySensor onChange={(isVisible) => { !visible && isVisible && setVisible(isVisible) }}>
            <div style={{ height: 1 }}></div>
          </VisibilitySensor>
          <Hidden lgDown>
            <VisibilitySensor onChange={(isVisible) => { !visible && isVisible && setVisible(isVisible) }}>
              <Grid
                className='main'
                container
                spacing={3}
                pb={10}
              >
                <Grid
                  item
                  lg={5}
                  sm={5}
                  xl={5}
                  xs={12}
                  data-aos="fade-up"
                >
                  <Typography marginBottom={5} variant="h2" color='secondary' fontWeight='bold' data-aos="fade-up">
                    Tervezzetek közösen
                  </Typography>
                  <Typography variant="h4">
                    Határozzátok meg a vállalati és egyéni célokat, illesszétek be az üzleti folyamatokba és érjétek el őket minél hamarabb!
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={7}
                  sm={7}
                  xl={7}
                  xs={12}
                  mt={10}
                  className={classes.targetEdit}
                  id="targetCard"
                >
                  <img
                    alt={'target-edit-modal'}
                    src={targetEditModal}
                    className={classes.image}
                    style={{ minHeight: matches && 500 }}
                  />
                </Grid>
              </Grid>
            </VisibilitySensor>
          </Hidden>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            pt={20}
            pb={5}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              data-aos="fade-up"
              id="customWorkflow"
            >
              <Typography variant="h2" color='secondary' fontWeight='bold' data-aos="fade-up" marginBottom={5} textAlign='center'>
                Cégre szabott egyedi tárgyalási folyamatok
              </Typography>
              <VisibilitySensor onChange={(isVisible2) => { !visible2 && isVisible2 && setVisible2(isVisible2) }}>
                <Typography variant="h4" color='default' data-aos="fade-up" textAlign='center'>
                  Mi adjuk az alapot, melyre építhetsz és tetszőlegesen kiegészítheted további lépésekkel és tartalommal.                  </Typography>
              </VisibilitySensor>
              <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: 50 }}>
                {/* <a > */}
                <Button className={classes.button} disableElevation variant='contained' href="https://my.aocsystem.com/ac/company?type=enterprise" rel="noreferrer" target="_blank" >Érdekel</Button>
                {/* </a> */}
              </Box>

            </Grid>
          </Grid>
        </Container>
        <Grid container>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            style={{
              // minHeight: '100vh', 
              overflow: 'hidden'
            }}
          >
            <div style={{ minHeight: 750, minWidth: 3850, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 50 }} class="horizontalImage">
              <Hidden mdDown>
                <TreeView data={dataFlow} startAnimation={visible2} className={classes.tree} orientation='horizontal' nodeSize={{ x: 300, y: 200 }} />
              </Hidden>
            </div>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <Grid container>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{
                minHeight: 1750,
                marginTop: -750,
                pointerEvents: 'none'
              }}
            >
              <TreeView data={dataFlow} startAnimation={visible2} orientation='vertical' nodeSize={{ x: 225, y: 150 }} zoom={0.8} />
            </Grid>
          </Grid>
        </Hidden>
        <Grid container>
          <Grid
            item
            lg={10}
            sm={10}
            xl={10}
            xs={10}
            zIndex={10}
          >
            <Banner
              bannerWidth={'100vw'}
              mt={{
                md: 20,
                xs: -10,
              }}
            //marginBottom={10}
            >
              <Container component="main">
                <Typography variant="h1" align="left" color='secondary'
                //data-aos="fade-up"
                >
                  Biztosíts cégednek
                </Typography>
                <Typography variant="h1" align="left" fontWeight={'bold'} gutterBottom className={classes.gradientText}
                //data-aos="fade-up" data-aos-delay="50"
                >
                  versenyelőnyt.
                </Typography>
                <Typography maxWidth={'md'} variant="h4" align="left" color="textSecondary" component="p" data-aos="fade-up" data-aos-delay="100" style={{ margin: '50px 0px 0px 0px' }}>
                  A   tökéletes folyamatok   lehetővé teszik  csapatod  számára  az  eredményes  és  hatékony munkavégzést.
                </Typography>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  marginTop={15}
                >
                  <Grid container display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                    <VisibilitySensor onChange={(isVisible) => {
                      //isVisible && ScrollTrigger.refresh(true)
                    }}>
                      <img
                        alt={'ipad-mockups'}
                        src={ipadMockups}
                        className={classes.imageLayout}
                      />
                    </VisibilitySensor>
                  </Grid>
                </Grid>
              </Container>
            </Banner>
            <Banner
              bannerWidth='100vw'
              id='prices'
              mt={15}
            >
              <Pricing
                prices={prices}
                buttonColor='primary'
              >
              </Pricing>
            </Banner>
          </Grid>
        </Grid>
      </Box >
    </Paper >
  )
}

export default SalesActivity;