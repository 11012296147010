import hu from './hu.json'
import en from './en.json'
import de from './de.json'

const languages = {
  hu,
  en,
  de
}

export default languages
