import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, Container, Grid, Hidden, Paper, Typography, useMediaQuery } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import theme from '../../theme/index';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import barion from '../../static/public/barion-card-strip-intl_800px.png'
import dilato from '../../static/public/dlogo.png'

const useStyles = makeStyles({
  // boxContainer: {
  //   backgroundColor: '#2c3e50',
  //   // backgroundColor: '#f6f6f6',
  //   // backgroundColor:'inherit',
  //   display: 'flex',
  //   jusitfyContent: 'space-between',
  // },
  footer1Container: {
    width: '100%',
    backgroundColor: '#2c3e50',
  },
  footer2Container: {
    width: '100%',
    // backgroundColor: '#f6f6f6'
    //backgroundColor: '#f6f6f7',
    backgroundColor: '#212c38',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.default.main,
    '&:hover': {
      color: 'white',
    }
  },
  textContainer: {
    marginTop: '35px',
  },
  textTitleGrid: {
    // marginTop: '50px',
  },
  title: {
    // color:'white'
    color: '#f5f5f6'
  },
  text: {
    marginBottom: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
})

function Footer() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)');

  const navigationLinks = [
    {
      title: t('footer.company'), children: [
        { text: t('footer.impress'), to: '/imprint' },

      ]
    },
    {
      title: t('footer.information'), children: [
        { text: t('footer.privacyNotice'), href: '#/data-management-information' },
      ]
    },
  ]

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    })
  }


  const renderLinks = (text, to, href, index) => {

    if (to) {
      return <Link className={classes.link} to={to}>
        <Typography className={classes.text} key={index}>
          {text}
        </Typography>
      </Link>
    }
    else
      return <a className={classes.link} href={href} target='_blank'>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <Typography className={classes.text} key={index}>
            {text}
          </Typography>
          <OpenInNewIcon style={{ fontSize: 14, marginLeft: 4, marginBottom: 9 }} />
        </div>
      </a>
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid
          container
        >
          <Box className={classes.footer1Container} display='flex' alignItems='center'>
            <Container>
              <Grid
                container
                display='flex'
                justifyContent='space-around'
              >
                {/* <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                flexDirection='column'
                display='flex'
                // jusitfyContent='flex-end'
                alignItems='flex-end'
              >
                <Card className={classes.card}>
                  <CardActionArea onClick={scrollToTop}>
                    <ArrowUpwardIcon fontSize='medium' style={{color:'#F6F6F6'}}/>
                  </CardActionArea>
                </Card>
              </Grid> */}
                <Hidden smDown>
                  {navigationLinks.map(({ title, children }, index) => {
                    return <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={3}
                      py={5}
                      className={classes.textTitleGrid}
                    >
                      <Typography fontWeight='bold' key={index} className={classes.title}>
                        {title}
                      </Typography>
                      <div className={classes.textContainer}>
                        {children.map(({ text, to, href }, index) => renderLinks(text, to, href, index))}
                      </div>
                    </Grid>
                  })}
                </Hidden>
              </Grid>
              <Hidden smUp>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className={classes.textTitleGrid}
                  py={5}
                >{navigationLinks.map(({ title, children }, index) => {

                  return <Accordion disableGutters elevation={0} square style={{ margin: 0, backgroundColor: '#2c3e50', }}>
                    <AccordionSummary expandIcon={<ExpandMore className={classes.title} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography fontWeight='bold' key={index} className={classes.title}>
                        {title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.textContainer}>
                        {children.map(({ text, to, href }, index) => renderLinks(text, to, href, index))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                })}
                </Grid>
              </Hidden>
            </Container>
          </Box>
          <Box className={classes.footer2Container} alignItems='center' display='flex' py={2} >
            <Container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mb={matches ? 2 : 2}

              >
                <Grid container>
                  {/* <Grid
                    item
                    xl={9}
                    lg={9}
                    md={9}
                    sm={7}
                    xs={12}
                    className={classes.row}
                    justifyContent={matches ? 'flex-start' : 'center'}
                  >
                    <a className={classes.link} href={'https://www.instagram.com/aocsystem/'} rel="noreferrer" target='_blank'>
                      <Typography className={`${classes.row} ${classes.link}`} color={theme.palette.default.main}>
                        <InstagramIcon style={{ marginRight: 5 }} />
                        Instagram
                      </Typography>
                    </a>
                    <a className={classes.link} href={'https://linkedin.com/company/aoc-system-zrt/'} rel="noreferrer" target='_blank'>
                      <Typography className={`${classes.row} ${classes.link}`} color={theme.palette.default.main}>
                        <LinkedInIcon style={{ margin: 5, marginLeft: 20 }} />
                        LinkedIn
                      </Typography>
                    </a>
                  </Grid> */}
                  {/* <Box margin={5}>
                  Follow us
                </Box> */}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container>
                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={9}
                    sm={7}
                    xs={12}
                    className={classes.row}
                    justifyContent={matches ? 'flex-start' : 'center'}

                  >
                    <Typography
                      // color={theme.palette.default}
                      // color='#999C9E'
                      color={theme.palette.default.main}
                    >
                      &copy; {new Date().getFullYear()}  {t('footer.copyright')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={4}
                    xs={12}
                    className={classes.row}
                    justifyContent={matches ? 'flex-start' : 'center'}
                    mt={{ sm: 0, xs: 4, md: 0 }}
                  >
                
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

export default Footer
