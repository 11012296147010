import Element from '../components/Element/Element'
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';

function Products() {
  return (

    <Paper>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Typography variant='h3' margin={'80px 20px'} data-aos="fade-right">Products</Typography>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={7}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((item, index) => {
              return <Grid
                key={index}
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                data-aos="fade-up"
              >
                <Element title={item}></Element>
              </Grid>
            })
            }
          </Grid>
        </Container>
      </Box>
    </Paper>
  )
}

export default Products;