
import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/index'
import Container from '@material-ui/core/Container';
import CheckIcon from '@material-ui/icons/Check';
import { Switch } from '@material-ui/core';
import FullScreenDialog from '../Modals/FullScreenDialog';
import { styled } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PricingDialog from './PricingDialog';

const useStyles = makeStyles({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(12, 0, 12),
  },
  cardHeader: {
    //backgroundColor: theme.palette.secondary.light,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  button: {
    padding: 10,
    borderRadius: 30,
    margin: theme.spacing(4),
  },
  card: {
    boxShadow: '18px 17px 20px 0px rgb(0 0 0 / 10%)',
    transition: '0.5s',
    height: '100%',
    '&:hover': {
      transition: '0.25s',
      transform: 'scale(1.01)',
    }
  },
  switch: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(8),
  },
});

export default function Pricing(props) {
  const classes = useStyles();
  const [monthly, setMonthly] = useState(true);
  const plans = props.prices

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: 20,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#dbdbdb' : '#dbdbdb',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#dbdbdb' : '#dbdbdb',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Grid container display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' className={classes.switch}>

          <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item>
              <Typography variant="h2" color='secondary'> Előfizetési díjak</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Typography style={{whiteSpace: 'nowrap'}} color={monthly && 'secondary'} variant='h4'>Havi előfizetés</Typography>
              <IOSSwitch onChange={() => setMonthly(!monthly)} defaultChecked={!monthly} />
              <Typography style={{whiteSpace: 'nowrap'}}  variant='h4' color={!monthly && 'secondary'}>Éves előfizetés</Typography>
            </Grid>
          </Grid>

        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
        //px={10}
        >
          {plans.map((plan) => (
            <Grid item key={plan.title}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <Card
                data-aos-delay={plan.delay}
                elevation={0}
                className={classes.card}
                style={{
                  background: plan.backgroundColor,
                  padding: 20,
                  margin: 15
                }}
              >
                <CardHeader
                  title={<Typography fontWeight='bold' variant='h4' style={{ color: props.titleColor }}>{plan.title}</Typography>}
                  subheader={
                    <div style={{ backgroundColor: '#ffac3f', borderRadius: 10, margin: '0px 25px' }}>
                      <Typography>{plan.subheader}</Typography>
                    </div>
                  }
                  titleTypographyProps={{ align: 'center', }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                      {monthly ? plan.price.monthly : plan.price.yearly}
                    </Typography>
                  </div>
                  <Typography variant="subtitle2">
                    {plan.note}
                  </Typography>
                  <CardActions>
                    <Button
                      className={classes.button}
                      disableElevation
                      fullWidth
                      variant={plan.buttonVariant}
                      color={props.buttonColor}
                      //style={{ textTransform: 'none', fontSize: 20, }} 
                      target="_blank" href={plan.href}
                    >
                      {plan.buttonText}
                    </Button>
                  </CardActions>
                  <ul>
                    {plan.description.map((line) => (
                      <Grid display='flex' alignItems='center' marginBottom={1}>
                        <CheckIcon style={{ color: 'lightgrey' }} />
                        <Typography marginLeft={2} component="li" variant="body2" align="left" key={line}>{line}</Typography>
                      </Grid>
                    ))}
                  </ul>
                  <FullScreenDialog
                    button={
                      <Typography marginTop={5} style={{ textDecoration: 'underline', }} variant="subtitle2" align="right">
                        Részletek
                      </Typography>
                    }
                  >
                    <PricingDialog
                      details={props.prices}
                      plan={plan}
                      monthly={monthly}
                    >
                    </PricingDialog>
                  </FullScreenDialog>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}