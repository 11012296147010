import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicTable from '../../components/BasicTable/BasicTable';
import theme from '../../theme/index';



const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '80px',
    fontWeight: 'bold',
    color: '#000'
  },
  subTitle: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '50px',
    fontWeight: 'bold',
  },
  subTitle2: {
    textAlign: 'left',
    marginTop: '40px',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'justify',
    marginBottom: '30px',
    marginTop: '30px'
  },
  button: {
    textTransform: 'none'
  },
  tableTitle: {
    color: '#000',
    textAlign: 'center',
    marginBottom: '10px',
    marginTop: '80px'
  }
})

const dataManagementTableColumns = [
  { title: 'Cél', variant: 'h7', align: 'left' },
  { title: 'Jogalap', variant: 'h7', align: 'right' },
  { title: 'Érintettek', variant: 'h7', align: 'right' },
  { title: 'Adatkategória', variant: 'h7', align: 'right' },
  { title: 'Időtartam', variant: 'h7', align: 'right' },
  { title: 'mód', variant: 'h7', align: 'right' },
  { title: 'Forrás', variant: 'h7', align: 'right' },
]

const informationTableRows = [
  {
    details: ['érintett számára megfelelő információ nyújtása és ezzel összhangban a kapcsolattartás', 'Hozzájárulás, megállapodás, jogi köt. telj, jogos érdek', 'Minden természeFügg a jogalaptól, tipikusan cél megvalósulása, törlési kérelem, polgári jogi elévülési idő, jogos érdek megszűnésetes személy, ideértve egy szervezet nevében eljáró képviselőt is, aki az Adatkezelővel kapcsolatba kerül és az Adatkezelőtől információt kér/kap ', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában ', 'cél megvalósulásáig, vagy törlési kérelemig, vagy jogszabályban meghatározott határidőben, vagy elévülési időben, vagy jogos érdek megszűnéséig ', 'elektronikusan és/vagy papír alapon, manuálisan történik ', 'Érintettek']
  }
]

const contactTalbeRows = [
  {
    details: ['az érintettel történő kapcsolattartás, felmerülő kérdések, kérések és egyebek megválaszolása, megoldása', 'önkéntes hozzájárulás vagy jogszabályi köt. teljesítése, vagy megállapodáson alapul, vagy  jogos érdek vagy jogszabályi kötelezettség, vagy létfontosságú érdek ', 'Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki az egyszeri információkérésen túl folyamatosan, vagy rendszeresen kapcsolatot tart az Adatkezelővel ', 'Lásd részletesen az adatkezelés tájékoztatójában/ leírásában ', 'cél megvalósulásáig, vagy törlési kérelemig, vagy jogszabályban meghatározott határidőben, vagy elévülési időben, vagy jogos érdek megszűnéséig ', 'elektronikusan és/vagy papír alapon, manuálisan történik ', 'Érintettek']
  }
]

const timeTableRows = [
  {
    details: ['az érintett számára időpont biztosítása és kapcsolattartás', 'önkéntes hozzájáruláson alapul', 'Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki adatainak megadásával időpontot foglal/egyeztet ', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában ', 'cél megvalósulásáig, vagy általános elévülési időben, vagy a jogos érdek fennállásáig ', 'elektronikusan és/vagy papír alapon, manuálisan történik', 'Érintettek']
  }
]

const partnerTalbeRows = [
  {
    details: ['az érintett számára megfelelő ajánlat nyújtása és kapcsolattartás  ', 'önkéntes hozzájárulás, vagy GDPR 6. cikk (1) b) pontja ', 'Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki az Adatkezelőtől ajánlatot kér személyes adatainak megadása mellett ', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában ', 'érvényességi időtartamban, vagy elfogadás esetén a jogviszony elévüléséig, vagy jogos érdek alapján történő adatkezelés esetén annak megszűnéséig ', 'elektronikusan és/vagy papír alapon, jellemzően elektronikusan, manuálisan történik ', 'Érintettek']
  }
]

const agreementTableRows = [
  {
    details: ['a megállapodás megkötése, teljesítése, teljesítésének felügyelete, kapcsolattartás ', 'Megállapodás megkötése (GDPR 6. cikk 1. b.) képviselő, kapcsolattartó adatainak kezelése jogos érdeken alapul ', 'Minden természetes személy, valamint szervezet nevében, képviseletében eljáró természetes személy, aki az Adatkezelővel – személyes adatok megadása mellett – saját nevében megállapodást köt az Adatkezelővel, vagy a szerződésben képviselőként, vagy kapcsolattartóként szerepel ', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában ', 'elévülési idő vagy megállapodásban meghatározott időtartam vagy nem leselejtezhető, így nem törölhető ', 'elektronikusan és/vagy papír alapon, manuálisan történik ', 'Érintettek']
  }
]
const onceInfoRows = [
  {
    details: ['az érintett számára megfelelő információ nyújtása és ezzel összhangban a kapcsolattartás ', 'önkéntes hozzájárulás vagy jogszabályi köt. teljesítése, vagy megállapodáson alapul, vagy  jogos érdek vagy létfontosságú érdek ', 'Minden természetes személy, ideértve egy szervezet nevében eljáró képviselőt is, aki az Adatkezelővel kapcsolatba kerül és az Adatkezelőtől információt kér/kap ', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában ', 'cél megvalósulásáig, vagy törlési kérelemig, vagy jogszabályban meghatározott határidőben, vagy elévülési időben, vagy jogos érdek megszűnéséig ', 'elektronikusan és/vagy papír alapon, manuálisan történik ', 'Érintettek ']
  }
]

const bankTableRows = [
  {
    details: ['a pénzügyi teljesítés elősegítése, ellenőrzése', 'Jogi kötelezettség teljesítése', 'Minden természetes személy, aki banki adatok alapján beazonosítható, beazonosított', 'számlabirtokos neve, bakszámla száma, közlemény, összeg, időpont', '8 év', 'saját nyilvántartásból, közhiteles nyilvántartásból, vagy közvetlenül az érintettől']
  }
]

const invoiceOutTableRows = [
  {
    details: ['a számlakibocsátással kapcsolatos jogszabályi kötelezettségek maradéktalan teljesítése', 'jogi kötelezettség teljesítése a 2007. évi CXXVII. törvény, valamint az e törvény felhatalmazása alapján kiadott rendeletek szerint', 'minden természetes személy, ideértve az egyéni vállalkozót is, akinek adata az Adatkezelő által kibocsátott számla (vagy azzal egy tekintet alá eső számviteli bizonylat) alapján azonosítható, azonosított', '2007. évi CXXVII. tv. 169-170. §-ban és 176.§-ban megfogalmazott adatkategóriák', '2000. évi C. törvény 169. §-a alapján az üzleti év plusz 8 év', 'közvetlenül az érintettől, ritkán közhiteles nyilvántartásból']
  }
]

const invoiceInTableRows = [
  {
    details: ['a jogszabályi kötelezettségek maradéktalan teljesítése, a számla (és azzal egy tekintet alá eső dokumentumok) tárolása a 2007. évi CXXVII. tv. 179. §-a alapján', 'jogi kötelezettség teljesítése (számla megőrzése) a 2007. évi CXXVII. törvény (179. §) alapján', 'minden természetes személy, akinek adata az Adatkezelő által befogadott számlára (vagy azzal egy tekintet alá eső számviteli bizonylatra) vagy mellékletére rákerült', '2007. évi CXXVII. tv. 169-170. §-ban és 176.§-ban megfogalmazott adatkategóriák', '2000. évi C. törvény 169. §-a alapján az üzleti év plusz 8 év', 'közvetlenül a számlát kiállító féltől']
  }
]

const feeTableRows = [
  {
    details: ['a pénzügyi teljesítés elősegítése', 'Adatkezelő jogos érdeke', 'Díjbekérő adatkörei által beazonosítható, beazonosított természetes személyek', 'Lásd a díjbekérőben és az adatkezelés tájékoztatójában', '8 évig, vagy ha nem történik megfizetés, érvényességi időben, vagy a cél ellehetetlenüléséig', 'saját nyilvántartásból, közvetlenül az érintettől']
  }
]

const aocTableRows = [
  {
    details: ['az érintett adatainak rögzítése, jogosultságainak, kedvezményeinek, hozzáférésének megadása, érvényesítése, ellenőrzése, a szolgáltatások igénybevételének megkönnyítése, valamint a kapcsolattartás', 'önkéntes hozzájárulás, de értelmezhető úgy is, hogy az adatkezelés megállapodáson (GDPR 6. cikk (1) b) pont második fordulata) alapul', 'Minden természetes személy, aki az AOC System rendszerbe történő regisztráció során megadott adatokkal beazonosítható, beazonosított', 'Lásd a regisztráció adatait', 'Lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const logInTableRows = [
  {
    details: ['az AOC System rendszerbe bejelentkező érintettek azonosítása, jogosultságainak biztosítása, ellenőrzése', 'önkéntes hozzájárulás', 'Minden természetes személy, aki az AOC System rendszerbe történő bejelentkezés alapján beazonosítható, beazonosított', 'Lásd a bejelentkezés adatköreit', 'Törlési kérelemig, polgári elévülési időben, részletesen lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const accountTableRows = [
  {
    details: ['célja az, hogy az érintett megismerhesse a rá vonatkozó adatokat, szükség esetén helyesbítse azokat', 'Jogi kötelezettség teljesítése', 'Minden regisztrált felhasználó, aki az AOC System rendszerbe belép', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában', 'érintett kérésére törlésig, jogos érdek megszűnéséig, számviteli tv. szerinti 8 évig', 'Érintettek']
  }
]

const applicantTableRows = [
  {
    details: ['a jelentkezés és kiválasztás lehetővé tétele, valamint a kapcsolattartás', 'önkéntes hozzájárulás', 'Minden természetes személy, aki az Adatkezelő által meghirdetett álláspályázatra, vagy kiírt álláspályázat nélkül jelentkezik', 'Lásd az önéletrajzban és az adatkezelés tájékoztatójában', 'Lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const timeInterviewTableRows = [
  {
    details: ['az érintett számára időpont biztosítása állásinterjú céljából', 'GDPR 6. cikk (1) b) pontja', 'Minden állásra jelentkező, akivel Adatkezelő időpontot egyeztet állásinterjúra', 'név, telefonszám, email cím, időpont', 'Lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const activitiesTableRows = [
  {
    name: 'Adatfeldolgozás tárgya',
    details: ['AOC System rendszerben található „Naptár” funkció/menüpont', 'AOC System rendszerben található „Feladataim” funkció/menüpont (Feladatütemező)', 'AOC System rendszerben található „Ügyfeleim” funkció/menüpont (ügyfélkezelés)', 'AOC System rendszerben található „Dokumentumtár” funkció/menüpont', 'AOC System rendszerben található „Vállalkozásom” funkció/menüpont és alpontjai', 'AOC System rendszerben található „Csoportom” funkció/menüpont', 'AOC System rendszerben található „Tárgyalási jegyzőkönyv” létrehozása']
  },
  {
    name: 'Adatfeldolgozás célja',
    details: ['Regisztrált felhasználók számára naptár biztosítása', 'Regisztrált felhasználók számára feladatütemező biztosítása', 'Regisztrált felhasználók számára ügyfélnyilvántartó biztosítása', 'Regisztrált felhasználók számára tárhely biztosítása dokumentumok kezeléséhez', 'Regisztrált felhasználók számára tárhely biztosítása vállalkozás működésével, könyveléssel kapcsolatos dokumentumok kezeléséhez', 'Statisztikák készítése, tervezés elősegítése a regisztrált felhasználók számára', 'Regisztrált felhasználó és ügyfele között létrejött tárgyalás részleteinek rögzítése']
  },
  {
    name: 'Adatfeldolgozás jellege, módja',
    details: ['Tárolás, regisztrált felhasználó által történő módosítás, törlés biztosítása', 'Tárolás, regisztrált felhasználó által történő módosítás, törlés biztosítása', 'Tárolás, regisztrált felhasználó által történő módosítás, lekérdezés, leszűrés törlés biztosítása', 'Tárolás, exportálás, keresés, regisztrált felhasználó által történő törlés biztosítása', 'Tárolás, exportálás, keresés, regisztrált felhasználó által történő törlés biztosítása', 'Statisztikák és célok létrehozása bevitt adatokból, tárolás, lekérdezés, vizuális megjelenítés', 'Tárolás']
  },
  {
    name: 'Adatfeldolgozás időtartama',
    details: ['Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig']
  },
  {
    name: 'Személyes adatok típusa, köre',
    details: ['Naptárba regisztrált felhasználó által bevitt, rögzített adatok, tipikusan: Időpont, név, elérhetőségi adat, cím', 'Feladatütemezőbe a regisztrált felhasználó által bevitt, rögzített adatok, tipikusan: dátum, ügyfél név, események, művelet, jegyzetben tárolt adatkörök', 'Az ügyfeleim menüpontban a regisztrált felhasználó által bevitt, rögzített adatok, tipikusan: név, cím, e-mail cím, telefonszám, megjegyzés, korábbi feladatok adatai, státusz', 'A regisztrált felhasználó által feltöltött dokumentumok adattartalma, regisztrált felhasználó megjegyzései', 'Anonim adatok a statisztikák kapcsán', 'Név, telefonszám, e-mail cím, statisztikai adatok a tervezés kapcsán', 'Regisztrált felhasználó által a tárgyalási jegyzőkönyvben rögzített adatok']
  },
  {
    name: 'Érintettek kategóriája',
    details: ['Regisztrált felhasználó által rögzített adatok alapján beazonosítható, beazonosított személyek, pl. a regisztrált felhasználó potenciális ügyfelei vagy meglevő ügyfelei', 'Regisztrált felhasználó által rögzített adatok alapszemélyek, pl. a regisztrált felhasználópotenciális ügyfelei vagy meglevő ügyfelei', 'Regisztrált felhasználó által rögzített adatok alapján beazonosítható, beazonosított személyek, pl. a regisztrált felhasználó potenciális ügyfelei vagy meglevő ügyfelei', 'Regisztrált felhasználó által rögzített dokumentumok adatai által beazonosítható, beazonosított személyek', 'Regisztrált felhasználó által rögzített dokumentumok adatai által beazonosítható, beazonosított személyek', 'Tervezés kapcsán regisztrált felhasználók', 'Regisztrált felhasználó által a tárgyalási jegyzőkönyv adatai alapján beazonosítható, beazonosított személyek (reg. felhasználó, ügyfele)']
  },
  {
    name: 'Adattovábbítás címzettjei az AOC System-en keresztül',
    details: ['Nincsen', 'Nincsen', 'Nincsen', 'Nincsen', 'Regisztrált felhasználó (könyvelő) felé az AOC System rendszerén belül', 'Nincsen', 'Nincsen']
  },
  {
    name: 'Adattovábbítás harmadik országokba az AOC System-en keresztül',
    details: ['Nem történik', 'Nem történik', 'Nem történik', 'Nem történik', 'Nem történik', 'Nem történik', 'Nem történik']
  },
]

const lawTableColumns = [
  { title: '', variant: 'h7', align: 'left' },
  { title: 'Előzetes tájékozódáshoz való jog', variant: 'h7', align: 'right' },
  { title: 'Hozzáférés joga', variant: 'h7', align: 'right' },
  { title: 'Helyesbítés joga', variant: 'h7', align: 'right' },
  { title: 'Törlés joga', variant: 'h7', align: 'right' },
  { title: 'Korlátozás', variant: 'h7', align: 'right' },
  { title: 'Adat-hordozhatóság', variant: 'h7', align: 'right' },
  { title: 'Tiltakozás', variant: 'h7', align: 'right' },
  { title: 'Hozzájárulás visszavonása', variant: 'h7', align: 'right' },
]

const lawTableRows = [
  {
    name: 'Hozzájárulás',
    details: [true, true, true, true, true, true, false, true]
  },
  {
    name: 'Megállapodás',
    details: [true, true, true, true, true, true, false, false]
  },
  {
    name: 'Jogi kötelezettség',
    details: [true, true, true, false, true, false, false, false]
  },
  {
    name: 'Létfontosságú érdek',
    details: [true, true, true, true, true, false, false, false]
  },
  {
    name: 'Közfeladat, közhatalmi jog.',
    details: [true, true, true, false, true, false, true, false]
  },
  {
    name: 'Jogos érdek',
    details: [true, true, true, true, true, false, true, false]
  },
]


function DataManagementInformation() {
  const classes = useStyles()
  const { t, } = useTranslation();

  document.title = t('Title.main') + " | " + t('Title.subTitle.dataManagement')

  return (
    <React.Fragment>
      <Paper>
        <Box style={{ height: '30vh', marginBottom: '5vh' }} backgroundColor='#212c38' display='flex' alignItems='center'>
          <Container>
            <Typography fontWeight='bold' variant='h3' textAlign='left' style={{ color: 'white' }}>
              A Dilato Finance Szolgáltató Kft.
              külső adatvédelmi tájékoztatója
            </Typography>
          </Container>
        </Box>
        <Box
          sx={{
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Grid
            container>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <Container>
                <Typography className={classes.text}>A Dilato Finance Szolgáltató Kft. (székhely: 1062 Budapest, Délibáb u. 8,  cégjegyzékszám: 01-09-728751,adószám: 13310691-2-42, e-mail:
                  <a href="mailto:info@dilato.eu" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#000' }}>
                    info@dilato.eu
                  </a>, önállóan képviseli: Fodor Zsolt ügyvezető igazgató, adatvédelmi tisztviselő: Dr. Bölcskei Krisztián,
                  <a href="mailto:info@adatvedelmiauditor.hu" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#000' }}>info@adatvedelmiauditor.hu</a>,
                  <a href="mailto:dr.bolcskei.krisztian@adatvedelmiauditor.hu" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#000' }}> dr.bolcskei.krisztian@adatvedelmiauditor.hu), </a>
                  mint adatkezelő ezúton tájékoztatja Önt összefoglalóan és röviden az általa végzett adatkezelési tevékenységekről.                </Typography>
                <Typography className={classes.text}>Adatkezelő felhívja a tisztelt érintettek figyelmét, hogy</Typography>
                <ul>
                  <li className={classes.text}>az érintettek jogaikkal (a hozzáférés joga, a helyesbítés joga, a törléshez való jog és az „elfeledtetéshez” való jog, az adatok zároláshoz/korlátozáshoz való jog, tiltakozáshoz való jog, az adathordozhatósághoz való jog) az
                    <a href="mailto:info@dilato.eu" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#000' }}> info@dilato.eu</a> e-mail címre, vagy Adatkezelő más elérhetőségre küldött nyilatkozattal tehetik meg, továbbá jogaik megsértése esetén fordulhatnak panasszal a hatósághoz (mindenkori elérhetőséget lásd: NAIH,
                    <a href='https://www.naih.hu/' style={{ textDecoration: 'none', color: '#000' }}>www.naih.hu</a>),vagy a lakóhely szerint illetékes bírósághoz és sérelemdíjat követelhetnek. Adatkezelő felhívja az érintettek figyelmét, hogy joggyakorlásuknak lehetnek feltételei, valamint korlátai egy-egy adatkezeléssel kapcsolatban, amely tényezőket az Adatkezelő vizsgálni köteles az érintettek joggyakorlása esetén. Abban az esetben, ha egy jogával az érintett adott adatkezelés kapcsán nem élhet, úgy a joggyakorlást kizáró/korlátozó ténybeli és/vagy jogi indokokról az Adatkezelő írásban (ide értve az elektronikus utat is) tájékoztatja az érintettet és arról nyilvántartást vezet.
                  </li>
                  <li className={classes.text}>
                    az egyes adatkezelések részletesebb magyarázatai az egyes adatkezelések elnevezéseire kattintva olvashatók. Bármelyik adatkezelés vonatkozásában az Adatkezelő adatfeldolgozóként is eljárhat, ha Partnere megbízta azzal, hogy érdekében, nevében eljárva hajtsa végre az adatkezelést. Ilyen esetben adatfeldolgozási megállapodást köt a feladat vonatkozásában és az érintettek figyelmét írásban felhívja arra, hogy adatfeldolgozóként jár el és tájékoztatja az érintetteket az adatkezelő nevéről, elérhetőségeiről és GDPR-ban meghatározottakról.
                  </li>
                  <li className={classes.text}>
                    profilalkotás egyik adatkezelés vonatkozásában sem történik.
                  </li>
                  <li className={classes.text}>
                    az adatokat az Adatkezelő jogosultsággal rendelkező Munkatársai, kizárólag a feladataik elvégzéséhez elengedhetetlenül szükséges mértékben kezelhetik.
                  </li>
                  <li className={classes.text}>
                    adatközlés harmadik fél felé adott adatkezelés vonatkozásában történhet, de Adatkezelő az érintett által meghatározott adatait Partnerei számára akkor továbbíthatja, ha az adattovábbítás GDPR valamint Infotv szerinti feltételei fennállnak. Adatkezelő hivatalos megkeresés esetén adatokat továbbít hatóságok, vagy bíróságok számára.
                  </li>
                  <li className={classes.text}>gondoskodik az adatok biztonságáról, így az Adatkezelő az adatokat megfelelő intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen. Adatkezelő az adatok biztonságát szolgáló intézkedések meghatározásakor és alkalmazásakor tekintettel van a technika mindenkori fejlettségére és több lehetséges adatkezelési megoldás közül azt választja, amely a személyes adatok magasabb szintű
                    védelmét biztosítja, kivéve, ha az aránytalan nehézséget jelentene. Az Adatkezelő az informatikai védelemmel kapcsolatos feladatai körében gondoskodik különösen:
                    <ul>
                      <li>az adatkezeléshez használt eszközök (a továbbiakban: adatkezelő rendszer) jogosulatlan személyek általi hozzáférésének megtagadásáról,  </li>
                      <li>az adathordozók jogosulatlan olvasásának, másolásának, módosításának vagy eltávolításának megakadályozásáról, </li>
                      <li>az adatkezelő rendszerbe a személyes adatok jogosulatlan bevitelének, valamint az abban tárolt személyes adatok jogosulatlan megismerésének, módosításának vagy törlésének megakadályozásáról,  </li>
                      <li>az adatkezelő rendszerek jogosulatlan személyek általi, adatátviteli berendezés útján történő használatának megakadályozásáról,</li>
                      <li>arról, hogy az adatkezelő rendszer használatára jogosult személyek kizárólag a hozzáférési engedélyben meghatározott személyes adatokhoz férjenek hozzá,</li>
                      <li>arról, hogy ellenőrizhető és megállapítható legyen, hogy a személyes adatokat adatátviteli berendezés útján mely címzettnek továbbították vagy továbbíthatják, illetve bocsátották vagy bocsáthatják rendelkezésére</li>
                      <li>arról, hogy utólag ellenőrizhető és megállapítható legyen, hogy mely személyes adatokat, mely időpontban, ki vitt be az adatkezelő rendszerbe</li>
                      <li>a személyes adatoknak azok továbbítása során vagy az adathordozó szállítása közben történő jogosulatlan megismerésének, másolásának, módosításának vagy törlésének megakadályozásáról</li>
                      <li>arról, hogy üzemzavar esetén az adatkezelő rendszer helyreállítható legyen.</li>
                      <li>arról, hogy az adatkezelő rendszer működőképes legyen, a működése során fellépő hibákról jelentés készüljön, továbbá a tárolt személyes adatokat a rendszer hibás működtetésével sem lehessen megváltoztatni.</li>
                    </ul>
                  </li>

                  <li className={classes.text} style={{ fontWeight: 'bold' }}>az alábbi összefoglalás az Adatkezelő minden adatkezelését tartalmazza, kivéve azokat, amelyek kizárólag az Adatkezelő Munkatársaira vonatkoznak. .</li>
                </ul>
                <Typography variant='h6' className={classes.tableTitle}>Egyszeri információkérés- és adás adatkezelésének összefoglaló táblázata </Typography>
                <BasicTable
                  rows={informationTableRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <Typography variant='h6' className={classes.tableTitle}>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatokhoz kapcsolódó adatkezelés összefoglaló táblázata</Typography>
                <BasicTable
                  rows={contactTalbeRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <Typography variant='h6' className={classes.tableTitle}>Időpontegyeztetés (időpontfoglalás) adatkezelésének összefoglaló táblázata </Typography>
                <BasicTable
                  rows={timeTableRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <Typography variant='h6' className={classes.tableTitle}>Érintett által tett ajánlatkérési felhívással és Adatkezelő által tett ajánlatadással kapcsolatos adatkezelés összefoglaló táblázata </Typography>
                <BasicTable
                  rows={partnerTalbeRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <Typography variant='h6' className={classes.tableTitle}>Megállapodás megkötése során megadott adatokhoz kapcsolódó adatkezelés összefoglaló táblázata</Typography>
                <BasicTable
                  rows={agreementTableRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <Typography>
                  Lezárva: 2019. június hó 4.
                </Typography>
                <Grid
                display='flex'
                justifyContent='right'
                >
                  <Typography>
                    Fodor Zsolt

                    ügyvezető igazgató

                    Dilato Finance Szolgáltató Kft.
                  </Typography>
                </Grid>
                <Typography className={classes.title} variant='h5'>Egyszeri információkérés és -adás adatkezelése </Typography>
                <Typography fontStyle='italic' marginBottom='40px'>Ebben az esetben az érintett egyszeri  információt kér az Adatkezelőtől, amelyet az Adatkezelő megválaszol, vagy kérelem nélkül az Adatkezelő az érintettet értesíti (nem reklám és nem üzletszerzési célból, hanem tájékoztatás történik), az ügy lezárul. </Typography>
                <Typography fontStyle='italic' marginBottom='40px'>Az adatkezelés független az információkérés, a tájékoztatáskérés módjától, konkrét céljától. </Typography>
                <Typography variant='h6' className={classes.tableTitle}>Egyszeri információkérés- és adás adatkezelésének összefoglaló táblázata </Typography>
                <BasicTable
                  rows={onceInfoRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <ul>
                  <li>
                    Adatkezelő lehetővé teszi az érintettek számára, hogy a következőkben részletezett adataik megadásával az Adatkezelőtől információt, tájékoztatót kérjenek, vagy kérelem nélkül, tájékoztatást kapjanak.
                  </li>
                  <h3>
                    Mi az adatkezelés jogalapja?
                  </h3>
                  <li>
                    Az adatkezelés jogalapja függően a információkérés és információadás konkrét céljától, eszközétől, módjától:
                    <ul>
                      <li>
                        érintett önkéntes hozzájárulása, kérelme (pl. egy kérdés, kérés közlése az Adatkezelő felé)
                      </li>
                      <li>
                        jogszabályi kötelezettség teljesítése, amennyiben ilyen tájékoztatási kötelezettséget jogszabály előír
                      </li>
                      <li>
                        megállapodáson alapul (pl. Adatkezelő részéről az érintett felé tett teljesítésről/lehetetlenülésről szóló egyszeri tájékoztatás)
                      </li>
                      <li>
                        jogos érdeken alapul (pl. Adatkezelő adataiban történt változás)
                      </li>
                      <li>
                        érintett, vagy másik személy létfontosságú érdekeinek védelme miatt szükséges az adatkezelés.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Kik az érintettek?
                  </h3>
                  <li>
                    Az érintettek köre: Minden természetes személy, ideértve egy szervezet nevében eljáró képviselőt is, aki az Adatkezelővel kapcsolatba kerül és az Adatkezelőtől információt kér/kap.
                  </li>
                  <h3>
                    Melyek az adatkezelés során kezelt adatok körei és céljai?
                  </h3>
                  <li>
                    Kezelt adatok köre és célja tipikusan a következő:
                    <Grid
                      display='flex'
                      justifyContent='space-around'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          név*
                        </Typography>
                        <Typography>
                          e-mail cím/levelezési cím*
                        </Typography>
                        <Typography>
                          kérdés tartalma
                        </Typography>
                        <Typography>
                          tájékoztatás adatkörei
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          válaszadás, tájékoztatás
                        </Typography>
                        <Typography>
                          válaszadás, tájékoztatás
                        </Typography>
                      </Box>
                    </Grid>
                  </li>
                  <h3>
                    Mi az adatkezelés célja?
                  </h3>
                  <li>
                    Az adatkezelés célja az érintett számára megfelelő információ nyújtása és ezzel összhangban a kapcsolattartás.
                  </li>
                  <h3>
                    Hogyan történik az adatkezelés?
                  </h3>
                  <li>
                    Az adatkezeléssel érintett tevékenység és folyamat tipikusan, de nem kizárólagosan a következő:
                    <ul>
                      <li>
                        Az érintett az Adatkezelő által biztosított, számára elérhető úton keresztül vagy módon egyeztethet az Adatkezelővel az Adatkezelő szolgáltatásairól, és/vagy egyéb, kapcsolódó kérdésekről.
                      </li>
                      <li>
                        Az Adatkezelő az érintett kérdését megválaszolja és azt részére – ugyanazon az úton, ahogyan az információ kérés érkezett, ha érintett máshogyan nem rendelkezik –  eljuttatja.
                      </li>
                      <li>
                        Érintettet külön kérése nélkül is tájékoztathatja az Adatkezelő adott célból, ha ahhoz megfelelő jogalappal (pl. megállapodás, jogi köt. teljesítése, jogos érdek) rendelkezik.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Meddig tart az adatkezelés?
                  </h3>
                  <li>
                    Adatkezelés időtartama:
                    <ul>
                      <li>
                        cél megvalósulásáig, vagy
                      </li>
                      <li>
                        törlési kérelemig,
                      </li>
                      <li>
                        jogszabályban meghatározott határidőben, vagy
                      </li>
                      <li>
                        elévülési időben, vagy
                      </li>
                      <li>
                        jogos érdek megszűnéséig.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Mi az adatkezelés módja?
                  </h3>
                  <li>
                    Az adatkezelés módja: elektronikusan és/vagy papír alapon, manuálisan történik.
                  </li>
                  <h3>
                    Honnan vannak az adatok?
                  </h3>
                  <li>
                    Közvetlenül az érintettől.
                  </li>
                  <h3>
                    Történik adatközlés harmadik fél számára?
                  </h3>
                  <li>
                    Adatok harmadik fél számára nem kerülnek közlésre, kivéve, ha azt érintett kifejezetten kéri, vagy ha jogszabályi kötelezettségen alapul.
                  </li>
                  <h3>
                    Hogyan biztosítja az adatok védelmét az Adatkezelő?
                  </h3>
                  <li>
                    Szervezési és technikai intézkedéseket a kezelt adatok védelme érdekében: lásd külön fejezetben.
                  </li>
                  <h3>
                    Történik automatizált döntéshozatal, profilalkotás?
                  </h3>
                  <li>
                    Az adatkezelés kapcsán ilyen nem történik.
                  </li>
                  <h3>
                    Egyéb
                  </h3>
                  <li>
                    A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatást (adatkezelést) nem tudja nyújtani, elvégezni.
                  </li>
                </ul>
                <Typography className={classes.title} variant='h5'>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatok  </Typography>
                <Typography fontStyle='italic' marginBottom='40px'>Példálózó felsorolással élve ilyen az elektronikus alapú kapcsolattartás, mint az e-mail, vagy a postai úton vagy telefonon történő kapcsolattartás, stb. A kapcsolattartás ebben az adatkezelésben azt jelenti, hogy nem egyszeri alkalomról van szó.
                </Typography>
                <Typography fontStyle='italic' marginBottom='40px'>Ide tartozik a kapcsolatápolás ügyfelekkel, és az aktív ügyfelek telefonos megkeresése is.  </Typography>

                <Typography variant='h6' className={classes.tableTitle}>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatok összefoglaló táblázata </Typography>
                <BasicTable
                  rows={contactTalbeRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <ul>
                  <li>
                    Adatkezelő biztosítja, hogy az érintett vele különböző módokon és fórumokon folyamatosan, vagy rendszeresen kapcsolatot tartson. Példálózó felsorolással élve ilyen az elektronikus alapú kapcsolattartás,
                    mint az e-mail, vagy a postai úton vagy telefonon történő kapcsolattartás, stb. (Például érintettel folyatott levelezés.)
                  </li>
                  <h3>
                    Mi az adatkezelés jogalapja?
                  </h3>
                  <li>
                    Az adatkezelés jogalapja:
                    <ul>
                      <li>
                        az érintett önkéntes hozzájárulása (így érintett kérelme)
                      </li>
                      <li>
                        abban az esetben, ha az Adatkezelő és az érintett megállapodást kötnek egymással például az Adatkezelő valamely szolgáltatásának igénybevételéről, a rendszeres kapcsolattartás ezen adatkezeléshez kapcsolódó lesz és jogszerűsége a szerződéskötésen alapul (pl. teljesítésről/lehetetlenülésről szóló tájékoztatás)
                      </li>
                      <li>
                        kapcsolatfelvétel és kapcsolattartás, így a vonatkozó adatok kezelése alapulhat az érintett, harmadik személy, vagy az Adatkezelő jogos érdekén is
                      </li>
                      <li>
                        jogszabályi kötelezettség teljesítése
                      </li>
                      <li>
                        érintett, vagy másik személy létfontosságú érdekeinek védelme miatt szükséges az adatkezelés.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Kik az érintettek?
                  </h3>
                  <li>
                    Az érintettek köre: Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki az egyszeri információkérésen túl folyamatosan, vagy rendszeresen kapcsolatot tart az Adatkezelővel.
                  </li>
                  <h3>
                    Melyek az adatkezelés során kezelt adatok körei és céljai?
                  </h3>
                  <li>
                    A kezelt adatok köre és célja:
                    <Grid
                      display='flex'
                      justifyContent='space-around'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          név*
                        </Typography>
                        <Typography>
                          telefonszám
                        </Typography>
                        <Typography>
                          levelezési/e-mail cím*
                        </Typography>
                        <Typography>
                          kérdés, kérdés, egyéb,
                        </Typography>
                        <Typography>
                          érintett által megadott adat
                        </Typography>
                        <Typography>
                          tájékoztatás adatkörei
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          .
                        </Typography>
                        <Typography>
                          válaszadás
                        </Typography>
                        <Typography>
                          válaszadás, tájékoztatás
                        </Typography>
                      </Box>
                    </Grid>
                  </li>
                  <h3>
                    Mi az adatkezelés célja?
                  </h3>
                  <li>
                    Az adatok kezelésének célja az érintettel történő kapcsolattartás, felmerülő kérdések, kérések és egyebek megválaszolása, megoldása. Speciális cél lehet marketing/értékesítési aktivitás. Ebben az esetben lásd a konkrét cél vonatkozó adatvédelmi leírását/tájékoztatóját is.
                  </li>
                  <h3>
                    Hogyan történik az adatkezelés?
                  </h3>
                  <li>
                    Az adatkezeléssel érintett tevékenység és folyamat tipikusan, de nem kizárólagosan a következő:
                    <ul>
                      <li>
                        Az érintett az Adatkezelő által biztosított, számára elérhető úton keresztül vagy módon, példálózó felsorolással élve szóban (személyesen, telefonon) vagy írásban (postai úton, elektronikus levélben) felveszi a kapcsolatot, vagy kapcsolatot tart az Adatkezelővel és kérdést, kérést vagy egyebet intéz az Adatkezelő felé.
                      </li>
                      <li>
                        Kapcsolatot az Adatkezelő is felveheti az érintettel kérdése megválaszolása, vagy az Adatkezelő jogos érdeke alapján.
                      </li>
                      <li>
                        A kérdés, kérés, az adott tájékoztatás tartalma és a jogszabályok, belső szabályzatok alapján az Adatkezelő megteszi a szükséges lépéseket.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Meddig tart az adatkezelés?
                  </h3>
                  <li>
                    Adatkezelés időtartama:
                    <ul>
                      <li>
                        cél megvalósulásáig, vagy
                      </li>
                      <li>
                        törlési kérelem végrehajtásáig, vagy
                      </li>
                      <li>
                        elévüléséi időben,
                      </li>
                      <li>
                        jogszabályban meghatározott határidőben, vagy
                      </li>
                      <li>
                        jogos érdek megszűnéséig.
                      </li>
                    </ul>
                    <Typography>
                      Például: a levelezés bizonyítékául szolgálhat egy ellenőrzés során, ezért az Adatkezelő kezeli azt elévülési időben. Bizonylat alapjául is szolgálhat, tehát adott esetben a levelezés megőrizhető a számviteli bizonylat alátámasztása miatt.
                    </Typography>
                  </li>
                  <h3>
                    Mi az adatkezelés módja?
                  </h3>
                  <li>
                    Az adatkezelés módja: elektronikusan és/vagy papír alapon, manuálisan történik.
                  </li>
                  <h3>
                    Honnan vannak az adatok?
                  </h3>
                  <li>
                    Adatok forrása: közvetlenül az érintettől.
                  </li>
                  <h3>
                    Történik adatközlés harmadik fél számára?
                  </h3>
                  <li>
                    Adat harmadik fél számára akkor kerül közlésre, ha adatfeldolgozót vesz igénybe, vagy ha a továbbítás jogszabályi feltételei fennállnak. Adatkezelő az adattovábbításról tájékoztatja az érintettet, kivéve, ha jogszabály a tájékoztatást megtiltja.
                  </li>
                  <h3>
                    Hogyan biztosítja az adatok védelmét az Adatkezelő?
                  </h3>
                  <li>
                    Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: részletesen lásd külön fejezetben.
                  </li>
                  <h3>
                    Történik automatizált döntéshozatal, profilalkotás?
                  </h3>
                  <li>
                    Automatizált döntéshozatal, profilalkotás: az adatkezelés kapcsán ilyen nem történik.
                  </li>
                  <h3>
                    Egyéb
                  </h3>
                  <li>
                    A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatás nyújtását (adatkezelést) megtagadja.
                  </li>
                </ul>
                <Typography className={classes.title} variant='h5'>Időpontegyeztetés (időpontfoglalás) adatkezelése  </Typography>
                <Typography fontStyle='italic' marginBottom='40px'>Az adatkezelés független attól, hogy az időpontegyeztetés milyen módon, milyen fórumon keresztül történik, hogy mi annak konkrét célja, vagy hogy ki (Adatkezelő melyik szervezeti
                  egysége vagy Munkatársa, vagy érintett) kezdeményezi.
                </Typography>
                <Typography fontStyle='italic' marginBottom='40px'>Tipikus példa lehet ügyfél, vagy leendő ügyfél időpontegyeztetése az Adatkezelővel. </Typography>

                <Typography variant='h6' className={classes.tableTitle}>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatok összefoglaló táblázata </Typography>
                <BasicTable
                  rows={timeTableRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <ul>
                  <li>
                    Adatkezelő lehetővé teszi az érintettek számára, hogy a következőkben részletezett adataik megadásával az Adatkezelőtől időpontot kérjenek, amely során személyesen egyeztethetnek az Adatkezelővel annak szolgáltatásairól, termékeiről, egyéb kérdésekről.
                  </li>
                  <h3>
                    Mi az adatkezelés jogalapja?
                  </h3>
                  <li>
                    Az időpontegyeztetés és -foglalás
                    <ul>
                      <li>
                        önkéntes hozzájáruláson alapul, vagy
                      </li>
                      <li>
                        a GDPR 6. cikk 1. b. pontján alapul, ha 	az időpontegyeztetés olyan szerződés teljesítéséhez szükséges, amelyben az érintett az egyik fél, vagy az a szerződés megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Kik az érintettek?
                  </h3>
                  <li>
                    Az érintettek köre: Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki adatainak megadásával időpontot foglal/egyeztet.
                  </li>
                  <h3>
                    Melyek az adatkezelés során kezelt adatok körei és céljai?
                  </h3>
                  <li>
                    A kezelt adatok köre és célja:
                    <Grid
                      display='flex'
                      justifyContent='space-around'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          név*
                        </Typography>
                        <Typography>
                          telefonszám
                        </Typography>
                        <Typography>
                          levelezési/e-mail cím*
                        </Typography>
                        <Typography>
                          időpont*
                        </Typography>
                        <Typography>
                          téma/tárgy
                        </Typography>

                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          szolgáltatás biztosításához szükséges
                        </Typography>
                        <Typography>
                          felkészülés, megfelelő feltételek biztosítása
                        </Typography>
                      </Box>
                    </Grid>
                  </li>
                  <h3>
                    Mi az adatkezelés célja?
                  </h3>
                  <li>
                    Az adatkezelés célja az érintett számára időpont biztosítása és kapcsolattartás.
                  </li>
                  <h3>
                    Hogyan történik az adatkezelés?
                  </h3>
                  <li>
                    Az adatkezeléssel érintett tevékenység és folyamat tipikusan, de nem kizárólagosan a következő:
                    <ul>
                      <li>
                        Az érintett az Adatkezelő által biztosított, számára elérhető úton keresztül vagy módon egyeztethet az Adatkezelővel időpontot. Az Adatkezelő az időpontegyeztetés során az Adatkezelő számára az egyeztetés során feltárt adatokat elektronikus nyilvántartási rendszerben és/vagy papíralapon rögzíti és a lefoglalt időpontot az érintett felé megerősíti.
                      </li>
                      <li>
                        Ideális esetben az érintett az időpontban személyesen megjelenik az Adatkezelővel közösen megbeszélt helyen, hogy az érintett és az Adatkezelő az időpontegyeztetés konkrét célját teljesítsék.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Meddig tart az adatkezelés?
                  </h3>
                  <li>
                    Adatkezelés időtartama:
                    <ul>
                      <li>
                        cél megvalósulásáig.
                      </li>
                      <li>
                        abban az esetben, ha az időponthoz (vagy elmaradásához) valamilyen joghatás fűződik, vagy fűződhet, vagy jogi kötelezettség teljesítésének bizonyítása, vagy jogos érdek érvényesítése szempontjából releváns, akkor az adatokat az Adatkezelő megőrzi az általános elévülési időben, vagy a jogos érdek fennállásáig.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Mi az adatkezelés módja?
                  </h3>
                  <li>
                    Az adatkezelés módja: elektronikusan és/vagy papír alapon, manuálisan történik.
                  </li>
                  <h3>
                    Honnan vannak az adatok?
                  </h3>
                  <li>
                    Adatok forrása: közvetlenül az érintettől.
                  </li>
                  <h3>
                    Történik adatközlés harmadik fél számára?
                  </h3>
                  <li>
                    Adatközlés: harmadik fél számára nem kerül közlésre.
                  </li>
                  <h3>
                    Hogyan biztosítja az adatok védelmét az Adatkezelő?
                  </h3>
                  <li>
                    Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: lásd külön fejezetben.
                  </li>
                  <h3>
                    Történik automatizált döntéshozatal, profilalkotás?
                  </h3>
                  <li>
                    Automatizált döntéshozatal, profilalkotás: az adatkezelés kapcsán ilyen nem történik.
                  </li>
                  <h3>
                    Egyéb
                  </h3>
                  <li>
                    A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatás nyújtását (adatkezelést) megtagadja.
                  </li>
                </ul>
                <Typography className={classes.title} variant='h5'>Érintett által tett ajánlatkérési felhívással és Adatkezelő által tett ajánlatadással kapcsolatos adatkezelés  </Typography>
                <Typography fontStyle='italic' marginBottom='20px'>Az érintetti ajánlatkérés és arra adott ajánlat különböző módokon történhet, de az adatkezelés független a konkrét céltól, módtól, formától.
                </Typography>
                <Typography variant='h6' className={classes.tableTitle}>Érintett által tett ajánlatkérési felhívással és Adatkezelő által tett ajánlatadással kapcsolatos adatkezelés összefoglaló táblázata  </Typography>
                <BasicTable
                  rows={partnerTalbeRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <ul>
                  <li>
                    Adatkezelő lehetővé teszi az érintettek számára, hogy a következőkben részletezett adataik megadásával az Adatkezelőtől (akár egyedi, személyre szabott) ajánlatot kérjenek.
                  </li>
                  <h3>
                    Mi az adatkezelés jogalapja?
                  </h3>
                  <li>
                    Az ajánlatkérés önkéntes hozzájáruláson alapul, vagy GDPR 6. cikk (1) b) pontja, ha az ajánlatkérés az érintettel kötendő a szerződés megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges.
                  </li>
                  <h3>
                    Kik az érintettek?
                  </h3>
                  <li>
                    Az érintettek köre: Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki az Adatkezelőtől ajánlatot kér személyes adatainak megadása mellett.
                  </li>
                  <h3>
                    Melyek az adatkezelés során kezelt adatok körei és céljai?
                  </h3>
                  <li>
                    Kezelt adatok köre és célja:
                    <Grid
                      display='flex'
                      justifyContent='space-around'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          név*
                        </Typography>
                        <Typography>
                          telefonszám
                        </Typography>
                        <Typography>
                          e-mail cím
                        </Typography>
                        <Typography>
                          ajánlati felhívás tárgya, tartalma
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          ajánlatadáshoz
                        </Typography>

                      </Box>
                    </Grid>
                  </li>
                  <h3>
                    Mi az adatkezelés célja?
                  </h3>
                  <li>
                    Az adatkezelés célja az érintett számára megfelelő ajánlat nyújtása és kapcsolattartás.
                  </li>
                  <h3>
                    Hogyan történik az adatkezelés?
                  </h3>
                  <li>
                    Az adatkezeléssel érintett tevékenység és folyamat a következő:
                    <ul>
                      <li>
                        Az érintett az Adatkezelő által biztosított, számára elérhető úton keresztül vagy módon elküldi adatait az Adatkezelő részére.
                      </li>
                      <li>
                        Az Adatkezelő az érintett ajánlatkérésére megfelelő ajánlatot dolgoz ki, vagy ha az ajánlatadáshoz szükséges információ beszerzése, az érintett által megadott elérhetőségen az érintettel kapcsolatba lép és az információ birtokában dolgozza ki az ajánlatot, majd azt részére – ugyanazon az úton, ahogyan az ajánlatkérés érkezett, ha érintett máshogyan nem rendelkezik –  eljuttatja.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Meddig tart az adatkezelés?
                  </h3>
                  <li>
                    Adatkezelés időtartama: Adatkezelő
                    <ul>
                      <li>
                        ha az ajánlatra az érintett az ajánlat érvényességi időtartamán belül érdemben nem reagált, úgy az érvényességi időtartam lejártáig kezeli.
                      </li>
                      <li>
                        ha az ajánlatra az érintett az ajánlat érvényességi időtartamán belül érdemben reagált és azt elfogadta, úgy az Adatkezelő és az érintett között fennálló jogviszonyból származó jogok és kötelezettségek elévüléséig kezeli.
                      </li>
                      <li>
                        érintett érvényességi időben tett jelzése alapján az érvényességi időn túl is kezelheti az adatokat jogos érdek alapján, a jelzésben tett határideig.
                      </li>
                    </ul>
                    <Typography >
                      Az adatkezelés gyakorlati megvalósulása: ha érintett érdemben nem reagál az ajánlatra, úgy Adatkezelő törli az ajánlatkéréssel és –adással kapcsolatos minden, adatot tartalmazó dokumentumot (pl. kimenő e-mail, ajánlat), vagy ezekből a személyes adatokat.
                    </Typography>
                  </li>
                  <h3>
                    Mi az adatkezelés módja?
                  </h3>
                  <li>
                    Az adatkezelés módja: elektronikusan és/vagy papír alapon, de jellemzően elektronikusan, manuálisan történik.
                  </li>
                  <h3>
                    Honnan vannak az adatok?
                  </h3>
                  <li>
                    Adatok forrása: közvetlenül az érintettől.
                  </li>
                  <h3>
                    Történik adatközlés harmadik fél számára?
                  </h3>
                  <li>
                    Adat harmadik fél számára nem kerül közlésre.
                  </li>
                  <h3>
                    Hogyan biztosítja az adatok védelmét az Adatkezelő?
                  </h3>
                  <li>
                    Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: részletesen lásd külön fejezetben.
                  </li>
                  <h3>
                    Történik automatizált döntéshozatal, profilalkotás?
                  </h3>
                  <li>
                    Automatizált döntéshozatal, profilalkotás: az adatkezelés kapcsán ilyen nem történik.
                  </li>
                  <h3>
                    Egyéb
                  </h3>
                  <li>
                    A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatás nyújtását (adatkezelést) megtagadja.
                  </li>
                </ul>
                <Typography className={classes.title} variant='h5'>
                  Megállapodás megkötésével kapcsolatos adatkezelés
                </Typography>
                <Typography fontStyle='italic' marginBottom='20px'>Ide tartozik bármilyen megállapodás megkötésével kapcsolatos adatkezelés, legyen a szerződő fél érintett, vagy Partner,  vagy harmadik fél kapcsolattartója.
                </Typography>
                <Typography variant='h6' className={classes.tableTitle}>Megállapodás megkötésével kapcsolatos adatkezelés összefoglaló táblázata  </Typography>
                <BasicTable
                  rows={agreementTableRows}
                  columns={dataManagementTableColumns}
                >
                </BasicTable>
                <ul>
                  <li>
                    Adatkezelő az egyes szolgáltatásainak nyújtását vonatkozó megállapodás előzetes megkötéséhez, mint feltételhez köti.
                  </li>
                  <h3>
                    Mi az adatkezelés jogalapja?
                  </h3>
                  <li>
                    Jogalap:
                    <ul>
                      <li>
                        megállapodás megkötése esetén az adatkezelés jogalapja a 6. cikk 1. b. pontja, ha a szerződést kötő fél az érintett maga.
                      </li>
                      <li>
                        Amennyiben a megállapodásban az érintett szervezet képviselője, vagy kapcsolattartója, úgy adatainak kezelése jogos érdeken alapul.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Kik az érintettek?
                  </h3>
                  <li>
                    Az érintettek köre: Minden természetes személy, valamint szervezet nevében, képviseletében eljáró természetes személy, aki az Adatkezelővel – személyes adatok megadása mellett – saját nevében megállapodást köt, vagy a szerződésben képviselőként, vagy kapcsolattartóként szerepel.
                  </li>
                  <h3>
                    Melyek az adatkezelés során kezelt adatok körei és céljai?
                  </h3>
                  <li>
                    Kezelt adatok köre és célja: lásd a megállapodásban, de tipikusan a következők:
                    <Grid
                      display='flex'
                      justifyContent='space-around'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          név*
                        </Typography>
                        <Typography>
                          szül. hely, idő
                        </Typography>
                        <Typography>
                          anyja neve
                        </Typography>
                        <Typography>
                          telefonszám*
                        </Typography>
                        <Typography>
                          e-mail cím
                        </Typography>
                        <Typography>
                          szerződés tárgya
                        </Typography>
                        <Typography>
                          fizetési feltételek
                        </Typography>
                        <Typography>
                          jogok és
                          kötelezettségek
                        </Typography>

                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          azonosítás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          kapcsolattartás
                        </Typography>
                        <Typography>
                          szerződés tartalmi eleme
                        </Typography>
                        <Typography>
                          szerződés tartalmi eleme
                        </Typography>
                        <Typography>
                          szerződés tartalmi eleme
                        </Typography>
                      </Box>
                    </Grid>
                  </li>
                  <h3>
                    Mi az adatkezelés célja?
                  </h3>
                  <li>
                    Az adatkezelés célja a megállapodás megkötése, teljesítése, teljesítésének felügyelete, kapcsolattartás.
                  </li>
                  <h3>
                    Hogyan történik az adatkezelés?
                  </h3>
                  <li>
                    Az adatkezeléssel érintett tevékenység és folyamat jellemzően a következő:
                    <ul>
                      <li>
                        Az érintett az Adatkezelő egyszeri vagy többszöri alkalommal egyeztetnek egymással a megállapodás részleteiről.
                      </li>
                      <li>
                        Érintett adatait a megállapodás létrehozásához az Adatkezelő számára megadja.
                      </li>
                      <li>
                        Felek önkéntesen és befolyásmentesen kötik meg egymással a megállapodást.
                      </li>
                      <li>
                        Adatkezelő a megállapodást az erre rendszeresített elektronikus és/vagy papír alapú nyilvántartási rendszerben rögzíti későbbi visszakereshetőség, bizonyítás alapjaként.
                      </li>
                      <li>
                        Adatkezelő érintettet, vagy érintett az Adatkezelőt a teljesítése folyamatában értesítheti a teljesítés egyes lépéseiről.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Meddig tart az adatkezelés?
                  </h3>
                  <li>
                    Adatkezelés időtartama:
                    <ul>
                      <li>
                        jogviszony időtartama alatt és azt követően a jogviszonyra vonatkozó elévülési idő leteltéig (általános elévülési idő: 5 év);
                      </li>
                      <li>
                        előfordulhat olyan megállapodás, amely maga határozza meg azt, hogy a jogviszony megszűnését követően mennyi ideig kell azt megőrizni, tárolni, tehát kezelni;
                      </li>
                      <li>
                        előfordulhat olyan megállapodás, amelyet jogszabály vagy az Adatkezelő nem leselejtezhetőnek minősít, ekkor az nem törölhető, nem semmisíthető meg.
                      </li>
                    </ul>
                  </li>
                  <h3>
                    Mi az adatkezelés módja?
                  </h3>
                  <li>
                    Az adatkezelés módja: elektronikusan és/vagy papír alapon, manuálisan történik.
                  </li>
                  <h3>
                    Honnan vannak az adatok?
                  </h3>
                  <li>
                    Adatok forrása: közvetlenül az érintettől.
                  </li>
                  <h3>
                    Történik adatközlés harmadik fél számára?
                  </h3>
                  <li>
                    Adatközlés: amennyiben harmadik fél felé közlésre kerül, úgy e címzett adatait a II. sz. melléklet tartalmazza.
                  </li>
                  <h3>
                    Hogyan biztosítja az adatok védelmét az Adatkezelő?
                  </h3>
                  <li>
                    Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: lásd külön fejezetben.
                  </li>
                  <h3>
                    Történik automatizált döntéshozatal, profilalkotás?
                  </h3>
                  <li>
                    Automatizált döntéshozatal, profilalkotás: az adatkezelés kapcsán ilyen nem történik.
                  </li>
                  <h3>
                    Egyéb
                  </h3>
                  <li>
                    A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatás nyújtását (adatkezelést) megtagadja.
                  </li>
                </ul>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default DataManagementInformation
