import { Box, Card, CardActionArea, CardContent, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({

  gradientTitle: {
    background: '-webkit-linear-gradient(165deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    transition: '0.5s',
    height: '100%',
    '&:hover': {
      boxShadow: '8px 5px 20px 0px rgb(0 0 0 / 10%)',
      transition: '0.25s',
      transform: 'scale(1.02)',
    }
  }
})

function Career() {
  const { t, } = useTranslation();
  const classes = useStyles()

  document.title = t('Title.main') + " | " + t('Title.subTitle.career')

  const items = [
    {
      title: "Frontend fejlesztő",
      url: "/career/frontend",
    },
    {
      title: "Backend fejlesztő",
      url: "/career/backend",
    },
    {
      title: "Webfejlesztő",
      url: "/career/webdeveloper",
    }
  ]

  return (
    <React.Fragment>
      <Paper>
        <Box
          className='main'
          minHeight='80vh'
          sx={{
            // background: 'white',
            // background: 'linear-gradient(150deg, rgba(60,185,184,0.8186624991793592) 0%, rgba(255,255,255,0.5413515748096114) 42%)',
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Container>
            <Typography className={classes.gradientTitle} marginBottom='15vh' textAlign='center' variant='h2' color='secondary' fontWeight='bold'>"Fejlessz velünk, alkossunk közösen!</Typography>
            <Typography marginBottom='10vh' textAlign='center' variant='h3'  >Nézd meg nyitott pozícióinkat</Typography>
            <Grid
              spacing={5}
              display='flex'
              alignItems='center'
              justifyContent='center'
              container
            >
              {items.map((item) => (
                <Grid
                  item
                  lg={3}
                  md={3}
                  xl={3}
                  sm={6}
                  xs={10}
                >
                  <CardActionArea component={Link} target="_blank" to={item.url}>
                    <Card
                      elevation={1}
                      className={classes.card}
                    >
                      <CardContent>
                        <Typography className={classes.title} variant='h6'  >{item.title}</Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                  {/* <Element
                    buttonText={'Bővebben'}
                    title={item.title}
                    url={item.url}
                  /> */}
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default Career
