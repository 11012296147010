import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import theme from '../../theme/index'
import { Typography, Tooltip } from '@material-ui/core';

const StyledTableCell = withStyles({
  head: {
    // backgroundColor: theme.palette.default.light,
    // color: theme.palette.common.white,
    fontSize: 19,
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
  },
  body: {
    color: theme.palette.default.main,
    fontSize: 14,
    border: `1px solid ${theme.palette.default.dark}`,
  },
})(TableCell);

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: '#fbfbfb',
    },
  },
})(TableRow);

const useStyles = makeStyles({
  table: {
    //minWidth: 700,
  },
});

export default function BasicTable(props) {
  const classes = useStyles();
  const [maxLength, setMaxLength] = useState(0);

  return (
    <TableContainer
    // component={Paper}
    // style={{ overflowX: 'initial' }}
    >
      <Table
        className={classes.table}
        aria-label="customized table"
        style={{ borderCollapse: 'inherit' }}
      // stickyHeader
      //size="small"
      >
        <TableHead>
          <TableRow>
            {props && props.columns && props.columns.map((column) => (
              <StyledTableCell align={column.align}>{column.title}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props && props.rows && props.rows.map((row) => (
            <StyledTableRow key={row.name}>
              {row.name && <Tooltip title={row.tooltip == null ? "" : row.tooltip} arrow placement="top">
                <StyledTableCell
                  align="left"
                  rowSpan={row.rowSpan}
                >
                  <Typography color='black' variant='subtitle2'>
                    {row.name}
                  </Typography>
                </StyledTableCell>
              </Tooltip>}
              {maxLength < row.details.length ? setMaxLength(row.details.length) : null}
              {row.details.map((detail) => (
                <StyledTableCell
                  align="center"
                  colSpan={row.colSpan || maxLength / row.details.length}
                  style={{
                    color: row.color || null,
                    fontWeight: row.color ? 'bold' : null
                  }}
                >
                  {detail}
                  {detail === true && <CheckIcon />}
                  {detail === false && <RemoveIcon />}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
