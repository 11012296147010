import {
  Box,
  Card,
  CardContent,
  Grid,
  Hidden,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import SwiperCore, {
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
  Virtual,
  Zoom
} from 'swiper';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import "swiper/components/zoom/zoom.min.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import theme from '../../theme/index';
import goals from '../../static/public/ClientMeeting/goals.png'
import financialOverview from '../../static/public/ClientMeeting/financialOverview.png'
import summary from '../../static/public/ClientMeeting/summary.png'
import personalData from '../../static/public/ClientMeeting/personalData.png'
import investmentTime from '../../static/public/ClientMeeting/investmentTime.png'
import financialRecommendation from '../../static/public/ClientMeeting/financialRecommendation.png'
import portfolio from '../../static/public/ClientMeeting/portfolio.png'
import brokerageAgreement from '../../static/public/ClientMeeting/brokerageAgreement.png'
import negotiationSummary from '../../static/public/ClientMeeting/negotiationSummary.png'
import recommendation from '../../static/public/ClientMeeting/recommendation.png'

SwiperCore.use([Zoom, Virtual, Mousewheel, Autoplay, Pagination, Navigation]);

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    border: `17px solid ${theme.palette.secondary.dark}`,
  },
  box: {
    borderRadius: 5,
    border: `20px solid ${theme.palette.secondary.light}`,
  },
  textClass: {
    margin: '25px',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  imgClass: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
})

const ScrollList = (props) => {
  const { startIndex } = props;
  const { t, } = useTranslation();
  const classes = useStyles();
  const ref = useRef(null)

  const [swiperRef, setSwiperRef] = useState();
  const [activeButton, setActiveButton] = useState(startIndex || 0);

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
  };

  const costumerProcess = [
    { text: t('products.salesActivity.scrollList.consentForm') },
    { text: t('products.salesActivity.scrollList.personalDetails') },
    { text: t('products.salesActivity.scrollList.financialOverview') },
    { text: t('products.salesActivity.scrollList.goals') },
    { text: t('products.salesActivity.scrollList.invevestmentHorizon') },
    { text: t('products.salesActivity.scrollList.portfolio') },
    { text: t('products.salesActivity.scrollList.financialProposal') },
    // { text: t('products.salesActivity.scrollList.productsParameters') },
    { text: t('products.salesActivity.scrollList.recommendation') },
    { text: t('products.salesActivity.scrollList.summary') },
    { text: t('products.salesActivity.scrollList.meetingSummary') },
  ]
  return (
    <Grid
      id='main-grid'
      ref={ref}
      container
      spacing={0}
      style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <Hidden mdDown>
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          style={{ height: '100%' }}
        >
          {costumerProcess.map((text, index) => {
            return <div
              onClick={() => slideTo(index + 1)}
              className={classes.textClass}
              key={index}
            >
              <Typography
                marginRight={2}
                variant='h5'
                color='secondary'
              >
                {index < 9 ? `0${index + 1}.` : `${index + 1}. `}
              </Typography>
              <Typography
                variant='h5'
                color={activeButton === index ? 'secondary' : 'default'}
              >
                {text.text}
              </Typography>
            </div>
          })}
        </Grid>
      </Hidden>
      <Grid
        item
        lg={8}
        sm={8}
        xl={8}
        xs={12}
      >
        <Box className={classes.box}>
          <Card
            className={classes.root}
            id='card'
          >
            <CardContent
              style={{ height: 500, display: 'flex', justifyContent: 'center' }}
            >
              <Swiper
                onSwiper={setSwiperRef}
                initialSlide={startIndex}
                mousewheel={true}
                slidesPerView={1}
                centeredSlides={true}
                spaceBetween={30}
                autoplay={{
                  "delay": 2500,
                  "disableOnInteraction": true,
                }}
                zoom={true}
                onSlideChange={() => setActiveButton(swiperRef?.activeIndex || startIndex)}
              >
                <div>
                  <SwiperSlide>
                    <div>
                      <img alt="brokerageAgreement" className={classes.imgClass}
                        src={brokerageAgreement} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="personalData" className={classes.imgClass}
                        src={personalData} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="financialOverview" className={classes.imgClass}
                        src={financialOverview} />
                    </div >
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="goals" className={classes.imgClass}
                        src={goals} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="investmentTime" className={classes.imgClass}
                        src={investmentTime} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="portfolio" className={classes.imgClass}
                        src={portfolio} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="financialRecommendation" className={classes.imgClass}
                        src={financialRecommendation} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="recommendation" className={classes.imgClass}
                        src={recommendation} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="summary" className={classes.imgClass}
                        src={summary} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img alt="negotiationSummary" className={classes.imgClass}
                        src={negotiationSummary} />
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ScrollList