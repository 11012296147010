import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../components/DataTable/DataTable';

const useStyles = makeStyles({
  subtitle: {
    marginBottom: '40px',
    marginTop: '50px',
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bold',
    marginLeft: '20px',
    marginBottom: '80px',
    marginTop: '50px'
  }
})

const columns = [
  { field: 'name', headerName: 'Név', width: 120 },
  { field: 'value', headerName: 'Érték', width: 300 },
  { field: 'domain', headerName: 'Domain', width: 140 },
  { field: 'path', headerName: 'Path', width: 120 },
  { field: 'expiryDate', headerName: 'Lejárat', width: 200 },
  { field: 'size', headerName: 'Mérte', width: 130 },
];

const rows = [
  //TODO '1p_jar is expired
  { id: 1, name: '1P_JAR', value: '	2019-12-3-8', domain: '.google.com', path: '/', expiryDate: '2020-01-02T08:29:22.000Z', size: '17' },
  { id: 2, name: 'ANID', value: 'AHWqTUmO2bNaCNbKHZ2o5IHVF0XM2WgQM_LkhTPwCq74_1JaiH9rR_Jl7YainLzU', domain: '.google.com', path: '/', expiryDate: '2020-05-26T09:08:31.057Z', size: '68' },
  { id: 3, name: 'APISID', value: 'X9g1xAzHev-vee0E/AyPue5qFnB8Cye4mi', domain: '.google.com', path: '/', expiryDate: '2021-11-27T13:29:03.223Z', size: '40' },
  { id: 4, name: 'CONSENT', value: 'YES+HU.hu+20150712-15-0	', domain: '.google.com', path: '/', expiryDate: '2038-01-10T08:00:00.025Z', size: '30' },
  { id: 5, name: 'HSID', value: 'Agqv50YZRA4wLQcAI', domain: '.google.com', path: '/', expiryDate: '2021-11-27T13:29:03.223Z', size: '21' },
  { id: 6, name: 'NID', value: '192=2BXggGk0ac5UAw5UI1PlwFMEq_EplW7L6Ac8cIi5Yc5Zb2hoMNi9lg_FCH5Okr6tI5ReMaRmXflyfUOLLjG80pHTPf_\noPaLEfOL3ujzgDyGTBeSkoGBOBU8N2MTJyvYtcCbEW1topt2j9xdqgUUfA_\n57tuQ45RuVGXLqy2nfJk2FELQwGaMc6DsJ6UKDeHC5qAZpqN5NXpExNtRHcPVrenLUCBVqr7\n-6KrvYcmh-rTDE1_eNWwcb1cOYwKTNW7RDms5983yxHYCniUEA', domain: '.google.com', path: '/', expiryDate: '2020-06-03T07:48:25.911Z', size: '281' },
  //TODO OTZ is expired
  { id: 7, name: 'OTZ', value: '5190342_52_52_123900_48_436380', domain: 'www.google.com', path: '/', expiryDate: '2019-12-14T09:41:56.000Z', size: '33' },
  { id: 8, name: 'S', value: 'billing-ui-v3=7_a1A3bIDWtIPDiSLYVqFzExtAFfUZX0:billing-ui-v3-efe=7_a1A3bIDWtIPDiSLYVqFzExtAFfUZX0', domain: '.google.com', path: '/', expiryDate: 'Session', size: '98' },
  { id: 9, name: 'SAPISID', value: 'kPkOqOuvpD65YQ74/ASIGTIMzt1Aej70pQ', domain: '.google.com', path: '/', expiryDate: '2021-11-27T13:29:03.223Z', size: '41' },
  //TODO saerch_samesite is expired
  { id: 10, name: 'SEARCH_SAMESITE', value: 'CgQIqI4B', domain: '.google.com', path: '/', expiryDate: '2020-05-14T10:22:43.067Z', size: '23' },
  { id: 11, name: 'SID', value: 'qwf6rCP5UJGIZRJZt_V6cbW-3CPPj0phelOsA2bUD7DANAvXYbk3B07qlen7dY9kGz3sEw.', domain: '.google.com', path: '/', expiryDate: '2021-11-27T13:49:49.897Z', size: '74' },
  // TODO expired
  { id: 12, name: 'SIDCC', value: 'AN0-TYtf8JRolQto6ApuQRRmuBry_xUwZ1OjpcsU8_pG6SiMH0ebhPik4GBcX8ZNqQhmavJjKys', domain: '.google.com', path: '/', expiryDate: '2020-03-02T08:40:04.869Z', size: '80' },
  { id: 13, name: 'SSID', value: 'A1uviNKtyvK-tnzqo', domain: '.google.com', path: '/', expiryDate: '2021-11-27T13:29:03.223Z', size: '21' },
  { id: 14, name: '_ga', value: 'GA1.2.80211327.1570811062', domain: '.aocsystem.com', path: '/', expiryDate: '2021-12-02T08:40:07.000Z', size: '28' },
  // TODO expired
  { id: 15, name: '_gat_gtag_UA_149844297_1', value: '1', domain: '.aocsystem.com', path: '/', expiryDate: '2019-12-03T08:41:07.000Z', size: '25' },
  // TODO expired
  { id: 16, name: '_gid', value: 'GA1.2.991404830.1575361390', domain: '.aocsystem.com', path: '/', expiryDate: '2019-12-04T08:40:07.000Z', size: '30' },
  { id: 17, name: 'moove_gdpr_popup', value: '%7B%22strict%22%3A%221%22%2C%22thirdparty%22%3A%221%22%2C%22advanced%22%3A%221%22%7D', domain: '.aocsystem.com', path: '/', expiryDate: '2020-09-11T08:28:38.000Z', size: '100' },
  // TODO expired
  { id: 18, name: 'wfwaf-authcookie-f76c3dcf007ddab104bc4e4cca9cbe74', value: '1%7Cadministrator%7C23daed88ca695876169765801c364237ed3fc82746df3b8f6c9520c8a8812772', domain: '.aocsystem.com', path: '/', expiryDate: '2019-12-03T20:23:35.823Z', size: '133' },
];

const columnStatistic = [
  { field: 'name', headerName: 'Név', width: 150 },
  { field: 'provider', headerName: 'Szolgáltató', width: 160 },
  { field: 'goal', headerName: 'Cél', width: 140 },
  { field: 'expiryDate', headerName: 'Lejárat', width: 130 },
  { field: 'type', headerName: 'Típus', width: 120 },
  { field: 'placeHolder', headerName: 'Tároláshelye', width: 170 },
]

const rowsStatistic = [
  { id: 1, name: 'Google Analitics', provider: 'Google LLC', goal: 'Látogatói felmérés', expiryDate: '24  hó', type: 'ID', placeHolder: 'Google szerverén' }
]

const columnDatahandler = [
  { field: 'name', headerName: 'Adatfeldolgozó neve', width: 220 },
  { field: 'contact', headerName: 'Adatfeldolgozó elérhetősége', width: 270 },
  { field: 'task', headerName: 'Végzett feladat, amely kapcsán kezeli az adatokat', width: 410 },
]

const rowDatahandler = [
  { id: 1, name: 'Cég, aki a weboldalt kezeli', contact: 'Elérhetősége', task: 'Weboldal frissítés, karbantartás' }
]

export default function Privacy() {
  const { t, } = useTranslation();
  const classes = useStyles()

  document.title = t('Title.main') + " | " + t('Title.subTitle.cookie')
  
  return (
    <Paper>
          <Box style={{ height: '30vh', marginBottom: '5vh' }} backgroundColor='#212c38' display='flex' alignItems='center'>
            <Container>
              <Typography fontWeight='bold' variant='h3' textAlign='left' style={{ color: 'white' }}>Cookie (süti) tájékoztató</Typography>
            </Container>
          </Box>
      <Box
        sx={{
          // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
          // minHeight: '100vh',
          py: 10
        }}
      >
        <Container>
          <Grid
            className='main'
            container
            spacing={3}
            textAlign='justify'
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Typography >
                Az adatkezelő, az AOC System Zrt. (székhelye: 1062 Budapest, Délibáb u. 8., cégjegyzékszám: 01-10-048930, adószáma: 25723258-2-42, e-mail cím: office@aocsystem.com, adatvédelmi tisztviselő: Dr. Bölcskei Krisztián, dpo@aocsystem.com) védi a személyes adatok bizalmasságát, biztonságát. Az Európai Unió 679/2016 számú rendeletének (“GDPR”), és a személyes adatok kezelésére, védelmére vonatkozó hatályos uniós és nemzeti jogszabályok értelmében Ön, mint a weboldal felhasználója (érintett) az alábbi tájékoztatást kapja a weboldalon használt cookie-kről, vagy más néven sütikről.
              </Typography>
              <Typography variant='h6' className={classes.subtitle}>Mi az a cookie (süti)?</Typography>
              <Typography marginBottom='20px'>
                A sütik kis szövegláncok, amelyeket a felhasználó által felkeresett webhelyek a felhasználó termináljára (általában a böngészőbe) küldenek, ahol eltárolják őket, hogy azokat a felhasználó következő látogatásakor továbbküldhessék ezekre a webhelyekre. A webhely böngészésekor olyan sütik is érkezhetnek a felhasználó termináljára, amelyeket különböző webhelyekről vagy webszerverekről (úgynevezett „harmadik felek”) küldenek, amelyeken a felhasználó által felkeresett webhely bizonyos elemei (például képek, térképek, hangok, más domainek oldalaira mutató linkek) találhatók.
              </Typography>
              <Typography variant='h6' className={classes.subtitle}>Adatkezelő hogyan használja a sütiket?</Typography>
              <Typography marginBottom='10px'>
                A sütik – érintett hozzájárulásával, esetleg más jogalap alkalmazása mellett – telepíthetők a weboldalról vagy a vonatkozó aldomainről.
              </Typography>
              <Typography marginBottom='10px'>
                Az érintett bármikor, bármely esetben kérheti a sütik deaktiválását vagy törlését, és ezt a webböngészője beállításainál teheti meg.
              </Typography>
              <Typography marginBottom='10px'>
                Fontos tudni, hogy ez a deaktiválás azt eredményezheti, hogy a weboldal egyes részei lelassulhatnak, vagy elérhetetlenné válhatnak, nem tudják betölteni a funkcióikat.
              </Typography>
              <Typography marginBottom='10px'>
                A sütik kezelése, beállítása vagy deaktiválása web böngészőnként változik, ezért az Adatkezelő azt javasolja az érintett számára, hogy ha szeretne további információkat kapni ezekkel a műveletekkel kapcsolatban, akkor tekintse át web böngészőjének “Súgó” funkcióját.
              </Typography>
              <Typography marginBottom='10px'>
                Az érintett az alábbi hivatkozásokban megtalálhatja, hogy hogyan lehet deaktiválni a sütiket a leggyakoribb böngészőkben:
              </Typography>
              <Typography>
                <ul>
                  <li><a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href="https://support.google.com/chrome/answer/95647?hl=hu">Google Chrome</a></li>
                  <li><a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href="https://support.apple.com/hu-hu/HT201265">Safari</a></li>
                  <li><a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a></li>
                  <li><a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href="https://support.mozilla.org/hu/kb/sutik-informacio-amelyet-weboldalak-tarolnak-szami">Mozilla Firefox</a></li>
                  <li><a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href="https://support.microsoft.com/hu-hu/microsoft-edge/cookie-k-t%C3%B6rl%C3%A9se-a-microsoft-edge-ben-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</a></li>
                  <li><a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                </ul>
              </Typography>
              <Typography marginBottom='80px'>
                A weboldalon használt sütik két kategóriára bonthatók: „Kötelező” és „Statisztikai” sütik.
              </Typography>
              <Typography variant='h6' className={classes.subtitle}>Milyen célokat szolgálnak a sütik?</Typography>
              <Typography marginBottom='10px'>
                Adatkezelő használ <span style={{ fontWeight: 'bold' }}>kötelező süti</span>ket, amely csoport több típusú sütit tartalmaz:
              </Typography>
              <Typography marginBottom='10px'>
                <ul>
                  <li>műszaki,</li>
                  <li>funkcionális műszaki és</li>
                  <li>műszaki analitikai sütkiet.</li>
                </ul>
              </Typography>
              <Typography marginBottom='10px'>
                Műszaki sütik, azaz elektronikus kommunikációs hálózaton keresztüli adatátvitelhez szükséges sütik vagy olyan sütik használata, amelyekre feltétlenül szüksége van a szolgáltatónak az érintett által kért szolgáltatás nyújtásához, és amely lehetővé teszi a weboldalunk biztonságos és hatékony használatát.
              </Typography>
              <Typography marginBottom='10px'>
                A műszaki sütik elengedhetetlenek ahhoz, hogy weboldal megfelelően működjön, és ezek a sütik segítik az érintetteket, hogy sikeresen navigáljanak az oldalakon és használják az oldalakon elérhető szolgáltatásokat. A felhasznált műszaki sütik kizárólag a böngészés ideje alatt kerülnek tárolásra addig, ameddig a böngésző bezárásra nem kerül, az állandó sütik viszont elmentésre kerülnek az érintett eszközén, és vagy elévülésükig vagy a törlésükig ott is maradnak.
              </Typography>
              <Typography marginBottom='10px'>
                A weboldal a következő műszaki sütiket használja:
              </Typography>
              <Typography>
                <ul>
                  <li style={{ marginBottom: '10px' }}>Műszaki navigációs vagy munkamenet sütik, a böngészést és a felhasználó azonosítását segítik. A munkamenet sütik a hitelesített felhasználóknak megkönnyítik a hozzáférést a weboldal korlátozott részeihez és az ottani folyamatos jelenléthez. </li>
                  <li style={{ marginBottom: '10px' }}>Funkcionális műszaki sütik, a felhasználó személyéhez tartozó beállítások megjegyzésére használatosak, mint például a nyelv;</li>
                  <li style={{ marginBottom: '10px' }}>Műszaki analitikai sütik, amelyek felismerik, hogy a felhasználó milyen módon használja az oldalakat, a funkcionalitás értékelése és javítása érdekében, például azáltal, hogy átfogó információkat gyűjtenek a webhelyet felkereső felhasználók számáról és/vagy arról, hogy mely oldalakat látogatják meg a leggyakrabban.</li>
                </ul>
              </Typography>
              <Typography marginBottom='20px'>
                A műszaki sütik telepítéséhez nincsen szükség az érintett hozzájárulására, de lehetősége van a sütiket kikapcsolni, törölni (lásd korábban).
              </Typography>
              <Typography marginBottom='20px'>
                Az alábbi táblázat összefoglalja a kötelező sütiket:
              </Typography>
              <DataTable height={370}>{rows}{columns}</DataTable>
              <Typography variant='h6' className={classes.subtitle} marginTop='20px' >
                Statisztikai sütik
              </Typography>
              <Typography marginBottom='20px'>
                Fontos az Adatkezelő számára, hogy tudja, mi történik az oldalain és szolgáltatásaival, felhasználói hogyan használják a szolgáltatásokat. Ebből a célból statisztikai adatokat, beleértve személyes adatokat gyűjt az Adatkezelő a weboldal használata során.
              </Typography>
              {/* TODO */}
              <DataTable height={165}>{rowsStatistic}{columnStatistic}</DataTable>

              <Typography marginTop='20px'>
                Amennyiben az érintett a statisztikai sütik kezeléséhez hozzájárul, úgy ezzel a beállítással engedélyezi a következőket:
              </Typography>
              <ul>
                <li>Statisztikai célú webanalitikai mérések</li>
                <li>Hirdetések megjelenítése az oldalon</li>
              </ul>
              <Typography marginBottom='30px'>
                Ezzel a beállítással NEM engedélyezi:
              </Typography>
              <ul>
                <li>Érdeklődési körének megfelelő hirdetések (remarketing) megjelenítése a weboldalon kívül (pl. közösségi oldalak)</li>
                <li>Oldal-használat elemzését annak érdekében, hogy testreszabott tartalmat jelenítsünk meg a weboldalon</li>
              </ul>
              <Typography variant='h6' className={classes.subtitle} >
                Történik adatközlés harmadik fél számára?
              </Typography>
              <Typography marginBottom='10px'>
                Adattovábbítás, -átadás harmadik fél számára az alábbiak szerint történhet:
              </Typography>
              <Typography marginBottom='20px'>
                Adatkezelő a cookie-k és a cookie-k által gyűjtött érintetti személyes adatok olyan harmadik személyek és / vagy címzettek felé kerülhetnek közlésre (továbbításra, átadásra), akik tevékenysége vagy az Adatkezelő által az érintettek számára nyújtott szolgáltatások biztosításához elengedhetetlenül szükséges (adatfeldolgozók), vagy az Adatkezelő jogi kötelezettségeinek teljesítéséhez szükséges (pl. hatóságok, bíróság, más állami szervek hivatalos megkeresése esetén).
              </Typography>
              <DataTable height={165}>{rowDatahandler}{columnDatahandler}</DataTable>

              <Typography marginBottom='20px' marginTop='20px'>
                Adatkezelő felhívja az érintett figyelmét arra, hogy a nevezett adatfeldolgozóval (adatfeldolgozókkal) külön adatfeldolgozási megállapodást kötött, hogy az adatbiztonsági előírásokat és a GDPR-ból, valamint a 2011. évi CXII. tv-ből fakadó előírásokat maradéktalanul teljesítse.
              </Typography>
              <Typography marginBottom='50px'>
                Amennyiben egy cookie nem az Adatkezelőhöz tartozik, hanem külön megnevezésre került a „Szolgáltató” (lásd a fenti táblázatot), ott az adatkezelő a Szolgáltató lesz és az adatok hozzá kerülnek és ő a saját adatkezelési tájékoztatója, szabályzata alapján kezeli azokat, amelyért az Adatfeldolgozó nem vállal felelősséget.
              </Typography>
              <Typography variant='h6' className={classes.subtitle} marginBottom="10px" >
                Milyen adatbiztonsági intézkedéseket hajt végre az Adatkezelő (és az adatfeldolgozó, ha ilyet az Adatkezelő igénybe vesz)?
              </Typography>
              <Typography>
                Az Adatkezelő (és adatfeldolgozó) az informatikai védelemmel kapcsolatos feladatai körében gondoskodik különösen:
              </Typography>
              <Typography marginBottom='30px'>
                <ul>
                  <li>az adatkezeléshez használt eszközök (a továbbiakban: adatkezelő rendszer) jogosulatlan személyek általi hozzáférésének megtagadásáról,</li>
                  <li>az adathordozók jogosulatlan olvasásának, másolásának, módosításának vagy eltávolításának megakadályozásáról,</li>
                  <li>az adatkezelő rendszerbe a személyes adatok jogosulatlan bevitelének, valamint az abban tárolt személyes adatok jogosulatlan megismerésének, módosításának vagy törlésének megakadályozásáról,</li>
                  <li>az adatkezelő rendszerek jogosulatlan személyek általi, adatátviteli berendezés útján történő használatának megakadályozásáról,</li>
                  <li>arról, hogy az adatkezelő rendszer használatára jogosult személyek kizárólag a hozzáférési engedélyben meghatározott személyes adatokhoz férjenek hozzá,</li>
                  <li>arról, hogy ellenőrizhető és megállapítható legyen, hogy a személyes adatokat adatátviteli berendezés útján mely címzettnek továbbították vagy továbbíthatják, illetve bocsátották vagy bocsáthatják rendelkezésére</li>
                  <li>arról, hogy utólag ellenőrizhető és megállapítható legyen, hogy mely személyes adatokat, mely időpontban, ki vitt be az adatkezelő rendszerbe</li>
                  <li>a személyes adatoknak azok továbbítása során vagy az adathordozó szállítása közben történő jogosulatlan megismerésének, másolásának, módosításának vagy törlésének megakadályozásáról</li>
                  <li>arról, hogy üzemzavar esetén az adatkezelő rendszer helyreállítható legyen.</li>
                  <li>arról, hogy az adatkezelő rendszer működőképes legyen, a működése során fellépő hibákról jelentés készüljön, továbbá a tárolt személyes adatokat a rendszer hibás működtetésével sem lehessen megváltoztatni.</li>
                </ul>
              </Typography>
              <Typography variant='h6' className={classes.subtitle} >Milyen jogai vannak az érintettnek?</Typography>
              <Typography marginBottom='30px'>
                Adatkezelő felhívja az érintettek figyelmét, hogy joggyakorlásuknak lehetnek feltételei, valamint korlátai egy-egy adatkezeléssel kapcsolatban, amely tényezőket az Adatkezelő vizsgálni köteles az érintettek joggyakorlása esetén. Abban az esetben, ha egy jogával az érintett adott adatkezelés kapcsán nem élhet, úgy a joggyakorlást kizáró/korlátozó ténybeli és/vagy jogi indokokról az Adatkezelő írásban (ide értve az elektronikus utat is) tájékoztatja az érintettet és arról nyilvántartást vezet.
              </Typography>

              <Typography variant='h6' className={classes.subtitle}>Hozzáféréshez való jog (GDPR 15. cikk) </Typography>
              <Typography marginBottom='20px'>
                Az érintett jogosult arra, hogy az Adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és az adatkezelés körülményeivel kapcsolatos információkhoz hozzáférést kapjon.
              </Typography>

              <Typography variant='h6' className={classes.subtitle}>Helyesbítéshez való jog (GDPR 16. cikk)</Typography>
              <Typography marginBottom='20px'>
                Az érintett jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat.
              </Typography>


              <Typography variant='h6' className={classes.subtitle}>Tiltakozáshoz való jog (GDPR 21. cikk)</Typography>
              <Typography marginBottom='10px'>
                Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak a GDPR 6. cikk (1) bek. e) vagy f) pontján alapuló kezelése ellen.
              </Typography>
              <Typography marginBottom='20px'>
                Ebben az esetben az Adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha bizonyítja, hogy az adatkezelést olyan jogos okok indokolják, amelyek elsőbbséget élveznek az érintett érdekeivel, jogaival és szabadságaival szemben.
              </Typography>


              <Typography variant='h6' className={classes.subtitle}>Adatkezelés korlátozásához való jog (GDPR 18. cikk)</Typography>
              <Typography marginBottom='10px'>
                Az érintett jogosult arra, hogy kérésére az Adatkezelő korlátozza az adatkezelést, ha a GDPR-ban meghatározott feltételek bármelyike megvalósul, és ebben az esetben az Adatkezelő a tároláson kívül más műveletet az adattal ne végezzen.
              </Typography>
              <Typography marginBottom='20px'>
                Ha az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival szemben.
              </Typography>


              <Typography variant='h6' className={classes.subtitle}>Törléshez (elfeledtetéshez) való jog (GDPR 17. cikk)</Typography>
              <Typography marginBottom='20px'>
                Az érintett jogosult arra, hogy az Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, ha az adatkezelésnek nincs célja, egyéb jogalapja, tiltakozás esetén nincs elsőbbséget élvező jogszerű ok az adatkezelésre, vagy ha az adatokat eleve jogellenesen kezelték, továbbá az adatokat jogi kötelezettség teljesítéséhez törölni kell.
              </Typography>


              <Typography variant='h6' className={classes.subtitle}>Adathordozhatósághoz való jog (GDPR 20. cikk)</Typography>
              <Typography marginBottom='30px'>
                Az érintett jogosult arra, hogy a rá vonatkozó, általa az Adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa anélkül, hogy ezt akadályozná az az adatkezelő, amelynek a személyes adatokat a rendelkezésére bocsátotta, ha jogszabályi feltételek fennállnak.
              </Typography>


              <Typography variant='h6' className={classes.subtitle}>A hatóság elérhetősége panasz (GDPR 77. cikk) esetén:</Typography>
              <Typography marginBottom='30px'>
                Nemzeti Adatvédelmi és Információszabadság Hatóság
              </Typography>
              <Typography marginBottom='10px'>Cím: 1055 Budapest, Falk Miksa utca 9-11.</Typography>
              <Typography marginBottom='10px'>Telefon: +36 (1) 391-1400</Typography>
              <Typography marginBottom='10px'>Fax: +36 (1) 391-1410</Typography>
              <Typography marginBottom='10px'>www:<a rel="noreferrer" target="_blank" style={{ textDecoration: 'none', color: '#000' }} href='https://www.naih.hu' > https://www.naih.hu</a></Typography>
              <Typography marginBottom='50px'>e-mail: ugyfelszolgalat@naih.hu</Typography>
              <Typography>Lezárva: 2020. július hó 01. napján</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Typography marginBottom='10px'>Fodor Zsolt</Typography>
            <Typography marginBottom='10px'>AOC System Zrt.</Typography>
            <Typography marginBottom='10px'>vezérigazgató</Typography>
          </Grid>
        </Container>
      </Box>
    </Paper>
  )
}
