import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider, } from '@material-ui/core/styles';
import { Paper, Typography, Button, useMediaQuery } from '@material-ui/core';
import AOS from 'aos';
import GlobalStyles from './components/GlobalStyles';
import './App.css';
import theme from './theme'
import 'aos/dist/aos.css'
import Routes from './routes';
import Navigation from '../src/components/Navigation/Navigation'
import Footer from '../src/components/Footer/Footer'
import CssBaseline from '@material-ui/core/CssBaseline';
import ScrollToTop from "./scrollToTop";
import Cookie from './components/Cookie/Cookie';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  const matches = useMediaQuery('(min-width:900px)');
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out'
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <GlobalStyles /> */}
      <Paper>
        <Router>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <Navigation></Navigation>
          </ThemeProvider>
          <Routes />
          <Footer/>
          {matches &&
          <ScrollToTopButton/>
          }
          <Cookie />
        </Router>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
