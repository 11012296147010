 import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    lineHeight: '24px',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});


function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ''}
      width={params.colDef.computedWidth}
    />
  );
}


// const columns = [

//   { field: 'name', headerName: 'Név', width: 120},
//   { field: 'value', headerName: 'Érték', width: 300, renderCell: renderCellExpand},
//   { field: 'domain', headerName: 'Domain', width: 140},
//   { field: 'path', headerName: 'Path', width: 120 },
//   { field: 'expiryDate', headerName: 'Lejárat', width: 200 },
//   { field: 'size', headerName: 'Mérte', width: 130 },
// ];

// const rows = [
//   { id: 1, name: '1P_JAR', value: '	2019-12-3-8', domain: '.google.com', path: '/', expiryDate: '2020-01-02T08:29:22.000Z', size: '17' },
//   { id: 2, name: 'ANID', value: 'AHWqTUmO2bNaCNbKHZ2o5IHVF0XM2WgQM_LkhTPwCq74_1JaiH9rR_Jl7YainLzU', domain: '.google.com', path: '/', expiryDate: '2020-05-26T09:08:31.057Z', size: '68' },
//   { id: 3, name: 'APISID', value: 'X9g1xAzHev-vee0E/AyPue5qFnB8Cye4mi', domain: '.google.com', path: '/', expiryDate: '2021-11-27T13:29:03.223Z', size: '40' },
//   { id: 4, name: 'CONSENT', value: 'YES+HU.hu+20150712-15-0	', domain: '.google.com', path: '/', expiryDate: '2038-01-10T08:00:00.025Z', size: '30' },
//   { id: 5, name: 'NID', value: '192=2BXggGk0ac5UAw5UI1PlwFMEq_EplW7L6Ac8cIi5Yc5Zb2hoMNi9lg_FCH5Okr6tI5ReMaRmXflyfUOLLjG80pHTPf_oPaLEfOL3ujzgDyGTBeSkoGBOBU8N2MTJyvYtcCbEW1topt2j9xdqgUUfA_57tuQ45RuVGXLqy2nfJk2FELQwGaMc6DsJ6UKDeHC5qAZpqN5NXpExNtRHcPVrenLUCBVqr7-6KrvYcmh-rTDE1_eNWwcb1cOYwKTNW7RDms5983yxHYCniUEA', domain: '.google.com', path: '/', expiryDate: '2020-06-03T07:48:25.911Z', size: '281' },
// ];

export default function DataTable(props) {
  const {height} = props
  return (
    <div style={{ height: height, backgroundColor: 'rgba(255, 255, 255, .95)', backdropFilter: 'blur(30px'}}>
      <DataGrid rows={props.children[0]} columns={props.children[1]}   pageSize={5} disableSelectionOnClick />
    </div>
  );
}