import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import BasicModal from '../Modals/BasicModal';
import CookieSettingsModal from './CookieSettingsModal';

function Cookie() {
  const { t, } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonStyle={{
        color: '#fff',
        background: '#07B1AB',
        padding: '10px 20px',
        borderRadius: 30
      }}
      buttonText={t('cookie.accept')}
      style={{
        backgroundColor: '#FFF',
        textAlign: 'left',
        color: 'black',
        boxShadow: '10px 12px 84px -3px rgba(0,0,0,0.24)',
      }}
      expires={365}

    >
      <Typography variant='subtitle2'>
        {/* {t('cookie.content', { settings: 'Beállítások' })} */}
        {t('cookie.content-01')}
        {/* <Link to='/privacy' style={{ textDecoration: 'underline', color: '#07B1AB' }}>
          {t('cookie.settings')}
        </Link> */}
        <span>
        <CookieSettingsModal/>
        </span>
        
        {t('cookie.content-02')}
      </Typography>
    </CookieConsent>
  )
}

export default Cookie;
