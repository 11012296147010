import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';

function NotFound() {
  return (
    <React.Fragment>
      <Paper>
        <Box
          sx={{
            minHeight: '100vh',
            py: 40
          }}
        >
          <Container maxWidth={false}>
            <Grid container display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
              <Typography variant="h1" color='default' fontWeight='bold' data-aos="zoom-out">404</Typography>
              <Typography variant="subtitle" color='secondary' data-aos="zoom-out" data-aos-delay="200">A keresett oldal nem található.</Typography>
            </Grid>
          </Container>
        </Box>
      </Paper>
    </ React.Fragment>
  )
}

export default NotFound;