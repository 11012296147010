import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Button, CardActionArea, CardActions, Box } from '@material-ui/core';
import CountUp from 'react-countup';
import { makeStyles } from '@material-ui/styles';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = makeStyles(theme => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100%',
    //border: '2px dashed red',
  },
}));

export default function Banner(props) {
  const classes = useStyles();


  return (
    <VisibilitySensor>
      {({ isVisible }) =>
        <Box
          {...props}
          height={props.height} className={classes.banner}
          sx={{
            background: props.background,
            height: props.bannerHeight,
            width: props.bannerWidth,
            //py: 20
          }}
        >
          {props.children}
        </Box>
      }
    </VisibilitySensor>
  );
}
