import * as React from 'react'
import Menu from 'material-ui-popup-state/HoverMenu'
import Button from '@material-ui/core/Button'
import { Link as MuiLink, } from '@material-ui/core'
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom';
import Logo from '../Logo'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  menu: {
    padding: '20px 0px',
    flexDirection: 'column',
  },
  mainMenu: {
    padding: '20px 0px',
    marginTop: 10,
    flexDirection: 'column',
  },
  menuItem: {
    borderRadius: 5,
    width: '100%'
  }
}));

const MenuDropdown = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  return (
    <React.Fragment>
      <MuiLink
        {...bindHover(popupState)}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...props}
        className={classes.root}
        style={{ color: '#5e5e5e', marginRight: 15 }}
      >
        <Typography variant='subtitle2'>
          {props.button}
        </Typography>
        <ArrowDropDownIcon
          // TODO: set scale according to theme settings
          // color='default'
          fontSize={'5px'}
          style={{ color: 'grey' }}
        />
      </MuiLink>
      <Menu
        disableScrollLock={true}
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        //className={classes.mainMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            //mt: 1.5,
            overflow: 'visible',
            borderRadius: 2,
            backgroundColor: '#FDFDFD',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '50%',
              width: 10,
              height: 10,
              bgcolor: '#FDFDFD',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Grid container className={classes.menu}>
          {props.items.map((item) => <Link key={item.index} to={item.disabled ? '#' : item.href} color="default" {...props}>
            <MenuItem className={classes.menuItem} onClick={popupState.close} disabled={item.disabled}>
              {item.logo && <Logo src={item.logo} marginRight={15} height={30} style={{ marginRight: 15 }} />}
              <Typography variant='subtitle2'> {item.text}</Typography>
            </MenuItem>
          </Link>
          )}
        </Grid>
      </Menu>
    </React.Fragment>
  )
}

export default MenuDropdown