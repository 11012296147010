import Element from '../components/Element/Element'
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import Pricing from '../components/Pricing/Pricing'

function Prices() {
  const prices = [
  {
    title: 'Egyéni',
    price: {
      monthly: '€ 22 / hó',
      yearly: '€ 20 / hó',
    },
    note: 'az előfizetői díj nettó ár, az ÁFÁt nem tartalmazza',
    description: [
      'Céleszköz, napi használat',
      'Egyszerű és hatékony',
      'Mobiliroda a zsebemben',
      'Professzionális munkavégzés',
      'Tökéletes folyamatok'
    ],
    buttonText: 'Vásárlás',
    buttonVariant: 'contained',
    href: "https://my.aocsystem.com/ac/registration",
    delay: '0',
    table: {
      title: 'Értékesítői nézet',
      descriptions: [
        'Tervezz és valósítsd meg céljaidat. Élvezd a rendszer nyújtotta számtalan funkciót. Egyszerű, gyors, könnyen kezelhető. Folyamatos sikerek és eredmények.',
      ],
      monthlyPlan: 'Havi előfizetési díj',
      notes: ["Az előfizetői díj felhasználónként nettó ár, az ÁFÁ-t nem tartalmazza. Éves előfizetés esetén 5% kedvezményt biztosítunk."],
      columns: [
        { title: "", variant: "h6", align: "" },
        { title: "Funkciók", variant: "h5", align: "left" },
      ],
      rows: [
        {
          name: "Dashboard",
          details: [true]
        },
        {
          name: "Naptár",
          details: [true]
        },
        {
          name: "Feladatmenedzsment",
          details: [true]
        },
        {
          name: "Ügyfélnyilvántartás",
          details: [true]
        },
        {
          name: "Sales Activity Basic tárgyalási folyamat",
          details: [true]
        },
        {
          name: "Dokumentumtár",
          details: [true]
        },
        {
          name: "Statisztika",
          details: [true]
        },
        {
          name: "Valós idejű adatok",
          details: [true]
        },
        {
          name: "Értesítési központ",
          details: [true]
        },
        {
          name: "Szoftverfelhasználói támogatás",
          details: [true]
        },
      ]
    }
  },
  {
    title: 'Csoport',
    //subheader: 'Legnépszerűbb',
    price: {
      monthly: '€ 380 / hó',
      yearly: '€ 350 / hó',
    },
    note: 'az előfizetői díj nettó ár, az ÁFÁt nem tartalmazza',
    description: [
      'Tökéletes csapatmunka',
      '20 felhasználó',
      'Tervezz és fejlessz',
      'Fókuszban az aktivitás',
      'Azonnali visszacsatolás'
    ],
    buttonText: 'Érdekel',
    buttonVariant: 'contained',
    href: "https://my.aocsystem.com/ac/company?type=company",
    delay: '150',
    table: {
      title: 'Csoport',
      descriptions: [
        'A rendszer egy teljes körű, felhő alapú szoftvermegoldás, amely elősegíti a hatékony és professzionális értékesítői munkát.',
        'A szoftver alkalmas kis vállalati környezetben dolgozók irányítására, menedzselésére, ösztönzésére, fejlesztésére, követi és támogatja az összes alkalmazott és külső értekesítő teljes munkafolyamatát. A vezető számára valós idejű statisztikákat és üzleti kimutatásokat készít, automatikusan a teljes csoportra vagy egyénre bontva egyaránt. Támogatunk, hogy egekbe repítsd az eladási mutatókat.',
        'Támogatunk, hogy egekbe repítsd az eladási mutatókat.'
      ],
      monthlyPlan: 'Havi előfizetési díj',
      notes: [
        "Az előfizetői csomagdíj nettó ár, az ÁFÁ-t nem tartalmazza.",
        "* Éves díjfizetés esetén a havi előfizetési díj csak € 360 / hó, továbbá ajándékba adunk egy a cégedre szabott egyedi tárgyalási folyamatot. Az alapot mi adjuk, tetszőlegesen kiegészítheted további lépésekkel és tartalommal.",
        "Az előfizetői csomag 20 fő nevesített felhasználót tartalmaz, igény esetén további + 20 fő felhasználóval bővíthető."
      ],
      columns: [
        { title: "Funkciók", variant: "h6", align: "left" },
        { title: "Felsővezetői nézet", variant: "h5", align: "right" },
        { title: "Csoportvezetői nézet", variant: "h5", align: "right" },
        { title: "Értékesítői nézet", variant: "h5", align: "right" },
        { title: "Call-Center nézet", variant: "h5", align: "right" },
      ],
      rows: [
        {
          name: "Felhasználók száma",
          tooltip: "Az előfizetésben foglalt felhasználói fiókok száma",
          details: ['1 fő', '3 fő', '15 fő', '1 fő']
        },
        {
          name: "További felhasználók",
          tooltip: "Hozzáadható további felhasználók száma",
          details: ['igény esetén további + 20 fővel bővíthető']
        },
        {
          name: "Dashboard",
          details: [true, true, true, true]
        },
        {
          name: "Naptár",
          details: [false, true, true, true]
        },
        {
          name: "Irodafoglalás",
          details: [false, true, true, true]
        },
        {
          name: "Feladatmenedzsment",
          details: [false, true, true, true]
        },
        {
          name: "Ügyfélnyilvántartás",
          details: [false, true, true, true]
        },
        {
          name: "Egyedi tárgyalási folyamat",
          details: ['1 db cégre szabott tárgyalási folyamat*']
        },
        {
          name: "További egyedi tárgyalási folyamatok",
          details: ['opcionális']
        },
        {
          name: "Dokumentumtár",
          details: [true, true, true, true]
        },
        {
          name: "Dokumentumfeltöltés",
          details: [true, true, true, true]
        },
        {
          name: "Export, import",
          details: [true, true, true, true]
        },
        {
          name: "Videótár",
          details: [true, true, true, true]
        },
        {
          name: "Értesítési központ",
          details: [true, true, true, true]
        },
        {
          name: "Értesítési-, üzenetküldés",
          details: [true, true, true, true]
        },
        {
          name: "Valós idejű adatok",
          details: [true, true, true, true]
        },
        {
          name: "Csoportkialakítás",
          details: ['tetszőleges virtuális struktura kialakítása']
        },
        {
          name: "Vállalati hierarchia",
          details: [true, true, true, true]
        },
        {
          name: "Jogosultsági rendszer",
          details: [true, true, true, true]
        },
        {
          name: "Egyéni statisztika",
          details: [true, true, true, true]
        },
        {
          name: "Strukturált statisztika",
          details: [true, true, true, 'hamarosan']
        },
        {
          name: "Egyéni tervezés",
          details: [true, true, true, 'hamarosan']
        },
        {
          name: "Strukturált tervezés",
          details: [true, true, true, 'hamarosan']
        },
        {
          name: "Asszisztensi nézet",
          details: ['hamarosan']
        },
        {
          name: "Külső felhasználók bekapcsolása",
          details: ['hamarosan']
        },
        {
          name: "Oktatás, képzés, támogatás",
          details: ['opcionális']
        },
        {
          name: "Rendszerintegráció",
          details: ['opcionális']
        },
        {
          name: "Egyedi idények, további fejlesztések",
          details: ['opcionális']
        },
      ]
    }
  },
  {
    title: 'Vállalat',
    titleColor: '#f5f5f7',
    backgroundColor: 'linear-gradient(360deg, rgba(255,255,255,0.8323704481792717) 0%, rgba(7,177,171,0.36738445378151263) 90%)',
    note: 'cégre szabott ajánlat nagyobb vállalatoknak, minimum 50 fő felett',
    price: {
      monthly: 'Egyedi megoldások',
      yearly: 'Egyedi megoldások',
    },
    description: [
      'Rendszerben való gondolkodás',
      'Tetszőleges struktúra',
      'Egyedi tárgyalási folyamatok',
      'Valós idejű adatok',
      'Hosszú távú ügyfélelégedettség'
    ],
    buttonText: 'Érdekel',
    buttonVariant: 'contained',
    href: "https://my.aocsystem.com/ac/company?type=enterprise",
    delay: '250',
    table: {
      title: 'Vállalat',
      descriptions: [
        'A rendszer egy teljes körű, felhő alapú szoftvermegoldás, amely elősegíti a hatékony és professzionális értékesítői munkát.',
        'A szoftver alkalmas kis vállalati környezetben dolgozók irányítására, menedzselésére, ösztönzésére, fejlesztésére, követi és támogatja az összes alkalmazott és külső értekesítő teljes munkafolyamatát. A vezető számára valós idejű statisztikákat és üzleti kimutatásokat készít, automatikusan a teljes csoportra vagy egyénre bontva egyaránt. \nA Vállalati csomag cégre szabott, egyedi megoldásokat kínál, min. 50 felhasználótól igényelhető.',
        'A Vállalati csomag cégre szabott, egyedi megoldásokat kínál, min. 50 felhasználótól igényelhető.'
      ],
      monthlyPlan: 'Havi előfizetési díj',
      notes: [
        'Az előfizetői csomagdíj nettó ár, az ÁFÁ-t nem tartalmazza.',
        '* Éves díjfizetés esetén a havi előfizetési díj csak € 360 / hó, továbbá ajándékba adunk egy a cégedre szabott egyedi tárgyalási folyamatot. Az alapot mi adjuk, tetszőlegesen kiegészítheted további lépésekkel és tartalommal.',
        'Az előfizetői csomag 20 fő nevesített felhasználót tartalmaz, igény esetén további + 20 fő felhasználóval bővíthető.'
      ],
      columns: [
        { title: "Funkciók", variant: "h6", align: "left" },
        { title: "Felsővezetői nézet", variant: "h5", align: "right" },
        { title: "Csoportvezetői nézet", variant: "h5", align: "right" },
        { title: "Értékesítői nézet", variant: "h5", align: "right" },
        { title: "Call-Center nézet", variant: "h5", align: "right" },
      ],
      rows: [
        {
          name: "Felhasználók száma",
          details: ['egyedi megoldás, tetszőleges struktúra kialakítása, min. 50 főtől']
        },
        {
          name: "További felhasználók",
          details: ['igény esetén, korlátlan felhasználóval bővítető']
        },
        {
          name: "Dashboard",
          details: [true, true, true, true]
        },
        {
          name: "Naptár",
          details: [false, true, true, true]
        },
        {
          name: "Irodafoglalás",
          details: [false, true, true, true]
        },
        {
          name: "Feladatmenedzsment",
          details: [false, true, true, true]
        },
        {
          name: "Ügyfélnyilvántartás",
          details: [false, true, true, true]
        },
        {
          name: "Egyedi tárgyalási folyamat",
          details: ['opcionális']
        },
        {
          name: "További egyedi tárgyalási folyamatok",
          details: ['opcionális']
        },
        {
          name: "Dokumentumtár",
          details: [true, true, true, true]
        },
        {
          name: "Dokumentumfeltöltés",
          details: [true, true, true, true]
        },
        {
          name: "Export, import",
          details: [true, true, true, true]
        },
        {
          name: "Videótár",
          details: [true, true, true, true]
        },
        {
          name: "Értesítési központ",
          details: [true, true, true, true]
        },
        {
          name: "Értesítési-, üzenetküldés",
          details: [true, true, true, true]
        },
        {
          name: "Valós idejű adatok",
          details: [true, true, true, true]
        },
        {
          name: "Csoportkialakítás",
          details: ['tetszőleges virtuális csoport kialakítása']
        },
        {
          name: "Vállalati hierarchia",
          details: ['tetszőleges virtuális hierarchia kialakítása']
        },
        {
          name: "Jogosultsági rendszer",
          details: [true, true, true, true]
        },
        {
          name: "Egyéni statisztika",
          details: [true, true, true, true]
        },
        {
          name: "Strukturált statisztika",
          details: [true, true, true, 'hamarosan']
        },
        {
          name: "Egyéni tervezés",
          details: [true, true, true, 'hamarosan']
        },
        {
          name: "Strukturált tervezés",
          details: [true, true, true, 'hamarosan']
        },
        {
          name: "Asszisztensi nézet",
          details: ['hamarosan']
        },
        {
          name: "Külső felhasználók bekapcsolása",
          details: ['hamarosan']
        },
        {
          name: "Oktatás, képzés, támogatás",
          details: ['opcionális']
        },
        {
          name: "Rendszerintegráció",
          details: ['opcionális']
        },
        {
          name: "Egyedi idények, további fejlesztések",
          details: ['opcionális']
        },
      ]
    }
  },
];

  return (

    <Paper>
      <Box
        sx={{
          //backgroundColor: 'background.default',
          background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
          minHeight: '100vh',
          py: 3,
        }}
      >
        <Grid
          container
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid
            item
            xl={12}
            lg={12}
            sm={12}
            xs={12}
            marginBottom={20}
          >
            <Pricing
              prices={prices}
              buttonColor='primary'
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            sm={2}
            xs={2}
          >
            <img
              alt={'barion-payment-logo'}
              src={'/barion-card-strip-intl_800px.png'}
              width={'100%'}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Prices;