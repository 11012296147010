import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import dlogo from "../../static/public/dlogo.png"

const Szechenyi2020 = () => {
  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"right"}
      >
        <Link
          to="/szechenyi2020"
        >
          <img src={dlogo} width={400}
            style={{
              imagerRendering: 'pixelated',
              filter: 'contrast(1.25)'
            }}
          >
          </img>
        </Link>
      </Grid>
      <Container>
        <>
          <Grid
            item
            minHeight="76vh" mb={10}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Pályázat címe:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                Gyakornokok foglalkoztatása a Dilato Finance Kft-nél
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Vissza nem térítendő támogatás összege:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                9 879 231 Ft
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Kedvezményezett neve:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                Dilato Finance Kft.
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Projektazonosító
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                GINOP-5.2.4-16-2017-02126
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              <Typography fontWeight="bold">
                Támogatási intenzitás:
              </Typography>
              <Typography ml={2}>
                100%
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography fontWeight="bold">
                Összefoglaló:
              </Typography>
              <Typography mt={2}>
                A projekt keretén belül 2 fő, 25 év alatti pályakezdő fiatal helyezkedett el a vállalkozásunknál, kikkel a 13,5 hónapos projektmegvalósítás
                során hatékony együttműködés alakult ki, oszlopos tagjaivá váltak a vállalkozásunknak.
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                A projekt befejezésének dátuma:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                2019.01.31.
              </Typography>
            </Box>
            <Grid margin={10}>
              <Divider />
            </Grid>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Pályázat címe:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                Gyakornokok foglalkoztatása a Dilato Finance Kft-nél
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Vissza nem térítendő támogatás összege:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                3 553 971 Ft
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Kedvezményezett neve:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                Dilato Finance Kft.
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                Projektazonosító
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                VEKOP-8-3-2-18-2018-0010
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              <Typography fontWeight="bold">
                Támogatási intenzitás:
              </Typography>
              <Typography ml={2}>
                80%
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography fontWeight="bold">
                Összefoglaló:
              </Typography>
              <Typography mt={2}>
                A projekt keretén belül 1 fő, 25 év alatti pályakezdő fiatal helyezkedett el a vállalkozásunknál, kikkel a 13,5 hónapos projektmegvalósítás
                során hatékony együttműködés alakult ki, oszlopos tagjává vált a vállalkozásunknak.
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'block',
                  xs: 'block',
                }
              }}
              mt={2}>
              <Typography fontWeight="bold">
                A projekt befejezésének dátuma:
              </Typography>
              <Typography
                sx={{
                  ml: {
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 0,
                    xs: 0,
                  }
                }}
              >
                2021.11.11.
              </Typography>
            </Box>
            <Grid
              mt={20}
            >
              <Link
                to="/"
              >
                <Button
                  variant='contained'
                  color="secondary"

                >
                  Vissza
                </Button>
              </Link>
            </Grid>
          </Grid>
        </>
      </Container>
    </>
  )
}

export default Szechenyi2020