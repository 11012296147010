import { Box, Container, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import Banner from '../../../components/Banner/Banner';

const useStyles = makeStyles({
  gradientTitle: {
    // background: "-webkit-linear-gradient(45deg, #56cac6 30%, #07B1AB 50%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent"
    color: 'white'
  },
})
function DataControll(props) {
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:900px)');
  const { title, description, content, color } = props.children
  return (
    <div>
      <Box>
        <Box style={{ height: '30vh' }} mb={10} display='flex' alignItems='center' backgroundColor={color}>
          <Container>
            <Typography textAlign={matches ? 'left' :'center'} variant="h2" fontWeight='bold' marginBottom={0} className={classes.gradientTitle}>{title}</Typography>
            {/* <Typography color='white' marginBottom={15}>{description}</Typography> */}
          </Container>
        </Box>
        <Container>
          <div style={{ marginBottom: '100px' }}>
            {content.map((data) => {
              return <div>
                <Typography variant="h5" color={color} fontWeight='bold' marginTop={5} marginBottom={2} >
                  {data.title}
                </Typography>
                {data.text.map((sentence) => {
                  return <Typography marginBottom={0.5}>
                    <li>{sentence}</li>
                  </Typography>
                })}
              </div>
            })}
          </div>
        </Container>
      </Box>
    </div>
  )
}

export default DataControll
