import { Hidden } from '@material-ui/core'
import HomeMobile from '../components/HomePageComponents/HomeMobile'
import React from 'react'
import HomeDesktop from '../components/HomePageComponents/HomeDesktop'
import ScrollToTopButton from '../components/ScrollToTopButton'

function Home() {
  return (
    <div>
      <Hidden mdDown>
        <HomeDesktop/>
      </Hidden>
      <Hidden mdUp>
        <HomeMobile/>
      </Hidden>
    </div>
  )
}

export default Home;
