import { AppBar, Button, Card, Divider, FormControlLabel, Grid, Modal, Paper, Switch, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core'
import { Box, fontWeight, palette } from '@material-ui/system'
import React from 'react'
import BasicTable from '../BasicTable/BasicTable';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/index'
import PolicyIcon from '@material-ui/icons/Policy';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InfoIcon from '@material-ui/icons/Info';
import json2mq from 'json2mq';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '95%',
  height: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid #f6f6f7',
  borderRadius: '7px',
  boxShadow: 27,
  p: 2,
};

const useStyles = makeStyles({
  tabPanel: {
    // overflow: 'scroll',

  },
  tab: {
    backgroundColor: '#f6f6f7',
    margin: '10px',
    borderRadius: '8px',
    // color: '#3cb9b8',
    color: theme.palette.secondary.default,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  text: {
    marginBottom: '20px',
  },
  button: {
    marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    borderRadius: 25,
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`
//   };
// }

function CookieSettings(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t, } = useTranslation();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [mandantoryChecked, setMandantoryChecked] = React.useState(true);
  const [statisticChecked, setstatisticChecked] = React.useState(true);
  const matches = useMediaQuery(
    json2mq({
      minWidth: 900,
      minHeight: 600
    })
    // '(min-width:900px)' || '(min-height:600px)'
  );

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: 10,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#3CB9B8',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const sMobileHight = useMediaQuery('(min-height:404px')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMandantorySwitchChange = (event) => {
    setMandantoryChecked(event.target.checked);
  };

  const handleStatisticSwitchChange = (event) => {
    setstatisticChecked(event.target.checked);
  };

  const handleAllCookieClick = () => {
    return setMandantoryChecked(true),
   setstatisticChecked(true),
   handleClose()
 }

  const handleSaveCookieClick = () => {
    handleClose()
  }

  const mandantoryCookiesColumns = [
    { title: 'Név', variant: 'h5', align: 'left' },
    { title: 'Érték', variant: 'h5', align: 'left' },
    { title: 'Domain', variant: 'h5', align: 'left' },
    { title: 'Path', variant: 'h5', align: 'left' },
    { title: 'Lejárat', variant: 'h5', align: 'left' },
    { title: 'Méret', variant: 'h5', align: 'left' },
  ]

  const mandantoryCookiesRows = [
    //TODO expired
    {
      name: '1P_JAR',
      details: ['2019-12-3-8', '.google.com', '/', '2020-01-02T08:29:22.000Z', '17']
    },
    //TODO expired
    {
      name: 'ANID',
      details: ['AHWqTUmO2bNaCNbKHZ2o5IHVF0XM2WgQM_LkhTPwCq74_1JaiH9rR_Jl7YainLzU', '.google.com', '/', '2020-05-26T09:08:31.057Z', '68']
    },
    {
      name: 'APISID',
      details: ['X9g1xAzHev-vee0E/AyPue5qFnB8Cye4mi', '.google.com', '/', '2021-11-27T13:29:03.223Z', '40']
    },
    {
      name: 'CONSENT',
      details: ['YES+HU.hu+20150712-15-0', '.google.com', '/', '2038-01-10T08:00:00.025Z', '30']
    },
    {
      name: 'HSID',
      details: ['Agqv50YZRA4wLQcAI', '.google.com', '/', '2021-11-27T13:29:03.223Z', '21']
    },
    // TODO expired
    {
      name: 'NID',
      details: ['192=2BXggGk0ac5UAw5UI1PlwFMEq_EplW7L6Ac8cIi5Yc5Zb2hoMNi9lg_FCH5Okr6tI5ReMaRmXflyfUOLLjG80pHTPf_\noPaLEfOL3ujzgDyGTBeSkoGBOBU8N2MTJyvYtcCbEW1topt2j9xdqgUUfA_\n57tuQ45RuVGXLqy2nfJk2FELQwGaMc6DsJ6UKDeHC5qAZpqN5NXpExNtRHcPVrenLUCBVqr7\n-6KrvYcmh-rTDE1_eNWwcb1cOYwKTNW7RDms5983yxHYCniUEA', '.google.com', '/', '2020-06-03T07:48:25.911Z', '281']
    },
    // TODO expired
    {
      name: 'OTZ',
      details: ['5190342_52_52_123900_48_436380', 'www.google.com', '/', '2019-12-14T09:41:56.000Z', '33']
    },
    {
      name: 'S',
      details: ['billing-ui-v3=7_a1A3bIDWtIPDiSLYVqFzExtAFfUZX0:billing-ui-v3-efe=7_a1A3bIDWtIPDiSLYVqFzExtAFfUZX0', '.google.com', '/', 'Session', '98']
    },
    {
      name: 'SAPISID',
      details: ['kPkOqOuvpD65YQ74/ASIGTIMzt1Aej70pQ', '.google.com', '/', '2021-11-27T13:29:03.223Z', '41']
    },
    // TODO expired
    {
      name: 'SEARCH_SAMESITE',
      details: ['CgQIqI4B', '.google.com', '/', '2020-01-02T08:29:22.000Z', '23']
    },
    {
      name: 'SID',
      details: ['qwf6rCP5UJGIZRJZt_V6cbW-3CPPj0phelOsA2bUD7DANAvXYbk3B07qlen7dY9kGz3sEw.', '.google.com', '/', '2021-11-27T13:49:49.897Z', '74']
    },
    // TODO expired
    {
      name: 'SIDCC',
      details: ['AN0-TYtf8JRolQto6ApuQRRmuBry_xUwZ1OjpcsU8_pG6SiMH0ebhPik4GBcX8ZNqQhmavJjKys', '.google.com', '/', '2020-03-02T08:40:04.869Z', '80']
    },
    {
      name: 'SSID',
      details: ['A1uviNKtyvK-tnzqo', '.google.com', '/', '2021-11-27T13:29:03.223Z', '21']
    },
    {
      name: '_ga',
      details: ['GA1.2.80211327.1570811062', '.aocsystem.com', '/', '2021-12-02T08:40:07.000Z', '28']
    },
    // TODO expired
    {
      name: '_gat_gtag_UA_149844297_1',
      details: ['1', '.aocsystem.com', '/', '2019-12-03T08:41:07.000Z', '25']
    },
    // TODO expired
    {
      name: '_gid',
      details: ['GA1.2.991404830.1575361390', '.aocsystem.com', '/', '2019-12-04T08:40:07.000Z', '30']
    },
    // TODO expired
    {
      name: 'moove_gdpr_popup',
      details: ['%7B%22strict%22%3A%221%22%2C%22thirdparty%22%3A%221%22%2C%22advanced%22%3A%221%22%7D', '.aocsystem.com', '/', '2020-09-11T08:28:38.000Z', '100']
    },
    // TODO expired
    {
      name: 'wfwaf-authcookie-f76c3dcf007ddab104bc4e4cca9cbe74',
      details: ['1%7Cadministrator%7C23daed88ca695876169765801c364237ed3fc82746df3b8f6c9520c8a8812772', '.aocsystem.com', '/', '2019-12-03T20:23:35.823Z', '133']
    },
  ]

  const statisticCookiesColumns = [
    { title: 'Név', variant: 'h5', align: 'left' },
    { title: 'Szolgáltató', variant: 'h5', align: 'left' },
    { title: 'Cél', variant: 'h5', align: 'left' },
    { title: 'Lejárat', variant: 'h5', align: 'left' },
    { title: 'Típus', variant: 'h5', align: 'left' },
  ]

  const statisticCookiesRows = [
    {
      name: 'Google Analytics',
      details: ['Google LLC', 'Látogatói felmérés', '24 hó', 'ID']
    }
  ]
  return (
    <span>
      <span style={{color: '#3cb9b8', textDecoration: 'underline', cursor:'pointer'}} onClick={handleOpen}>beállítások</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>


          <Grid
            container>
            <Grid
              item>
              <Box
                // direction={matches ? 'row' : 'column'}
                display={matches && 'flex'}
                sx={{
                  // flexGrow: 1,
                  bgcolor: "background.paper",
                  height: '80vh',
                  width: '80vw',
                }}>
                {/* <AppBar position='static' color='inherit' style={{ boxShadow: 'none' }}> */}
                <Grid
                  container
                  display='flex'
                  alignItems='center'
                  justifyContent='center'

                  // maxWidth={matches ? '20vw' : '120px'}
                  maxWidth={matches ? '250px' : null}

                  sx={matches &&
                    { borderRight: 1, borderColor: "divider", }
                  }

                >
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    mb='1px'
                    mr={matches && '20px'}
                  // width='300px'
                  // width='300px'
                  >
                    {/* 
          <Grid
            container> */}

                    <Tabs
                      textColor='secondary'
                      // display='flex'
                      // orientation='vertical'
                      orientation={matches && 'vertical'}
                      variant={!matches && 'scrollable'}
                      value={value}
                      onChange={handleChange}
                    // aria-label="Vertical tabs example"
                    >
                      <Tab className={classes.tab} icon={<PolicyIcon />} label={matches && "Cookie tájékoztató és nyilatkozat"} />
                      <Tab className={classes.tab} icon={<CheckCircleOutlineIcon />} label={matches && "Kötelező sütik"} />
                      <Tab className={classes.tab} icon={<EqualizerIcon />} label={matches && "Statisztikai sütik"} />
                      <Tab className={classes.tab} icon={<InfoIcon />} label={matches && "Cookie tájékoztató"} />
                    </Tabs>
                    {/* </Grid> */}
                  </Grid>
                </Grid>
                {/* </AppBar> */}
                <Grid
                  container
                  // flexDirection={matches ? 'row' : 'column'}
                  display='flex'
                  paddingRight={matches && '20px'}
                  paddingLeft={matches && '10px'}
                >
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ overflow: 'scroll', }}
                    // width={matches ? '55vw' : null}
                    height={matches ? '70vh' : '54vh'}
                    width={matches ? '61vw' : 'null'}
                  >
                    <TabPanel className={classes.tabPanel} value={value} index={0}>
                      <Typography variant='h5' fontWeight='bold' className={classes.text}>Cookie tájékoztató és nyilatkozat</Typography>
                      <Typography className={classes.text}>A felhasználói élmény fokozása, kényelmi és statisztikai célból weboldalunk adatokat (cookie-kat, sütiket) tárol a készülékén és webszervereken.</Typography>
                      <Typography className={classes.text}>Adatkezelő, az AOC System Zrt. (székhelye: 1062 Budapest, Délibáb u. 8., cégjegyzékszám: 01-10-048930, adószáma: 25723258-2-42, e-mail cím: <a href="mailto:office@aocsystem.com" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#07B1AB' }}>office@aocsystem.com</a>, adatvédelmi tisztviselő: Dr. Bölcskei Krisztián, <a href="mailto:dpo@aocsystem.com" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#07B1AB' }}>dpo@aocsystem.com</a>).</Typography>
                      <Typography className={classes.text}>Kérjük, az itt elérhető cookie nyilatkozatot tekintse át és jelölje be az Ön számára megfelelőket, köszönjük!</Typography>
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={1}>
                      <Grid container>
                        <Grid
                          item style={{ width: matches ? '58vw' : '73vw' }}
                        >
                          <Typography variant='h5' fontWeight='bold' className={classes.text}>Kötelező sütik</Typography>
                          <Typography className={classes.text}>Az weboldal működéséhez és a szolgáltatás zavartalan biztosításához  az oldal személyes adatokat tárol a böngészőjében. Az ebbe csoportba tartozó sütik használata elengedhetetlen az oldalon, ezeket csak időszakosan lehet törölni.</Typography>
                          <Typography> Ezzel a beállítással az alábbiak kerülnek engedélyezésre:</Typography>
                          <ul>
                            <li>Feladatok, tranzakciók folyamatának megjegyzése</li>
                            <li>Hirdetések megjelenítése az oldalon</li>
                          </ul>
                          <Typography className={classes.text}>Ezzel a beállítással NEM engedélyezi:</Typography>
                          <ul>
                            Feladatok, tranzakciók folyamatának megjegyzése
                            <li>Érdeklődési körének megfelelő hirdetések (remarketing) megjelenítése a weboldalon kívül (pl. közösségi oldalak)</li>
                            <li>Oldal-használat elemzését annak érdekében, hogy testreszabott tartalmat jelenítsünk meg a weboldalon.</li>
                          </ul>
                          <BasicTable rows={mandantoryCookiesRows} columns={mandantoryCookiesColumns} />
                          <FormControlLabel
                            style={{ marginTop: '20px' }}
                            label='Engedélyez'
                            control={
                              <IOSSwitch sx={{ m: 1 }}
                                checked={mandantoryChecked}
                                onChange={handleMandantorySwitchChange}
                                inputProps={{ 'aria-label': 'controlled' }} />
                            }
                          >
                          </FormControlLabel>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={2}>
                      <Grid
                        container>
                        <Grid
                          item style={{ width: matches ? '58vw' : '73vw' }}>
                          <Typography variant='h5' fontWeight='bold' className={classes.text}>Statisztikai sütik</Typography>
                          <Typography className={classes.text}>Fontos az Adatkezelő számára, hogy tudja, mi történik az oldalain és szolgáltatásaival,  felhasználói hogyan használják a szolgáltatásokat. Ebből a célból statisztikai adatokat, beleértve személyes adatokat gyűjt az Adatkezelő a weboldal használata során.</Typography>
                          <BasicTable rows={statisticCookiesRows} columns={statisticCookiesColumns} />
                          <FormControlLabel
                            style={{ marginTop: '20px', }}
                            label='Engedélyez'
                            control={
                              <IOSSwitch sx={{ m: 1 }}
                                checked={statisticChecked}
                                onChange={handleStatisticSwitchChange}
                                inputProps={{ 'aria-label': 'controlled' }} />
                            }
                          >
                          </FormControlLabel>
                          <Typography mt={5} className={classes.text}>Ezzel a beállítással engedélyezi:</Typography>
                          <ul>
                            <li>Statisztikai célú webanalitikai mérések</li>
                          </ul>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={3}>
                      <Typography variant='h5' fontWeight='bold' className={classes.text}>Cookie tájékoztatónak</Typography>
                      <Typography className={classes.text}>Bővebb információkat a <a href="#/privacy" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#07B1AB' }}>Cookie tájékoztatónkban</a> talál.</Typography>
                    </TabPanel>
                  </Grid>
                  <Grid
                    container
                    style={{ height: '10vh' }}
                    sx={
                      { borderTop: 1, borderColor: "divider", marginLeft: 1 }
                    }
                    alignItems='center'
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      display='flex'
                      justifyContent='space-between'
                      alignItems='cente'
                      px={1}
                      mt={sMobileHight ? '10px' : '0px'}
                    >
                      <Button onClick={handleAllCookieClick} style={{ padding: matches ? '10px 20px' : null, visibility: !mandantoryChecked || !statisticChecked ? 'visible' : 'hidden' }} className={classes.button} size='small' color='secondary' disableElevation variant='contained'>Összes Engedélyezése</Button>
                      <Button onClick={handleSaveCookieClick} style={{ padding: matches ? '10px 20px' : null, }} className={classes.button} size='small' color='secondary' disableElevation variant='contained'>Beállítások mentése</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </span>
  )
}

export default CookieSettings