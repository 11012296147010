import {
  Box,
  Button, Container,
  Grid,
  Divider,
  Paper, Switch, Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { styled } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/Banner/Banner';
import FullScreenDialog from '../../components/Modals/FullScreenDialog';
import NavSecondary from '../../components/Navigation/NavSecondary';
import PricingDialog from '../../components/Pricing/PricingDialog';
import theme from '../../theme/businessAndOfficeTheme';
import Tilt from 'react-parallax-tilt';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import dasboardNewWidht from '../../static/public/dashboard_width.png'
import invoiceWdith from '../../static/public/invoiceWidth2.png'
import invite from '../../static/public/mockups/invite.png'
import imacIpadCircle2 from '../../static/public/mockups/imac-ipad-circle-bno-reduce.png'
import navOnlineConnection from '../../static/public/mockups/nav.png'
import circle from '../../static/public/mockups/circle.png'
// import { Switch, Divider } from '@material-ui/core';


const useStyles = makeStyles({
  svgLine: {
    position: 'absolute',
    top: 470,
    // left: 500,
    left: '36%',
    width: '60%',
    height: '67%',
    // overflow: 'visible'
  },
  svgLineMd: {
    position: 'absolute',
    top: 430,
    // left: 500,
    left: '16%',
    width: '60%',
    height: '67%',
    // overflow: 'visible'
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  description: {
    variant: 'h6',
  },
  faintWord: {
    variant: 'h7',
    opacity: '0.1'
  },
  faintWordSmall: {
    component: 'h2'
  },
  faintWordBig: {
    component: 'h1'
  },
  button: {
    padding: '10px 20px',
    borderRadius: 30,
    backgroundColor: '#512da8',
    '&:hover': {
      backgroundColor: '#512da8'
    }
  },
  switch: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  gradientTitle: {
    background: '-webkit-linear-gradient(165deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  DeepPurple: {
    background: 'linear-gradient(90deg, rgba(159,42,210,1) 0%, rgba(67,21,89,1) 35%, rgba(133,21,181,1) 100%)',
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  fullScreenButton: {
    '&:hover': {
      transition: '2s',
      opacity: '0.4'
    }
  }
})

// const yearlyPrice = '8.500'
// const monthlyPrice = '9.990'
const yearlyPrice = '3.000'
const monthlyPrice = '3.500'

const prices =
{
  title: 'Business & Office',
  price: {
    monthly: monthlyPrice + ' Ft / hó',
    yearly: yearlyPrice + ' Ft / hó',
  },
  buttonText: 'Vásárlás',
  buttonVariant: 'contained',
  href: "https://my.aocsystem.com/ac/registration?product=BO",
  dialog: {
    title: 'Business & Office',
    descriptions: [
      'Minden, amit látnod kell, egy pillanat alatt elérhető! Reformáld meg a cégvezetéssel kapcsolatos papírmunkát, legyen gördülékeny és hatékony az információ áramlás. Munkád mostantól okosabbá válik, levesszük a felesleges terhet a vállaldról. Könnyen áttekintető, felhasználóbarát felületünk támogatja az eredményes munkádat.',
    ],
    monthlyPlan: 'Havi előfizetési díj',
    notes: ["Az előfizetői díj felhasználónként (1 db cég, 1 nevesített felhasználóval) nettó ár, az ÁFÁ-t nem tartalmazza. Éves előfizetés esetén 2 hónapot ajándékba adunk."],
    tables:
      [
        {
          title: ["Hivatalos Dokumentum"],
          columns: [
            { title: "", variant: "h6", align: "" },
            { title: "Funkciók", variant: "h5", align: "left" },
          ],
          rows: [
            {
              details: ['Dokumentumkezelés'],
              colSpan: 2,
              color: '#512DA8'
            },
            {
              name: "Céges papírok",
              tooltip: "Alapító okirat, alapszabály, aláírási címpéldány, meghatalmazás, mérleg, taggyűlési jegyzőkönyv, társasági szerződés...",
              details: [true]
            },
            {
              name: "Levelek",
              tooltip: "Ajánlott levél, értesítő, hivatalos irat, tértivevény, csomag...",
              details: [true]
            },
            {
              name: "Szerződések",
              tooltip: "Adásvételi-, alvállalkozói-, bérleti-, együttműködési-, megbízási-, munka-, vállalkozói szerződés...",
              details: [true]
            },
            {
              name: "Teljesítési igazolás",
              details: [true]
            },
            {
              name: "Jelenéti ívek",
              details: [true]
            },
            {
              name: "Szabadság nyilvántartás",
              details: [true]
            },
            {
              name: "Főkönyvi kivonat",
              details: [true]
            },
            {
              name: "Mérleg",
              details: [true]
            },
            {
              details: ['Könyvelés'],
              colSpan: 2,
              color: '#F81F24'
            },
            {
              name: "Számlák",
              tooltip: "Bejövő és kimenő számlák",
              details: [true]
            },
            {
              name: "Számlakivonatok",
              tooltip: "Bankszámla, értékpapírszámla, letét...",
              details: [true]
            },
            {
              name: "Adófizetési értesító",
              tooltip: "ÁFA, iparűzési adó, társasági adó...",
              details: [true]
            },
            {
              name: "Bérlista, bérjegyzék",
              details: [true]
            },
            {
              details: ['További funkciók'],
              colSpan: 2,
              color: '#FFAC3F'
            },
            {
              name: "Partnerkezelés",
              details: [true]
            },
            {
              name: "NAV online kapcsolat",
              details: [true]
            },
          ]
        },
      ],
  },
}

function BusinessAndOffice(props) {
  const { t, } = useTranslation();
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:900px)');
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const sMobile = useMediaQuery('(min-width:390px)');
  const mLaptop = useMediaQuery('(min-width:1024px)');
  const [monthly, setMonthly] = useState(true);


  document.title = t('Title.main') + " | " + t('Title.subTitle.businessOffice')

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#dbdbdb' : '#dbdbdb',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#dbdbdb' : '#dbdbdb',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <React.Fragment>
      <Paper>
        <Box
          className="mainClass"
          sx={{
            // background: 'linear-gradient(0deg, rgba(255,255,255,0.5413515748096114) 39%, rgba(251,173,127,0.5413515748096114) 66%, rgba(255,255,255,0.5525560566023284) 89%)',
            py: 10,
            overflow: 'hidden'
          }}
        >
          <NavSecondary
            title={t('products.businessAndOffice.title')}
            color='gray'
            buttonColor='primary'
            buyUrl="https://my.aocsystem.com/ac/registration?product=BO"
            anchors={
              [
                { text: t('navigation.home'), href: 'description', offset: -70 },
                { text: t('products.businessAndOffice.navigation.advantages'), href: 'advantages', },
                { text: t('products.businessAndOffice.navigation.prices'), href: 'prices', offset: -220 },
              ]
            }
          />
          <Container>
            <Grid
              container
              display='flex'
              justifyContent='space-between'
              alignItems='space-between'
              flexDirection={!lgUp && 'column'}
              spacing={3}
            >
              <Grid
                item
                marginTop={matches ? '70px' : '20px'}
                xl={7}
                lg={7}
                md={8}
                sm={12}
                xs={12}
              >
                <Typography
                  variant='h1'
                  // data-aos='fade-right'
                  fontSize={!sMobile && '2.2em'}
                  fontWeight='bold'
                  className={classes.gradientTitle}
                  textAlign='left'
                  // marginTop={matches ? '20px' : '20px'}
                  marginTop={'20px'}
                >
                  Stabil alapokra építve fejlődsz
                </Typography>
                <Typography variant='h6'
                  marginTop={5}
                >
                  Több, mint egyszerű dokumentumtárolás.
                </Typography>
                <Typography variant='h6'
                  marginTop={1}
                >
                  A rendszer támogat és segít átlátni a céged pénzügyi és könyvelési folyamatait!
                </Typography>
                <Button
                  className={classes.button}
                  style={{ marginTop: matches ? '45px' : '20px' }}
                  variant='contained'
                  href="https://my.aocsystem.com/ac/registration?product=BO"
                  target="_blank"
                >
                  {t('products.businessAndOffice.buy')}
                </Button>
              </Grid>
              <Grid
                item
                xl={5}
                lg={5}
                md={4}
                sm={12}
                xs={12}
                marginTop={lgUp ? 10 : 8}

                // display='flex'
                // justifyContent='flex-end'
                // alignItems='flex-end'
                style={{display: lgUp?  'block' :'flex' }}
                justifyContent={!lgUp && 'center'}
              // data-aos='fade-left'
              >
                <div>
                  <img
                    alt='ipad-ipadlg4'
                    src={dasboardNewWidht}
                    // width={matches ? '180%' : '100%'}
                    width={mLaptop ? '1200px' : '610px'}
                    style={{
                      width: matches ? '300%' : '150%' ,
                      transform: 'rotate(0deg)',
                      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                      borderRadius: '24px'
                    }}
                  >
                  </img>
                </div>
              </Grid>

              {/* <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                alignItems='flex-end'
                justifyContent='flex-end'
                data-aos='fade-left'
              >
                <Typography variant='h5'
                  marginTop={matches ? 2 : 4}
                >{t('products.businessAndOffice.subtitleDescription2')}
                </Typography>
              </Grid> */}
            </Grid>
          </Container>
            <Container>
            <Divider sx={{ mt: lgUp ? 20 : 10 ,mb: lgUp ? 20:10} } />
              <Grid
                container
                display='flex'
                justifyContent='space-between'
                alignItems='space-between'
                marginLeft={0}
                paddingLeft={0}
                flexDirection={!lgUp && 'column-reverse'}
                marginBottom={{
                  lg: 10,
                  md: 55,
                  xs: 15,
                }}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  data-aos='fade-right'
                >
                  <img
                    alt='invite'
                    style={{
                      transform: 'rotate(0deg)',
                      // boxShadow: '5px 1px 30px',
                      marginTop: lgUp ? '10px' : '50px',
                      marginLeft: lgUp ? '-50px' : '0px',
                      borderRadius: '1px',
                    }}
                    src={invite}
                    width={matches ? '100%' : '100%'}
                  >
                  </img>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  position='relative'

                >
                  <Typography marginTop='10px' variant='h2' color='#FFAC3F' fontWeight='bold' style={{ fontSize: lgUp && '50px' }} marginLeft={lgUp ? '-150px' : '0px'} textAlign={lgUp ? 'center' : 'left'}>
                    Kapcsolódjatok virtuálisan, dolgozzatok közösen
                  </Typography>
                  <Typography variant='h4' fontWeight='bold' marginTop='50px'>
                    Tedd még egyszerűbbé a könyvelőddel a kapcsolattartást!
                  </Typography>
                  <Typography variant='h6' marginTop='50px'>
                    Könyvelőd és Te is ugyanazt a platformot használjátok, nem kell többé egymásra várni, minden egy helyen van, bármikor, azonnal visszakereshető. Levesszük a felesleges terhet a válladról. Naprakész információk és valós idejű adatokkal megkönnyítjük a mindennapjaidat.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Container>
            <Divider sx={{ mt: lgUp ? 20 : 10 ,mb: lgUp ? 20:10} } />
              <Grid
                container
                display='flex'
                justifyContent='space-between'
                alignItems='space-between'
                marginTop={lgUp ? 30 : 0}
                flexDirection={!lgUp && 'column'}
                zIndex={0}
                marginBottom={{
                  lg: 10,
                  md: 55,
                  xs: 10,
                }}
              >
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={12}
                  sm={12}
                  xs={12}
                  
                  zIndex='auto'
                  marginTop={lgUp ? '100px' : '0px'}
                >
                  <Grid
                    item
                    position='relative'
                    zIndex={400}
                    marginLeft={lgUp ? '80px' : '0px'}
                    marginRight={lgUp && '-200px'}
                  >
                    <Typography marginTop='10px' variant='h2' color='#FFAC3F' fontWeight='bold' style={{ fontSize: '40px' }} >
                      Nav online adatkapcsolat
                    </Typography>
                    <Typography variant='h4' fontWeight='bold' marginTop='20px' >
                      Kösd össze fiókod a NAV online számlarendszerével!
                    </Typography>
                    <Typography variant='h6' marginTop='20px' style={{ zIndex: 100 }} >
                      Minden magyarországi bejövő és kimenő számla adatait azonnal lekérünk és megjelenítjük Neked. Dőlj hátra, és élvezd az automatizált funkciót!
                    </Typography>
                  </Grid>
                  <img
                    alt='invite'
                    style={{
                      transform: 'rotate(0deg)',
                      // boxShadow: '5px 1px 30px',
                      marginTop: lgUp ? '-90%' : '-40%',
                      marginLeft: lgUp ? ' 10px' : '30px',
                      borderRadius: '1px',
                      position: 'relative',
                      zIndex: 0,
                      visibility: !lgUp && 'hidden'
                    }}
                    src={circle}
                    width={lgUp ? '120%' : '60%'}
                  >
                  </img>
                </Grid>
                <Grid item
                  xl={7}
                  lg={7}
                  md={12}
                  sm={12}
                  xs={12}
                  zIndex={0}
                >
                  <img
                    alt='invite'
                    style={{
                      transform: 'rotate(0deg)',
                      // boxShadow: '5px 1px 30px',
                      marginTop: lgUp ? '10px' : '50px',
                      marginLeft: lgUp ? ' 10px' : '0px',
                      borderRadius: '1px',
                      zIndex: -30
                    }}
                    src={navOnlineConnection}
                    width={matches ? '100%' : '100%'}
                  >
                  </img>
                </Grid>
              </Grid>
            </Container>
            <Container>
            <Divider sx={{ mt: lgUp ? 20 : 10 ,mb: lgUp ? 20:10} } />
              <Grid
                container
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                marginTop={13}
                flexDirection={!lgUp && 'column-reverse'}
              >
                <Grid
                  xl={7}
                  lg={7}
                  md={12}
                  sm={12}
                  xs={12}
                  data-aos='fade-right'
                >
                  <img
                    alt='outgoingBill'
                    style={{
                      transform: 'rotate(0deg)',
                      // boxShadow: '5px 1px 30px',
                      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                      marginTop: lgUp ? '10px' : '50px',
                      marginLeft: lgUp ? '-50px' : '0px',
                      borderRadius: '24px'
                    }}
                    src={invoiceWdith}
                    width={matches ? '90%' : '100%'}
                  // width={matches ? '515px' : '100%'}
                  >
                  </img>
                </Grid>
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Typography marginTop='10px' variant='h2' color='#FFAC3F' fontWeight='bold' style={{ fontSize: '40px' }}>
                    Maximális kényelem, naprakész információ
                  </Typography>
                  <Typography variant='h4' fontWeight='bold' marginTop='50px'>
                    Automatikusan kitöltjük Neked a számla főbb adatait
                  </Typography>
                  <Typography variant='h6' marginTop='50px'>
                    Havi 20-30 órát takarítunk meg a könyvelési anyagok előkészítésében, 31%-a gyorsabb végrehajtás, 45%-os csökkentett idő az adminisztratív feladatokra
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          <Banner id='prices' minHeight='50vh' background='#512da8' marginTop={25} data-aos='fade-up'>
            <Container maxWidth='xl' className={classes.switch} >
              <Box>
                <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: sMobile ? 'left' : 'center', justifyContent: sMobile ? 'left' : 'center', }} ml='0px'>
                  <Typography style={{ whiteSpace: 'nowrap' }} color={'#f8f8f9'} fontWeight={!monthly && 'bold'} variant={matches ? 'h5' : 'h4'} marginRight={3}>{t('products.businessAndOffice.monthly')}</Typography>
                  <FormControlLabel
                    control={<IOSSwitch onChange={() => setMonthly(!monthly)} defaultChecked={monthly} />}
                    label=""
                  />
                  <Typography style={{ whiteSpace: 'nowrap' }} variant={matches ? 'h5' : 'h4'} color={'#f8f8f9'} fontWeight={monthly && 'bold'}>{t('products.businessAndOffice.yearly')}</Typography>
                </Grid>

                <Grid
                  container
                  sx={{
                    px: 7
                  }}
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='space-between'
                  marginTop='90px'

                >
                  <Grid
                    item
                  >
                    <Typography color='#f5f5f7' variant={sMobile ? 'h2' : 'h4'}  >{t('products.businessAndOffice.subscriptionPrice')}</Typography>
                  </Grid>
                  <Grid
                    item>
                    <Typography variant='h2' fontWeight='bold' color='#f5f5f7' style={{ whiteSpace: 'nowrap' }}>{monthly ? yearlyPrice : monthlyPrice} <span>{t('products.businessAndOffice.currency')}</span></Typography>
                  </Grid>

                </Grid>
                <Grid
                  item
                  marginTop='100px'
                  mr='0px'>
                  <FullScreenDialog
                    button={<Typography className={classes.fullScreenButton} variant={matches ? 'h5' : 'h4'} color='#f5f5f7' style={{ textDecoration: 'link' }} align="right">{t('products.businessAndOffice.forDetails')}<ArrowForwardRoundedIcon style={{ fontSize: '15px', marginLeft: '5px' }}></ArrowForwardRoundedIcon></Typography>}
                  >
                    <PricingDialog
                      details={prices}
                      plan={prices}
                      monthly={!monthly}>
                    </PricingDialog>
                  </FullScreenDialog>
                </Grid>
              </Box>
            </Container>
          </Banner>
          <Container>
            <Grid container>
              <Grid item xs={8} id='advantages' maxWidth='100%'>
                <Typography
                  variant='h2'
                  //color='#512DA8'
                  className={classes.DeepPurple}
                  fontWeight='bold'
                  mt={25}
                >
                  Növeld a hatékonyságot
                </Typography>
                <Typography variant='h6' marginTop='45px' marginLeft='10px'>
                  Képzeld el, hogy a munkanapjaidat elégedettséggel fejezed be. A rendszerrel nem csak időt takarítunk meg, hanem felgyorsítjuk a növekedést minden típusú vállalkozás számára. Ismerd meg a céged alapműködését.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              marginTop={matches ? 20 : -130}
              container>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                visibility={!matches && 'hidden'}
              >
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={10}
                  sm={7}
                  xs={12}
                  display='flex'
                  justifyContent='space-between'
                  mt={matches ? -7 : 10}
                  ml={matches ? 60 : 10}
                  mr={matches ? -80 : 0}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Grid
                      item
                      p={!matches && 5}
                    >
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >{t('products.businessAndOffice.faintWord1')}</Typography>
                    </Grid>
                  </Tilt>
                  <Tilt tiltReverse={true} >
                    <Grid
                      item
                    >
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >{t('products.businessAndOffice.faintWord2')}</Typography>
                    </Grid>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={6}
                  xs={6}
                  display='flex'
                  justifyContent='center'
                  marginTop={3}
                  marginRight={20}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Typography variant='h7' component='h1' style={{ opacity: '0.3' }} >{t('products.businessAndOffice.faintWord3')}</Typography>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={8}
                  sm={5}
                  xs={5}
                  display='flex'
                  justifyContent='space-between'
                  mr={matches ? -20 : 0}
                  ml={matches ? 20 : 0}
                  marginTop={4}
                  mb={2}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Typography marginLeft={5} variant='h7' component='h2' style={{ opacity: '0.3' }}
                      marginTop={matches ? 0 : 2} >{t('products.businessAndOffice.faintWord4')}</Typography>
                  </Tilt>
                  <Tilt tiltReverse={true} >
                    <Typography variant='h7' component='h1' style={{ opacity: '0.3' }}
                      marginLeft={matches ? 0 : 5}

                    >{t('products.businessAndOffice.faintWord5')}</Typography>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={10}
                  xs={12}
                  display='flex'
                  mt={3}
                  mr={10}
                  justifyContent='flex-end'
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >{t('products.businessAndOffice.faintWord6')}</Typography>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={10}
                  sm={8}
                  xs={12}
                  display='flex'
                  justifyContent='flex-end'
                  mt={10}
                  ml={4}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Grid
                      item
                      p={!matches && 5}
                    >
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >Céges papírok</Typography>
                    </Grid>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={5}
                  xs={5}
                  display='flex'
                  justifyContent='center'
                  marginTop={matches ? -6 : 0}
                  marginRight={matches ? 45 : 0}
                  ml={!matches && 2}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Typography variant='h7' component='h1' style={{ opacity: '0.3' }} >Szerződések</Typography>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={5}
                  xs={5}
                  display='flex'
                  justifyContent='center'
                  marginTop={6}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >Főkönyvi kivonat</Typography>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={10}
                  sm={5}
                  xs={5}
                  display='flex'
                  justifyContent='space-between'
                  mt={6}
                  ml={matches ? 20 : 0}
                  mr={matches ? -30 : 0}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Grid
                      item mt={5}>
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >Szabadság nyilvántartás</Typography>
                    </Grid>
                  </Tilt>
                  <Tilt tiltReverse={true} >
                    <Grid
                      item
                      mt={!matches && -5}
                    >
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >Teljesítési igazolás</Typography>
                    </Grid>
                  </Tilt>
                </Grid>
                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={10}
                  sm={5}
                  xs={5}
                  display='flex'
                  justifyContent='space-between'
                  mt={0}
                  ml={60}
                  mr={-40}
                  data-aos="fade-zoom-in"
                >
                  <Tilt tiltReverse={true} >
                    <Grid
                      item
                      ml={20}
                      mt={-4}
                    >
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >Mérleg</Typography>
                    </Grid>
                  </Tilt>
                  <Tilt tiltReverse={true} >
                    <Grid
                      item
                    >
                      <Typography variant='h7' component='h2' style={{ opacity: '0.3' }} >Partner kezelés</Typography>
                    </Grid>
                  </Tilt>
                </Grid>
              </Grid>
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                style={{ borderRadius: 100 / 2 }}
              >
                <img
                  style={{ borderRadius: 100 / 2 }}
                  alt='imac-ipad-circle2'
                  src={imacIpadCircle2}
                  width='120%'>
                </img>
              </Grid>
            </Grid>
            <Grid
              container
              marginBottom={10}>
              <Grid
                item
                xl={8}
                lg={8}
                md={7}
                sm={12}
                xs={12}
                textAlign={matches ? 'left' : 'center'}
                data-aos='fade-up'
              >
                <Typography variant='h2' fontSize={!matches && '2.2em'} color='#512da8' fontWeight='bold' marginTop={20}>
                  {/* {t('products.businessAndOffice.changeView')} */}
                  Innováció és fejlesztés
                </Typography>
                <Typography variant='h6' marginTop={2}>
                  {/* {t('products.businessAndOffice.changeViewDescription')} */}
                  Ügyfeleink elégedettségére folyamatosan bővítjük rendszerünket új és izgalmas funkciókkal, hogy a lehető legjobb megoldásokat biztosítsuk.
                </Typography>
              </Grid>
              {/* <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                display='flex'
                justifyContent='flex-end'
                alignItems='flex-end'
                marginTop={matches ? 0 : 4}>
                <Button className={classes.button} variant='contained' href="https://my.aocsystem.com/ac/registration?product=BO" target="_blank" >{t('products.businessAndOffice.buy')}</Button>
              </Grid> */}
            </Grid>


          </Container>

        </Box>
      </Paper>
    </React.Fragment >
  )
}

export default BusinessAndOffice;