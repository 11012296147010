import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import CareerFooter from './CareerFooter'
import DataControll from './DataControll'
import { useTranslation } from 'react-i18next';

function Backend() {
  const { t, } = useTranslation();

  document.title = t('Title.main') + " | " + t('Title.subTitle.backend')

  const data = {
    title: 'Backend fejlesztő',
    description: 'Cégünk új junior backend munkatárssal szeretné bővíteni a csapatát. Fejlessz velünk, alkossunk közösen!',
    color: '#3463AD',
    content:
      [
        {
          title: "Téged keresünk, ha:",
          text: ["Lelkes és csapatjátékos vagy", "Igényes vagy a munkádra", "Nyitott vagy új ismeretek befogadására", "Van belső motivációd a tanulásra és fejlődésre"]
        },
        {
          title: "Mihez kell értened?",
          text: ["JAVA, Spring-Boot, OOP", "Hibernate/JPA (MySql)", "REST API", "Json, XML", "Swagger, Docker", "Verziókezelő ismerete (Git)", "JUnit5, Flyway, Gradle"]
        },
        {
          title: "Mik azok az elvárások, amiknek meg kell felelned?",
          text: ["Releváns programozási ismeretek/tapasztalat", "Nagyfokú önállóság és precizitás", "Pontosság és megbízhatóság", "A munkához szükséges szintű angol nyelvismeret", "Agilis fejlesztési módszertan"]
        },
        {
          title: "Mit kínálunk?",
          text: ["Bejelentetett, teljes munkaidős állás, heti 40 óra", "Versenyképes fizetés, dinamikusan növekvő fizetési és juttatási csomag", "Figyelünk, hogy a munkádhoz minden eszközöd meglegyen", "Fiatalos, innovatív és célratörő munkakörnyezet", "Rugalmas munkavégzés irodában és otthonról", "Hazai és nemzetközi piacra szánt saját fejlesztésű SaaS szoftvereink folyamatos fejlesztésében való aktív részvétel", "Részvétel teljes implementációs folyamatokban a tervezéstől az élesítésig"]
        }
      ]
  }

  return (
    <div>
      <Box minHeight='90vh' marginTop='50px'>
        <DataControll>{data}</DataControll>
        <Container>
        <Typography textAlign='left' fontWeight='bold'>{t('company.career.backEnd.sendCV')}<span style={{color: data.color, fontSize:'1.5em' }}>{t('company.career.backEnd.sendCV1')}</span><span>{t('company.career.webDeveloper.sendCV2')}</span>, a <a href="mailto:contact@aocsystem.com" rel="noreferrer" target='_blank' style={{textDecoration:'none', color: data.color}}>contact@aocsystem.com</a> e-mail címre.</Typography>
        </Container>
        <CareerFooter>{data}</CareerFooter>
      </Box>
    </div>
  )
}

export default Backend
