import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, useMediaQuery, } from '@material-ui/core';
import targetEditModal from '../../../static/public/target-edit.png';
import screenStack from '../../../static/public/3d-screen-stack.png';
import targetCard from '../../../static/public/target-card.png';

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles(theme => ({
  parent0: {
    position: ' relative',
    //background: ' pink',
    ////width: 'auto',
    ////margin: ' 8vw 10vw',
    ////height: '1500px',
    //border: ' 5px dotted lime',
  },

  child0: {
    opacity: ' 0.8px',
    height: ' 150px',
    //background: 'lime',
    border: ' 3px dotted purple',
    width: ' 200px',
    position: ' absolute',
    top: ' 0',
  },

  child11: {
    left: 0,
  },
  child21: {
    left: 150
  },
  svgContainer: {
    width: '10em',
  },
  chalk: {
    fontFamily: 'Indie Flower',
  }
}));

export default function AnimatedElement(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1200px');
  const { t, } = useTranslation();
  const ref = useRef();
  let targetDevice = useRef(null)
  ScrollTrigger.saveStyles(targetDevice)

  return (
    <Grid container
    //style={{ border: '2px solid blue' }}
    >
      <div className={classes.parent0}>
        <h2></h2>
        <Grid container>
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={{
              // border: '1px solid blue',
              marginLeft: matches ? '-10%' : '0%',
            }}
          >
            <img
              alt={'3d-screen-stack'}
              src={screenStack}
              //width='100%'
              style={{
                width: matches ? '120%' : '100%',
              }}
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          // style={{ border: '1px solid blue' }}
          >
            {/* <Grid container display='flex' flexDirection='row' alignItems='flex-start' justifyContent='center' style={{ border: '2px dotted red', transform: 'rotate(5deg)' }}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={3}
                xs={4}
                style={{
                  border: '1px solid red',
                  zIndex: 10
                }}
              >
                <img
                  alt={'target-card'}
                  src={'/target-card.png'}
                  width='100%'
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{
                  border: '1px solid red',
                }}
              >
                <div
                  class="svg-container"
                  style={{
                    marginLeft: -100
                  }}
                >
                <svg
                //<svg pointerEvents='none' overflow='visible' height='auto' width='auto'>
                viewBox="0 0 300 500"
                  //overflow='visible'
                  >
                    <path class="path" fill="transparent" stroke='#ffac3f' stroke-width="4" d="M 49.793 65.353 C 49.793 65.353 251.556 38.901 223.029 235.477 C 194.502 432.053 -23.341 879.149 390.041 828.838" stroke-linecap="round" stroke-dashoffset="0px" stroke-dasharray="1017.72412109375px 1017.72412109375px" />
                  </svg>
                </div>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid
          container
          mt={{
            md: -20,
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={5}
            sm={4}
            xs={2}
          // style={{ border: '4px dashed green', }}
          >
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={8}
            xs={10}
          // style={{ border: '4px dashed blue' }}
          >
            <Grid container
              display='flex'
              flexDirection='row'
              alignItems='flex-start' justifyContent='flex-end'
              style={{
                // border: '2px dotted red',
                transform: 'rotate(-5deg)'
              }}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{
                  // border: '1px solid red',
                  zIndex: 10
                }}
              >
                <img
                  alt={'target-card'}
                  src={targetCard}
                  width='100%'
                />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{
                  // border: '1px solid red',
                }}
              >
                <div
                  class="svg-container"
                  style={{
                    marginLeft: -70
                  }}
                >
                  {/* <svg pointerEvents='none' overflow='visible' height='auto' width='auto'> */}
                  <Typography
                    textAlign='center'
                    className={classes.chalk}
                    color='primary'
                    variant='h5'
                    //style={{width: 200}}
                    ml={{
                      //sm: 10,
                      xs: 10
                    }}
                    mr={{
                      sm: -20,
                    }}
                    mb={{
                      sm: -5,
                      xs: -5
                    }}
                  >
                    Motiváld munkatársaid céljaik elérésében!
                  </Typography>
                  <svg
                    viewBox="0 0 300 500"
                  //overflow='visible'
                  >
                    <path
                      //class="path"
                      fill="transparent"
                      stroke='#ffac3f'
                      stroke-width="4"
                      d="M 49.793 65.353 C 49.793 65.353 251.556 38.901 223.029 235.477 C 194.502 432.053 -23.341 879.149 390.041 828.838"
                      stroke-linecap="round"
                      stroke-dashoffset="0px"
                      stroke-dasharray="1017.72412109375px 1017.72412109375px"
                    />
                  </svg>
                  {/* <svg className={classes.svgLine} overflow='visible' style={{ transform: 'scale(0.5)', marginTop: -375, marginLeft: -200, zIndex: 10 }} pointerEvents='none'>
                    <path class='path' fill="transparent" stroke='#ffac3f' stroke-width="4" d="M 202.906 -55.647 C 303.855 -111.593 357.919 -102.614 442.236 -91.233"></path>
                    <path class='path' fill="transparent" stroke='#ffac3f' stroke-width="4" d="M 236.041 -14.43"></path>
                    <path class='path' fill="transparent" stroke='#ffac3f' stroke-width="4" d="M 220.686 -16.855"></path>
                    <path class='path' fill="transparent" stroke='#ffac3f' stroke-width="4" d="M 235.868 -56.853 L 202.576 -55.317 L 217.922 -88.258"></path>
                  </svg> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container
        //marginTop={-25}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={4}
            xs={2}
          // style={{ border: '1px solid red' }}
          >
            {/* <Typography marginBottom={5} variant="h2" color='secondary' fontWeight='bold' data-aos="fade-up">
              Tervezz
            </Typography>
            <Typography variant="h4">
              Vegyél fel egyéni célokat és érd el őket minél hamarabb!
            </Typography> */}
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={8}
            xs={10}
            style={{
              // border: '1px solid red', 
              transform: 'rotate(5deg)'
            }}
            mt={{
              sm: -30,
              xs: -20
            }}
          >
            <img
              alt={'target-edit-modal'}
              src={targetEditModal}
              width='100%'
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            mt={{
              sm: -40,
              //xs: -20
            }}
          >
            <Typography marginBottom={5} variant="h2" color='secondary' fontWeight='bold' data-aos="fade-up">
              Tervezz
            </Typography>
            <Typography variant="h4" maxWidth={500}>
              Vegyél fel egyéni célokat és érd el őket minél hamarabb!
            </Typography>
          </Grid>
        </Grid>
        {/* <code>position: relative;</code>
        <div className={`${classes.child0} ${classes.child21}`}>
          <h2>child 2</h2>
          <code>position: absolute;</code>
          <code>top: 0;</code>
          <code>left: 150px;</code>
        </div> */}
      </div>
    </Grid>
  );
}
