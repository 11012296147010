import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Button, CardActionArea, CardActions } from '@material-ui/core';
import CountUp from 'react-countup';
import { makeStyles } from '@material-ui/styles';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = makeStyles(theme => ({
  card: {
    '&:hover': {
      transition: '0.2s'
    }
  }
}));

export default function Element(props) {
  const classes = useStyles();


  return (
    <VisibilitySensor>
      {({ isVisible }) =>
        <Card
          className={classes.card}
          style={{
            // backgroundColor: 'rgba(255, 255, 255, .5)',
            // backdropFilter: 'blur(5px)',
            width: '100%',
            borderRadius: 10,
            transition: '0.2s',
            //border: (isVisible ? '' : '4px solid blue')
          }}
          elevation={3}
        >
          <CardActionArea
            target="_blank" 
            href={props.url}
          >
            <Grid
              item
              sx={{ height: 100 }}
              style={{ background: props.background || '#3CB8B8' }}
              color='secondary'
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color='default'>
                {/* <CountUp end={props.title} delay={0} duration={2}>
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp> */}
                {props.title}
              </Typography>
              <Typography variant="body2" color='default'>
                {props.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="default" target="_blank" href={props.url}>
              {props.buttonText}
            </Button>
          </CardActions>
        </Card>
      }
    </VisibilitySensor>
  );
}
