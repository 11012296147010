import { Box, Container, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '80px',
    fontWeight: 'bold'
  },
  text: {
    textAlign:'justify',
    marginBottom: '30px',
    marginTop: '30px'
  }
}))

function TermsAndConditions() {
  const classes = useStyles()
  const { t, } = useTranslation();


  document.title = t('Title.main') + " | " + t('Title.subTitle.termsAndConditions')
  
  return (
    <React.Fragment>
      <Paper>
      <Box style={{ height: '30vh', marginBottom: '5vh' }} backgroundColor='#212c38' display='flex' alignItems='center'>
            <Container>
              <Typography fontWeight='bold' variant='h3' textAlign='left' style={{ color: 'white' }}>Általános Szerződési Feltételek</Typography>
            </Container>
          </Box>
        <Box
        sx={{
          // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
          // minHeight: '100vh',
          py: 10
        }}>
          <Container>
            <Grid
            container>
              <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
                <Typography className={classes.text}>A jelen Általános Szerződési Feltételek (továbbiakban: „ÁSZF”) az AOC System Zrt. (továbbiakban: „Szolgáltató” „AOC System”), mint az AOC szoftver(ek) (továbbiakban: „Szoftver”) értékesítője, valamint a Szoftver(eke)t felhasználó ügyfelek (továbbiakban: „Ügyfél” „Felhasználó”) jogait és kötelezettségeit tartalmazza.</Typography>
                <Typography className={classes.text}>A <a href='/' rel="noreferrer" target='_blank' style={{textDecoration:'none', color:'#000'}}>www.aocsystem.com</a> weboldalon való regisztrációval Ön elfogadja Általános Szerződési Feltételeinket, amely az Ön és az AOC System Zrt. között megkötött jogi megállapodásnak minősül.</Typography>
                <Typography className={classes.title}>I. A Szolgáltató cégadatai, elérhetőségei</Typography>
                <ul style={{listStyleType: 'none'}}>
                  <li>Cégnév: AOC System Zártkörűen Működő Részvénytársaság</li>
                  <li>Képviselő: Fodor Zsolt, vezérigazgató</li>
                  <li>Székhely: 1062 Budapest, Délibáb u. 8.</li>
                  <li>Nyilvántartó bíróság: Fővárosi Törvényszék Cégbírósága</li>
                  <li>Cégjegyzékszám: 01-10-048930</li>
                  <li>Adószám: 25723258-2-42</li>
                  <li>Bankszámlaszám HUF:    HU25 1210 0011 1026 0449 0000 0000</li>
                  <li>Bankszámlaszám EUR:    HU14 1210 0011 1053 8087 0000 0000</li>
                  <li style={{marginLeft: '144px'}}>BIC (SWIFT) kód:        GNBAHUHB</li>
                  <li>E-mail: <a href="mailto:contact@aocsystem.com" rel="noreferrer" target='_blank' style={{textDecoration:'none', color:'#000'}}>contact@aocsystem.com</a> </li>
                </ul>
                <Typography className={classes.title}>II. Általános rendelkezések</Typography>
                <Typography className={classes.text}>2.1. Jelen ÁSZF rendelkezései kiterjednek minden olyan jogviszonyra, amely a Szolgáltató által kínált Szoftverek, valamint az ahhoz kapcsolódó szolgáltatások Ügyfél által történő felhasználására és igénybevételére irányul. A Szoftverek és a Szoftverekhez kapcsolódó szolgáltatás csomagok (továbbiakban: „Szolgáltatások”) mindenkori listája és leírása a Szolgáltató https://www.aocsystem.com/ honlapján (továbbiakban: „Honlap”) érhető el.</Typography>
                <Typography textAlign='left'><span style={{fontWeight: 'bold', fontStyle: 'italic'}}>Ügyfél</span> jelen ÁSZF értelmében:</Typography>
                <Typography className={classes.text}><span style={{fontWeight: 'bold'}}>a) gazdálkodó szervezet</span> az a gazdasági társaság, az európai részvénytársaság, az egyesülés, az európai gazdasági egyesülés, az európai területi társulás, a szövetkezet, a lakásszövetkezet, az európai szövetkezet, a vízgazdálkodási társulat, az erdőbirtokossági társulat, a külföldi székhelyű vállalat magyarországi fióktelepe, az állami vállalat, az egyéb állami gazdálkodó szerv, az egyes jogi személyek vállalata, a közös vállalat, a végrehajtói iroda, a közjegyzői iroda, az ügyvédi iroda, a szabadalmi ügyvivői iroda, az önkéntes kölcsönös biztosító pénztár, a magánnyugdíjpénztár, az egyéni cég, továbbá az egyéni vállalkozó, emellett gazdálkodó tevékenységével összefüggő polgári jogi kapcsolataiban az állam, a helyi önkormányzat, a költségvetési szerv, jogszabály alapján a költségvetési szervek gazdálkodására vonatkozó szabályokat alkalmazó egyéb jogi személy, az egyesület, a köztestület, valamint az alapítvány; ( <span style={{fontWeight: 'bold',}}>évi CXXX.</span> törvény új Pp. 7.§ 6. pontja)</Typography>
                <Typography className={classes.text}><span style={{fontWeight: 'bold'}}>b) szakmája, önálló foglalkozása vagy üzleti tevékenysége körében eljáró természetes személy, ill. jogi személy vagy jogi személyiség nélküli szervezet,</span> aki nem minősül fogyasztónak ( <span style={{fontWeight: 'bold'}}>évi V. tv. új Ptk. 8:1.§ 3 pontja</span>)</Typography>
                <Typography className={classes.text}>2.2. Az AOC System Zrt. az ÁSZF módosításait a www.aocsystem.com weboldalon minden esetben közzéteszi, továbbá minden felhasználó értesítési központjában is elhelyezésre kerül. Az ÁSZF elfogadásával egyben az Ügyfél kötelezettséget vállal arra is, hogy a jelen ÁSZF változásait folyamatosan nyomon követi.</Typography>
                <Typography className={classes.text}>2.3. A Szolgáltató egyoldalúan módosíthatja a jelen Általános Szerződési Feltételeket, ha ezt szükségessé teszi(k) (i) az alkalmazandó jogszabályok, nem korlátozó jelleggel ideértve a jogszabály-módosításokat is; (ii) az alkalmazandó jogszabályon alapuló rendelet, utasítás, határozat; (iii) a Szolgáltatások fejlődése; (iv) műszaki okok; (v) üzemeltetési körülmények; vagy (vi) a felhasználó számára kedvező feltételváltozások.</Typography>
                <Typography className={classes.text}>2.4. Mielőtt a tervezett változtatás hatályba lépne, értesíteni fogjuk Ügyfeleinket és amennyiben az ÁSZF Ügyfélre nézve többletkötelezettséget tartalmaz lehetőséget adunk az Ügyfélnek a Szolgáltatások lemondására legalább 30 nappal a változás érvénybe lépése előtt. Azzal, hogy Ügyfél a változás hatálybalépése után tovább használja a Szolgáltatásokat, Ügyfél elfogadja az új feltételeket. Ha Ügyfél nem fogadja el az új feltételeket, akkor a jogviszonyt kifejezett nyilatkozattal írásban fel kell mondania, amely nyilatkozat eredeti példányát köteles a Szolgáltató részére postai úton megküldeni a Szolgáltató fent jelzett székhelyére.</Typography>
                <Typography className={classes.title}>III. A szolgáltatások</Typography>
                <Typography className={classes.text}>3.1. A rendszer egy teljes körű, felhő alapú szoftvermegoldás, amely a pénzügyi termékek és szolgáltatások értékesítői piacán magának az értékesítőnek, a tanácsadónak, különösen a pénzügyi értékesítők számára nyújt kiváló megoldást. Az AOC System nem csupán egy szoftver, hanem egy teljes körű megoldás. Elősegíti a hatékony és professzionális értékesítői munkát, automatizáltan teljes ügymeneteket kezel, használata egyszerű és gyors. A szoftver alkalmas kis-, közép-, és nagyvállalati környezetben dolgozók irányítására, menedzselésére, ösztönzésére, fejlesztésére, követi és támogatja az összes alkalmazott és külső értékesítő teljes munkafolyamatát. A vállalati hierarchia minden vezetője számára valós idejű statisztikákat és üzleti kimutatásokat készít, automatikusan a teljes cégre, a csoportra vagy egyénre bontva egyaránt. A szoftver zárt rendszeren és egyedi platformon fut, GDPR kompatibilis.</Typography>
                <Typography className={classes.text}>3.2. A Szolgáltató a részére biztosított kizárólagos felhasználási jogok keretében az általa karbantartott és támogatott szoftverek használatát értékesíti és nyújt információkat azok használatával kapcsolatban.</Typography>
                <Typography className={classes.text}>3.3. A szolgáltató a weblapon kívül hírlevelek segítségével, e-mail útján tanácsadást, háttér támogatást nyújt az értékesített szoftverekkel kapcsolatban.</Typography>
                <Typography className={classes.text}>3.4. A Szolgáltató fontos feladata, hogy az általa értékesített szoftverek naprakészek legyenek és mindenben megfeleljenek a hatályos jogszabályoknak és felhasználói igényeknek. Ennek érdekében a szoftverek rendszeresen frissülnek, melyek az Ügyfél aktivitása nélkül, magából a szoftverből elérhetőek.</Typography>
                <Typography className={classes.text}>3.5. Szolgáltató az Előfizetés időtartama alatt biztosítja az Ügyfélnek, hogy a Szoftver(ek) legfrissebb verzióját használja. Az esetleges szoftverfrissítés szükségességéről – a frissítést megelőző 3 nappal előtte – a Szolgáltató az értesítési központon keresztül tájékoztatja az előfizetéssel rendelkező Ügyfeleket.</Typography>
                <Typography className={classes.text}>3.6. A Szolgáltató fenntartja a jogot, hogy a Szoftverek és Szolgáltatások működését, tartalmát és funkcióit bármikor előzetes értesítés nélkül módosítsa, javítsa, kiegészítse, vagy egyes Szoftvereket és Szolgáltatásokat. A Szoftverek és Szolgáltatások kivezetéséről, illetve megszüntetéséről a Szolgáltató 60 nappal előre, az értesítési központon keresztül tájékoztatja az érintett Ügyfelet. A Szolgáltató fenntartja a jogot továbbá, hogy a Szoftverekhez kapcsolódóan, díjfizetés ellenében igénybe vehető funkciókat és szolgáltatáscsomagokat tegyen elérhetővé.</Typography>
                <Typography className={classes.title}>IV. Szolgáltatás igénybevétele, regisztráció</Typography>
                <Typography className={classes.text}>4.1. A Szolgáltató szolgáltatásainak igénybevételéhez az Ügyfél elismeri, hogy</Typography>
                <ol>
                  <li>elfogadja jelen ÁSZF-et;</li>
                  <li>18. életévét betöltötte és cselekvőképes személy;</li>
                  <li>a megrendeléshez megadja a kért adatokat/információkat</li>
                  <li>gazdálkodó szervezet esetén a gazdálkodó szervezet törvényes képviselője</li>
                  <li>szakmája, önálló foglalkozása vagy üzleti tevékenysége körében eljáró természetes személy, ill. jogi személy vagy jogi személyiség nélküli szervezet, így nem minősül fogyasztónak</li>
                </ol>
                <Typography className={classes.text}>4.2. Az Ügyfél nem veheti igénybe a Szolgáltató szoftvereit olyan vállalkozás támogatására, amely törvénysértő tevékenységet folytat, illetve amelyet Szolgáltató a tevékenységével összeegyeztethetetlennek tart.</Typography>
                <Typography className={classes.text}>4.3. A Szolgáltatás megköveteli fióknyitást, ezért az Ügyfélnek a regisztrációt be kell fejeznie az aktuális, teljes és pontos információkkal, a vonatkozó regisztrációs űrlap alapján. Emellett kötelező kiválasztani egy jelszót. A jelszó és a fiók titkosságának megőrzéséért az Ügyfél teljes felelősséggel tartozik, e körben a Szolgáltató a felelősségét kizárja.</Typography>
                <Typography className={classes.text}>4.4. Teljes mértékben Ügyfél felelős a fiókja alatt felmerült összes tevékenységért. Ügyfél vállalja, hogy haladéktalanul értesíti az AOC System-et fiókja jogosulatlan használatáról vagy a biztonság bármilyen más megsértéséről a support@aocsystem.com címen keresztül, melynek elmaradásából származó kárért Szolgáltatót felelősség nem terheli. Az AOC System nem vállal felelősséget semmilyen veszteségért, amelyet Ügyfél okozhat, ha valaki más használja a jelszavát vagy a fiókját, akár az Ügyfél tudta, akár anélkül történik a használat. Az Ügyfél felelősséggel tartozik a fiókját vagy jelszavát használó személy miatti károkért, veszteségekért.</Typography>
                <Typography className={classes.title}>V. A Szolgáltatások használata</Typography>
                <Typography className={classes.text}>5.1. AOC System-fiók. A Szolgáltatás eléréséhez AOC System-fiókra van szükség. Az Ügyfél AOC System-fiókja lehetővé teszi, hogy Ügyfél a AOC System által biztosított szoftvert használja.</Typography>
                <Typography className={classes.text}>5.2. Fiók létrehozása. Online regisztrációval AOC System-fiókot hozhat létre. Ügyfél vállalja, hogy AOC System-fiókjának regisztrációjakor nem használ hamis, pontatlan vagy félrevezető információt. Ha Ügyfél egy jogi személy nevében hoz létre AOC System-fiókot, például vállalata vagy munkáltatója nevében, akkor Ügyfél kijelenti, hogy jogosult az adott jogi személy nevében kötelező érvénnyel elfogadni a jelen Feltételeket. Ügyfél az AOC System-fiókjának hitelesítő adatait nem ruházhatja át más felhasználóra vagy jogi személyre. Ügyfél a fiókja védelme érdekében titokban tartja fiókjának adatait és jelszavát. Ügyfél felelős az AOC System-fiókja segítségével végrehajtott összes tevékenységért.</Typography>
                <Typography className={classes.text}>5.3. Fiókhasználat: Ha Szolgáltató alappal feltételezi, hogy fennáll a veszélye annak, hogy az Ügyfél AOC System-fiókját csalárd módon egy harmadik fél használja (például a fiók biztonsága megsérülésének eredményeképp), akkor az AOC System felfüggesztheti az Ügyfél fiókját, amíg Ügyfél újra nem igényli annak használatát. A biztonsági sérülés jellege alapján szükségessé válhat, hogy letiltsuk a hozzáférést az Ügyfél Tartalmának egészéhez vagy egyes részeihez.</Typography>
                <Typography className={classes.text}>5.4. Az Ügyfél fiókjának bezárása. Az Ügyfél bármikor és bármilyen okból lemondhat konkrét Szolgáltatásokat, vagy bezárhatja AOC System-fiókját. Az előfizetés lejárta előtti 14., 7. és 1. napon a Szolgáltató figyelmezteti az Ügyfelet, hogy előfizetése le fog járni, igény esetén újítsa meg, ill. gondoskodjon a tartalom exportálásáról. A Szolgáltató az előfizetés lezárásakor automatikusan lezárja az Ügyfél fiókját. Minden lezárás esetén a Szolgáltató a fiókot 7 napra felfüggesztett állapotúvá teszi arra az esetre, ha az Ügyfél meggondolná magát ill. újabb előfizetést aktivál. A 7. nap letelte után Szolgáltató bezárja az Ügyfél AOC System-fiókját, kivéve, ha a 60 nap alatt az Ügyfél újra bejelentkezik, új előfizetést aktivál, utóbbi esetben az Ügyfél AOC System-fiókja is újraaktiválásra kerül.</Typography>
                <Typography className={classes.text}>5.5. Eljárási rend fiók bezárásakor. Az AOC System-fiók bezárásával megszűnik Ügyfélnek az a joga, hogy az AOC System által nyújtott szolgáltatásokat elérje. A felfüggesztett állapot 7. napját követően Ügyfél AOC System-fiókjához társított Adatokat, illetve az Ügyfél AOC System-fiókjához társított Tartalmát a Szolgáltató törli, ennek eredményeképp előfordulhat, hogy Ügyfél többé nem tudja elérni az AOC System-fiókot ezért felhívjuk az ügyfél figyelmét, hogy a rendszeres biztonsági másolatok készítése ügyfél felelőssége és feladata. Ez alól kivétel, ha a 60 nap alatt az Ügyfél újra bejelentkezik, új előfizetést aktivál, utóbbi esetben az Ügyfél AOC System-fiókja is újra aktiválásra kerül</Typography>
                <Typography className={classes.text}>5.6. További készülékek/díjcsomagok. A Szolgáltatás használatához internetkapcsolatra és/vagy mobiltelefonos adatkapcsolatra/díjcsomagra van szükség. Ügyfél a felelős a Szolgáltatások használatához szükséges összes kapcsolat, díjcsomag és/vagy készülék biztosításáért, valamint a kapcsolatokért, a díjcsomagokért és a készülékekért a szolgáltató(k) által felszámított díjak megfizetéséért. Ezek a díjak a Szolgáltatásokért részünkre fizetett bármely díjon felül fizetendők, az ilyen díjakat AOC System nem téríti meg Ügyfélnek. Az Ügyfélre vonatkozó ilyen esetleges díjakról érdeklődjön a szolgáltatójánál (szolgáltatóinál).</Typography>
                <Typography className={classes.text}>5.7. Szolgáltatási értesítések. Ha valamit AOC Systemnek közölnie kell Ügyféllel az Ügyfél által használt Szolgáltatással kapcsolatban, akkor a jogszabályok által számunkra előírt Szolgáltatási értesítést és tájékoztatót küldi Ügyfélnek. Ha Ügyfél az AOC System-fiókjához kapcsolódóan megadta az e-mail-címét vagy a telefonszámát, akkor a Szolgáltatási értesítéseket e-mailben vagy SMS-ben is elküldheti ki Szolgáltató Ügyfélnek. Szolgáltató javasolja, hogy Ügyfél figyelje és tartsa karban a megadott e-mail-címét. Ha Ügyfél nem járul hozzá az elektronikus értesítések fogadásához, fel kell hagynia a Szolgáltatások használatával.</Typography>
                <Typography className={classes.title}>VI. Az Ügyfél Tartalma</Typography>
                <Typography className={classes.text}>6.1. Az Ügyfél Tartalma: A szolgáltatás lehetővé teszi, hogy Ügyfél különböző tartalmakat tároljon vagy azokat megossza, vagy hogy Ügyfél másoktól anyagokat fogadjon. Az AOC System nem tart igényt az Ügyfél Tartalmának tulajdonjogára. Az Ügyfél Tartalma az Ügyfél Tartalma marad, és Ügyfél kizárólagosan felelős érte.</Typography>
                <Typography className={classes.text}>6.2. Amikor Ügyfél megosztja az Ügyfél Tartalmát másokkal, akkor Ügyfél kifejezetten hozzájárul ahhoz, hogy mindazok, akikkel Ügyfél megosztotta az Ügyfél Tartalmát, ingyenesen, a világon bárhol használhassák, menthessék, rögzíthessék, többszörözhessék, sugározhassák, továbbíthassák megoszthassák, megjeleníthessék, kommunikálhassák. Amennyiben Ügyfél nem kívánja, hogy másoknak lehetőségük nyíljon a fentiekre, ne használja a Szolgáltatásokat az Ügyfél Tartalmának megosztására.</Typography>
                <Typography className={classes.text}>6.3. Ügyfél kijelenti és szavatolja, hogy a jelen Feltételek időtartamára rendelkezik (és rendelkezni fog) mindazokkal a jogokkal, amelyek birtokában Ügyfél az Ügyfél Tartalmát feltöltheti vagy megoszthatja a Szolgáltatásokon, vagy azokon keresztül, valamint, hogy az Ügyfél Tartalmának a Szolgáltatásokon keresztül történő gyűjtése, használata és megőrzése nem sért semmilyen törvényt, és nem sérti mások jogait. Nyomatékosan javasoljuk, hogy rendszeres időközönként készítsen biztonsági másolatot az Ügyfél Tartalmáról. Az AOC System semmilyen jogcímen nem tehető felelőssé az Ügyfél Tartalmáért és azokért az anyagokért, amelyeket mások a Szolgáltatásaink felhasználásával feltöltenek, tárolnak vagy megosztanak. E Körben AOC System teljeskörűen kizárja felelősségét.</Typography>
                <Typography className={classes.text}>6.4. Érvényesítés. Szolgáltató fenntartja az Ügyfél Tartalma elutasításának jogát, amennyiben az meghaladja a tárterületre vagy a fájlméretre a Szolgáltatás által megengedett 5 GB korlátot. Továbbá amennyiben Ügyfél megsérti a jelen feltételekben felsorolt bármelyik kötelezettségét, vagy más módon lényegesen megsérti a jelen Feltételeket, lépéseket tehet a Szolgáltató az Ügyfél ellen, nem korlátozó jelleggel ideértve a Szolgáltatások nyújtásának leállítását vagy az Ügyfél AOC System-fiókjának azonnali bezárását rendkívüli felmondással, vagy a Szolgáltatásból vagy a Szolgáltatásba irányuló kommunikációjának blokkolását is. Szolgáltató fenntartja a jogot arra is, hogy az Ügyfél Tartalmát bármikor blokkolja vagy eltávolítsa a Szolgáltatásokból, ha tudomására jut, hogy az megsértheti az alkalmazandó jogszabályokat vagy a jelen Feltételeket. Az AOC System fenntartja annak jogot, hogy a jelen Feltételek lehetséges megsértésének kivizsgálása során a probléma megoldásának érdekében áttekintse az Ügyfél Tartalmát. AOC System a Szolgáltatások teljes mértékű felügyeletét nem biztosítja, és ezt meg sem kíséreli.</Typography>
                <Typography className={classes.title}>VII. Tárterület-foglalás</Typography>
                <Typography className={classes.text}>7.1. A Szolgáltató fenntartja a jogot arra, hogy amennyiben az Ügyfél több tartalmat tárol AOC System-fiókjában, mint amennyit az előfizetése megenged, és Ügyfél az értesítéstől számított 15 napon (vagy az értesítésben meghatározott bármely, ennél hosszabb időtartamon) belül nem reagál a Szolgáltatónak arra az értesítésére, amelyben Ügyfelet a fiók rendbetételére (az engedélyezett mennyiséget meghaladó tartalom eltávolítására vagy egy nagyobb tárhelyet biztosító, új előfizetés-csomagra történő áttérésre) szólítja fel, a Szolgáltató lezárhassa az Ügyfél fiókját, és törölheti az abban található Tartalmát, vagy letilthatja a hozzáférést az Ügyfél Tartalmához.</Typography>
                <Typography className={classes.text}>Az Ügyfél a Beállítások/Előfizetésem menüpont alatti diagramm segítségével folyamatosan nyomon követheti tárhelyének foglaltságát.</Typography>
                <Typography className={classes.text}>7.2. A szolgáltatás teljesítménye. Különféle tényezők, például az Ügyfél berendezése, az internetkapcsolat, vagy például a szerverkapcsolatban, és/vagy annak meghibásodása esetén miatt esetenként előfordulhat késés, a tartalom az AOC System fiókba történő feltöltésben vagy a tartalom szinkronizálásában. Erre vonatkozó felelősségét Szolgáltató teljeskörűen kizárja.</Typography>
                <Typography className={classes.title}>VIII. A kommunikációs szolgáltatások igénybevétele</Typography>
                <Typography className={classes.text}>8.1. A Szolgáltatások tartalmazhatnak csevegő területeket, hírcsoportokat, fórumokat, közösségeket, naptárakat, fényképalbumokat, dossziékat és / vagy más üzenet- vagy kommunikációs eszközöket, amelyek lehetővé teszik a másokkal való kommunikációt (továbbiakban: „Kommunikációs szolgáltatás”). Ügyfél kötelezettséget vállal arra, hogy a Kommunikációs szolgáltatásokat csak a megfelelő és adott esetben az adott kommunikációs szolgáltatással kapcsolatos üzenetek és anyagok küldésére, ill. fogadására használja.</Typography>
                <Typography className={classes.text}>8.2. Ügyfél kötelezi magát, hogy a kommunikációs szolgáltatások használatakor különösen, de nem kizárólagosan az alábbi magatartási szabályokat betartja:</Typography>
                <ul>
                  <li>nem használja a Kommunikációs szolgáltatásokat felmérésekkel, versenyekkel, piramis sémákkal, láncolatokkal, levélszeméttel, spammel vagy bármilyen másolatú vagy kéretlen (kereskedelmi vagy egyéb) üzenetekkel kapcsolatban</li>
                  <li>mások törvényes jogait (például a magánélethez és a nyilvánossághoz fűződő jogokat), nem sérti, rágalmazást, visszaélést, zaklatást, bántalmazást, fenyegetést vagy egyéb módon való sérelmet nem valósít meg</li>
                  <li>tilos bármilyen nem megfelelő, ostoba, rágalmazó, obszcén, tisztességtelen vagy jogellenes téma, név, anyag vagy információ közzététele, feltöltése, vagy terjesztése</li>
                  <li>az ehhez szükséges összes hozzájárulás nélkül nem tölt fel, illetve más módon sem tesz elérhetővé olyan fájlokat, amelyek képeket, fényképeket, szoftvereket vagy egyéb anyagokat tartalmaznak és amelyek szellemi tulajdon tárgyát képezik</li>
                  <li>tilos bármely áru vagy szolgáltatás bármilyen üzleti célú hirdetése vagy eladása</li>
                  <li>nem tölt le olyan a fájlokat, amelyeket egy kommunikációs szolgáltatás másik felhasználója feladott, és amelyekről tudja, vagy észszerűen tudnia kell, hogy ezeket nem lehet törvényesen lemásolni, megjeleníteni, végrehajtani és / vagy terjeszteni.</li>
                </ul>
                <Typography className={classes.text}>8.3. Az AOC System nem köteles ellenőrizni a kommunikációs szolgáltatásokat. Az AOC System azonban fenntartja a jogot, hogy a Kommunikációs Szolgáltatásokba beküldött anyagokat felülvizsgálja, és bármilyen anyagot saját belátása szerint eltávolítson. Az AOC System fenntartja a jogot, hogy bármilyen okból bármikor, értesítés nélkül, bármilyen módon megszüntesse a kommunikációs szolgáltatásokhoz való hozzáférést.</Typography>
                <Typography className={classes.text}>8.4. Az AOC System fenntartja a jogot bármilyen információ nyilvánosságra hozatalára, amennyiben az alkalmazandó törvények, rendeletek, jogi eljárások vagy hatósági kérelmek teljesítéséhez, vagy bármilyen információ vagy anyag teljes vagy részleges szerkesztéséhez, elküldéséhez vagy megsemmisítéséhez szükséges.</Typography>
                <Typography className={classes.text}>8.5. Ügyfél köteles mindig körültekintően eljárni, ha bármilyen kommunikációs szolgáltatás útján magával vagy gyermekeivel, hozzátartozó személyesen azonosítható információkat közöl, vagy oszt meg. Az AOC System nem ellenőrzi és nem támogatja a kommunikációs szolgáltatásokban található tartalmat, üzeneteket vagy információkat, ezért az AOC System kifejezetten felelősséget nem vállal a kommunikációs szolgáltatásokkal és a kommunikációs szolgáltatásokban való részvételéből eredő bármilyen tevékenységgel kapcsolatban.</Typography>
                <Typography className={classes.title}>IX. Tilos, jogellenes vagy tiltott felhasználás, viselkedési szabályok</Typography>
                <Typography className={classes.text}>9.1. A Szolgáltatások használatának feltételeként Ügyfél kötelezettséget vállal arra, hogy a Szolgáltatásokat nem használja olyan célra, amely jogellenes, törvénysértő vagy tiltott a jelen feltételek, és szolgáltató egyéb közleményei által.</Typography>
                <Typography className={classes.text}>9.2. A Szolgáltatást TILOS olyan módon használni, amely megrongálhatja, letilthatja, túlterhelheti vagy károsíthatja az AOC System szervert vagy az AOC System szerverhez csatlakoztatott hálózatot, vagy akadályozhatja a Szolgáltatások bármelyik fél általi használatát és élvezetét. TILOS megkísérelni jogosulatlan hozzáférést szerezni az AOC System szerveréhez vagy a Szolgáltatásokhoz kapcsolódó bármely Szolgáltatáshoz, más fiókhoz, számítógépes rendszerhez vagy hálózathoz, hackerezéssel, jelszó-bányászattal vagy bármilyen más módszerrel. TILOS bármely anyag vagy információ, beszerzése vagy megszerzése olyan módon, amelyet szándékosan nem tett elérhetővé a Szolgáltatásokon keresztül az Ügyfél.</Typography>
                <Typography className={classes.text}>9.3. A jelen Feltételeket megsértő Tartalom, anyag feltöltése vagy művelet nem engedélyezett. Azzal, hogy Ügyfél elfogadja a jelen Feltételeket, Ügyfél kötelezettséget vállal a következő szabályok betartására:</Typography>
                <ul>
                  <li>Nem tesz semmi törvénybe ütközőt.</li>
                  <li>Nem vesz részt semmilyen olyan tevékenységben, amely gyermekek kihasználására, bántalmazására vagy bántalmazással való fenyegetésére irányul.</li>
                  <li>Nem küld levélszemetet. Levélszemétnek minősülnek a nem kívánt vagy kéretlen tömeges e-mailek, bejegyzések, kapcsolatfelvételi kérések, SMS-üzenetek és csevegőüzenetek.</li>
                  <li>Nem jelenít meg nyilvánosan, illetve ne osszon meg a Szolgáltatások segítéségével semmiféle nem megfelelő tartalmat vagy egyéb anyagot (ideértve például a meztelenséget, az állatokkal való fajtalankodást, a pornográfiát, a durva beszédet, az erőszak grafikus megjelenítését és a bűncselekményt is).</li>
                  <li>Nem vesz részt csalárd, hamis vagy megtévesztő tevékenységben (például ne próbáljon meg pénz kérni hamis indokkal, ne adja ki magát más személynek, ne manipulálja a Szolgáltatásokat a megjegyzések befolyásolása érdekében).</li>
                  <li>Nem kerüli meg szándékosan a Szolgáltatások elérésére és rendelkezésre állására vonatkozó semmilyen korlátozást.</li>
                  <li>Nem vesz részt Ügyfélre, a Szolgáltatásokra vagy más személyekre nézve káros tevékenységben (például: vírusok terjesztése, zaklatás és megfigyelés, terrorista vagy erőszakos szélsőséges tartalom közzététele, gyűlöletbeszéd, mások elleni erőszakra történő buzdítás).</li>
                  <li>Nem sérti meg mások jogait (például: szerzői jogi védelem alá eső anyag jogosulatlan megosztása, továbbértékesítése vagy egyéb módon történő terjesztése, felhasználása).</li>
                  <li>Nem vesz részt olyan tevékenységben, amely megsérti mások adatainak bizalmasságát vagy védelmét.</li>
                  <li>Nem segít másoknak a jelen szabályok megsértésében.</li>
                </ul>
                <Typography className={classes.title}>X. Felhasználási feltételek, szerzői jogok</Typography>
                <Typography className={classes.text}>10.1. A Szoftverek a szerzői jog, a nemzetközi szerzői jogi egyezmények, valamint egyéb, a szellemi alkotásokra vonatkozó jogszabályok védelme alatt állnak. Az AOC System alapjául szolgáló know-how a Szellemi Tulajdon Nemzeti Hivatalának önkéntes műnyilvántartásában Y2000281 szám alatt került bejegyzésre. Az Ügyfél a Szoftverekre való előfizetéssel az előfizetés határozott idejében jogosult a szoftver használatára, azonban az Ügyfél a használaton felül semmilyen más felhasználási jogot nem szerez, és nem szerzi meg a Szoftverek, illetve a know-how tulajdonjogát sem. A Szoftverek vonatkozásában a jelen ÁSZF alapján kifejezetten át nem engedett jogokat a Szolgáltató fenntartja.</Typography>
                <Typography className={classes.text}>10.2. A szoftver és a hozzá tartozó dokumentáció, legyen akár forráskódban, akár tárgykódban, vagy bármilyen más formában rögzítve, szerzői jogi védelem alatt áll.</Typography>
                <Typography className={classes.text}>10.3. Jelen ÁSZF elfogadásával az Ügyfél tudomásul veszi, hogy a Szoftverek felépítése, a Szoftverekben található valamennyi szöveges és grafikai tartalom, valamint a Szoftverek működésének alapjául szolgáló valamennyi egyéb informatikai megoldás vonatkozásában a Szolgáltató kizárólagos szerzői jogokkal rendelkezik.</Typography>
                <Typography className={classes.text}>10.4. Az Ügyfél a Szolgáltató által forgalmazott Szoftverek esetén kizárólag üzleti célokra történő belső használatra jogosult.</Typography>
                <Typography className={classes.text}>10.5. Jelen ÁSZF értelmében az Ügyfél harmadik személyre át nem ruházható, nem kizárólagos jogot kap arra, hogy a Szoftvereket használja. Az Ügyfél a Szoftverek felhasználására harmadik személynek további engedélyt nem adhat, nem jogosult azt harmadik személy részére sem ingyenesen, sem bármilyen természetű ellenszolgáltatás fejében, bérbe vagy haszonbérbe adni, azt bármilyen módon harmadik személynek átadni, harmadik személy számára elérhetővé tenni, illetőleg a saját harmadik személlyel szemben fennálló kötelezettségének biztosítékaként vagy garanciájaként felhasználni. Az Ügyfél nem jogosult a Szoftvereket másolni, adathordozón többszörözni, elemezni, visszafejteni, megváltoztatni vagy más módon átalakítani.</Typography>
                <Typography className={classes.title}>XI. Általános fizetési feltételek</Typography>
                <Typography className={classes.text}>11.1. Előfizetés létrejötte. Amikor Ügyfél AOC System szolgáltatást vásárol, akkor az Ügyfél előfizetésére/vásárlására a jelen fizetési feltételek alkalmazandók, és jelen ÁSZF elfogadásával Ügyfél elfogadja ezen feltételeket.</Typography>
                <Typography className={classes.text}>Ügyfél tudomásul veszi, hogy előfizetés akkor jön létre, ha Ügyfél regisztrál az AOC System rendszerében, elfogadja jelen ÁSZF, az Adatvédelmi szabályzat és tájékoztató és az Adatfeldolgozási megállapodás az AOC System használatához elnevezésű szerződések rendelkezéseit, valamint kifizeti a kiválasztott előfizetés szerinti díjat.</Typography>
                <Typography className={classes.text}>Előfizetési díjakat és az előfizetési időszakokat a Szolgáltató a honlapján teszi közzé és a regisztráció során teszi választhatóvá. A tévedésből hibásan feltüntetett díjakért felelősséget nem vállalunk.</Typography>
                <Typography className={classes.text}>11.2. Díj megfizetés. Díjköteles Szolgáltatás esetén Ügyfél vállalja az adott díj megfizetését bankkártyás fizetéssel vagy banki átutalással. Eltérő rendelkezés hiányában a Szolgáltatások ára tartalmazza az összes felmerülő költséget – különösen, de nem kizárólag a mindenkor érvényes és hatályos tranzakciós illetéket, bankkártyás fizetési költséget, átutalási díjat, ÁFÁ-t.</Typography>
                <Typography className={classes.text}>A díj forintban (HUF) vagy euro (EUR) összegben fizetendő. Az euro összeg a felek egyedi megállapodása értelmében MNB középárfolyam szerint kerül forint (HUF) összegre konvertálva és fizethető. Jelen ASZF 11.3. pontja, illetve a Felek egyedi megállapodása és a díjbekérő/számla is tartalmazza a Szolgáltató megfelelő (EUR vagy HUF) számlaszámát.
                 {/* A díj euro (EUR) összegben fizetendő, mely a felek egyedi megállapodása értelmében MNB középárfolyam szerint kerül forint (HUF) összegre konvertálva is fizethető. jelen ASZF 11.3. pontja, illetve a Felek egyedi megállapodása és a díjbekérő/számla is tartalmazza a Szolgáltató megfelelő (EUR vagy HUF) számlaszámát. */}
                 </Typography>
                <Typography className={classes.text}>Miután értesítést küld a Szolgáltató az Ügyfélnek arról, hogy nem kapta meg időben a teljes előfizetési összeget, felfüggesztheti vagy megszüntetheti a Szolgáltatásokat, ha az Ügyfél a felszólításban, illetve jelen ÁSZF feltételei szerint meghatározott időn belül nem fizeti meg a teljes előfizetési összeget. A Szolgáltatásoknak a fizetés nem teljesítése miatti felfüggesztése vagy megszüntetése azt eredményezheti, hogy az Ügyfél elveszíti a hozzáférését a fiókjához és annak tartalmához, és nem használhatja azokat.</Typography>
                <Typography className={classes.text}>11.3. Fizetési feltételek. A Szolgáltatás díjának kifizetéséhez az Ügyfélnek meg kell adnia a fizetési módot, amikor regisztrál az adott Szolgáltatásra. A fizetési mód lehet bankkártyás fizetés vagy banki átutalás.</Typography>
                <Typography className={classes.text}><span style={{fontWeight: 'bold', fontStyle: 'italic'}}>Online bankkártyás fizetés:</span></Typography>
                <Typography className={classes.text}>Ügyfél az oldalon történő regisztráció után – választása szerint – kártyás online fizetéssel tudja kiegyenlíteni a szolgáltatás ellenértékét, melyhez a fizetések a Barion rendszerén keresztül valósulnak meg. A fizetési szolgáltatást nyújtó Barion Payment Zrt. a Magyar Nemzeti Bank felügyelete alatt álló intézmény, engedélyszáma: H-EN-I-1064/2013.</Typography>
                <Typography className={classes.text}>További tájékoztató a Barion rendszeren keresztüli fizetésről:<a href='https://www.barion.com/hu/ugyfelszolgalat/hogyan-tudok-barionnal-fizetni-egy-webshopban/' rel="noreferrer" target='_blank' style={{textDecoration:'none', color:'#000'}}>https://www.barion.com/hu/<br/>ugyfelszolgalat/hogyan-tudok-barionnal-fizetni-egy-webshopban/</a> </Typography>
                <Typography className={classes.text}>A bankkártyaadatok a Szolgáltatóhoz nem jutnak el. A fizetés során beírt adatokat sem a weboldal, sem a Szolgáltató nem tárolja, azokat az adott fizetéskezelő cég (Barion) kezeli és a pénzügyi szolgáltatók eltérő adatvédelmi rendelkezései lépnek érvénybe ezen esetekben. <a href='https://www.barion.com/hu/altalanos-szerzodesi-feltetelek/' rel="noreferrer" target='_blank' style={{textDecoration:'none', color:'#000'}}>https://www.barion.com/hu/altalanos-szerzodesi-feltetelek/</a></Typography>
                <Typography className={classes.text}>A számlázás online történik, elektronikus számla kerül kiállításra a fizetési folyamat sikeres lezárását követően a törvényben előirtak szerinti határidőben, melyet a Számlázz.hu rendszere végez.</Typography>
                <Typography className={classes.text}><span style={{fontWeight: 'bold', fontStyle: 'italic'}}>Banki átutalással történő fizetés:</span></Typography>
                <Typography className={classes.text}>Ügyfél az oldalon történő regisztrációt követően – választása szerint – banki átutalással tudja kiegyenlíteni a szolgáltatás ellenértékét az alábbi bankszámlaszámokra:</Typography>
                <ul style={{listStyleType: 'none'}}>
                  <li>IBAN (HUF)                 HU25 1210 0011 1026 0449 0000 0000</li>
                  <li>IBAN (EUR)                 HU14 1210 0011 1053 8087 0000 0000</li>
                  <li>BIC (SWIFT) kód:       GNBAHUHB</li>
                </ul>
                <Typography className={classes.text}>Banki átutalással történő fizetés esetén a Szolgáltató elektronikus díjbekérőt küld ki Ügyfél részére, mely alapján az Ügyfél teljesíti a fizetést. A fizetés Szolgáltató számláján történő jóváírást követően, a törvényben előírtak szerinti határidőben, Szolgáltató elektronikus számlát állít ki és küldi meg Ügyfél e-mail címére és a szolgáltatott fiókjába.</Typography>
                <Typography className={classes.text}>Ügyfél ezenkívül tudomásul veszi, hogy a Szolgáltató számára engedélyeznie kell a bankja vagy a megfelelő fizetési hálózat által biztosított, kiválasztott fizetési móddal kapcsolatosan frissített számlázási adatainak használatát. Ügyfél vállalja, hogy azonnal, de legkésőbb 5 napon belül frissíti a fiókját és egyéb adatait, ideértve az e-mail-címét és a fizetési mód részleteit is, hogy Szolgáltató végrehajthassa a tranzakciót, és szükség esetén a tranzakciót illetően kapcsolatba tudjon lépni Ügyféllel. Számlázási adatainak módosítása nem érinti azokat a díjakat, amelyekkel a Szolgáltató még azt megelőzően terhelte meg az Ügyfél számlázási fiókját, hogy 5 napon belül meg tudta volna tenni a megfelelő intézkedéseket az Ügyfél számlázási adatainak módosítása nyomán.</Typography>
                <Typography className={classes.text}>11.4. Számlázás. Azzal, hogy a Szolgáltató számára az Ügyfél megad egy fizetési módot, kijelenti, hogy Szolgáltató jogosult a megadott fizetési mód használatára, és hogy a megadott összes fizetési adat valós és pontos; (ii) feljogosítja a Szolgáltatót arra, hogy a megadott fizetési mód használatával díjat számítson fel Ügyfélnek a Szolgáltatásokért, illetve az elérhető tartalmakért; továbbá (iii) feljogosítja a Szolgáltatót arra, hogy díjat számítson fel Ügyfélnek a Szolgáltatások minden olyan díjköteles funkciójáért, amelyre Ügyfél regisztrál, vagy amelyet igénybe vesz a jelen Feltételek érvényességi időtartama alatt.</Typography>
                <Typography className={classes.text}>A Szolgáltató – a megadott módon – (a) előre; (b) regisztrációt követően; (c) a vásárlás időpontjában; a vagy (d) előfizetési Szolgáltatások esetén rendszeres időközönként állíthat ki Ügyfélnek díjbekérőt, számlát és bankkártyás fizetés esetén terhelheti meg a bankszámláját az előfizetés során jóváhagyott összeggel. A kiszámlázott összeg nem haladhatja meg az Ügyfél által jóváhagyott összeget.</Typography>
                <Typography className={classes.text}>30 nappal előre értesítjük Ügyfelet az előfizetési Szolgáltatásokért ismétlődően fizetendő díjak változásáról, <span style={{fontWeight: 'bold'}}>árváltozás</span> esetén továbbá lehetőséget biztosítunk Ügyfélnek arra, hogy az árváltozás hatálybalépése előtt 15 nappal lemondhassa a Szolgáltatásokat. A Szolgáltató egyszerre több, a tárgyidőszakot megelőző számlázási időszakra vonatkozóan is kiszámlázhatja a korábban fel nem dolgozott összegeket.</Typography>
                <Typography className={classes.text}>A Szolgáltató a kiállított díjbekérőt/számlát az Ügyfél által megadott e-mail címre küldi meg és/vagy tölti fel az Ügyfél AOC System oldalára. Jelen ÁSZF elfogadásával Ügyfél kifejezetten hozzájárul ahhoz, hogy részére a Szolgáltató a számlát elektronikusan állítsa ki és továbbítsa, töltse fel.</Typography>
                <Typography className={classes.text}>11.5. Ismétlődő fizetések. Ön vállalja, hogy ha Ön előfizetéses alapon vásárolja meg a Szolgáltatásokat (például havi, negyedéves, féléves vagy éves fizetéssel), akkor ismétlődő fizetésre adhat felhatalmazást, és a Szolgáltató számára a fizetések az Ön által vállalt fizetési móddal és ismétlődő időszakokban történnek meg, egészen addig, amíg az adott Szolgáltatást Ön vagy a Szolgáltató meg nem szünteti. Ha le kívánja állítani a Szolgáltatások folytatólagos használata miatti terheléseket, akkor még a következő számlázási dátum előtt le kell mondania a Szolgáltatásokat. A Szolgáltatások lemondására vonatkozóan útmutatással fogjuk ellátni Önt. Azzal, hogy Ön ismétlődő fizetésre ad felhatalmazást, Ön feljogosítja egyúttal a fizetési szolgáltatót arra, hogy tárolja az Ön fizetési eszközének adatait és az ilyen fizetéseket elektronikus beszedéssel vagy átutalással teljesítse az Ön megjelölt bankszámlájáról, vagy hogy a megfelelő összeggel megterhelje az Ön megjelölt kártyaszámláját (hitelkártyás vagy hasonló fizetés esetén) (a továbbiakban együttesen „Elektronikus Fizetés”). Az előfizetési díjakat az adott előfizetési időszak előtt vonjuk/hívjuk le. A Szolgáltató, illetve annak szolgáltatói fenntartják annak jogát, hogy ha bármelyik fizetést visszautasítják, vagy ha bármelyik hitelkártyás vagy hasonló tranzakciót visszautasítanak vagy elutasítanak, akkor a Szolgáltató vagy annak szolgáltatói beszedhessék a megfelelő visszautasítás, elutasítás vagy fedezethiány miatt felszámítandó díjat, és hogy az ilyen fizetést Elektronikus Fizetéssel dolgozhassák fel.</Typography>
                <Typography className={classes.text}>11.6. Elektronikus számlakivonat és hibák. A Szolgáltató elektronikus számlakivonatot bocsát az Ügyfél rendelkezésére, amely online kivonatát úgy tudja megnézni, hogy a www.aocsystem.com webhelyen bejelentkezik a fiókjába. Ez az egyetlen számlakivonat, amit a Szolgáltató ad. Ügyfél nyilatkozik, hogy az elektronikus számla tárolásának szabályairól tud, tisztában van a számla megőrzési, kezelési feltételeivel és e körben a felelősség őt terheli. Ügyfél kifejezetten tudomásul veszi, hogy előfizetése megszűnése esetén Szolgáltató a számláit nem tárolja, az a fiókjával együtt törlésre kerül, de amennyiben Ügyfélnek a számláira az előfizetése megszűnésétől számított 5 éven belül szüksége van, külön 1000,- Ft/számla díj ellenében Szolgáltatótól igényelheti.</Typography>
                <Typography className={classes.text}>11.7. Visszatérítési Szabályzat. Ügyfél tudomásul veszi, hogy amennyiben a Szolgáltatás teljesítése a számára teljes mértékben megtörtént, ezt követően vásárlása nem téríthető vissza. Amikor szoftver szolgáltatást vásárol a Szolgáltatótól, akkor a fizetés megkezdésének pillanatában megszűnik az Ügyfél lemondási joga. Minden vásárlás végleges és vissza nem téríthető.</Typography>
                <Typography className={classes.text}>11.8. A Szolgáltatások beszüntetése. A Szolgáltatásokat úgy tudja jövőre nézve lemondani, hogy az ügyfélszolgálathoz fordul. A díjköteles Szolgáltatások lemondása leállítja a Szolgáltatás folytatólagos használata miatti terheléseket. Szolgáltató kifejezetten felhívja a figyelmet, hogy lemondáskor Ügyfél nem kap visszatérítést; és ki kell fizetnie mindazt az összeget, amellyel a Szolgáltatásokra vonatkozó számlázási fiókját a lemondás napja előtt megterhelték; Ha Ügyfél lemondja a Szolgáltatásokat, akkor az Ügyfélnek nyújtott Szolgáltatások az aktuális Szolgáltatási időszak végén, illetve rendszeres időközönkénti számlázás esetén az aktuális előfizetési időszak végén szűnnek meg. Fentiek alól kivételt képez az az eset, amennyiben Ügyfél az előfizetési időszakban magasabb díjazású szolgáltatáscsomagot igényel; ebben az esetben a már megfizetett díj beszámításra kerül, az előfizetési időszakból hátralévő időre arányosan az Ügyfelet a szolgáltatások értékének különbözete terheli.</Typography>
                <Typography className={classes.text}>11.9. Promóciós ajánlatok. A Szolgáltató időnként próbaidőszakra Szolgáltatásokat kínálhat fel, és ezekért a Szolgáltatásokért ez alatt az időszak alatt nem kér díjat az Ügyféltől. A Szolgáltató fenntartja a jogot arra, hogy (a normál díjszabás alapján) díjat számítson fel az Ügyfélnek az ilyen Szolgáltatásokért, ha Ügyfél megsérti az ajánlat és jelen ÁSZF feltételeit és kikötéseit.</Typography>
                <Typography className={classes.text}>11.10. Árváltozás. Ha az Ügyfél által igénybe vett Szolgáltatáshoz rögzített időtartam és ár tartozik, akkor az az ár az adott időtartam végéig érvényben marad. Ha folytatni kívánja a Szolgáltatások használatát, el kell fogadnia az új ajánlatot és az új árat. Ha az Ügyfélnek nyújtott Szolgáltatások rendszeres előfizetési időszakokra tagolódnak (például havi előfizetés), de nem határozott időtartamra szólnak és nem próbaidőszakos ajánlathoz tartoznak, akkor Szolgáltató módosíthatja a Szolgáltatások árát, ha új vagy továbbfejlesztett funkciókkal egészíti ki a Szolgáltatást, amennyiben ennek megfelelően nőttek a Szolgáltatás nyújtásának költségei is, vagy a piaci változásokat követve (például a foglalkoztatási költségek emelkedése, az árfolyamváltozás, az adózási szabályok/előírások változásai, az infláció, a licencdíjak vagy az infrastrukturális és adminisztratív költségek emelkedése miatt). Az árváltozás hatálybalépése előtt legalább 30 nappal tájékoztatja Szolgáltató az Ügyfelet A fenti szakasznak megfelelően az árváltozások előtt 15 nappal Ügyfélnek lehetősége lesz a Szolgáltatások lemondására. Amikor az árváltozásról értesítjük, akkor arról is tájékoztatni fogjuk, hogy ha nem mondja le a Szolgáltatásokat, akkor az új ár lép hatályba, emlékeztetni fogjuk továbbá arra is, hogy miként tudja lemondani a Szolgáltatásokat.</Typography>
                <Typography className={classes.text}>11.11. Késedelmes fizetés. Késedelmes fizetés esetén Ügyfél köteles megfizetni a Szolgáltató lejárt tartozások beszedésével járó, a szokásost meg nem haladó mértékű költségeit, beleértve a jogi képviseleti költségeket, valamint az egyéb jogi költségeket és díjakat is, a jogszabályok és előírások által engedélyezett módon és mértékben. Szolgáltató minden fizetési felszólításáért 15 EURO-nak megfelelő általánydíjat jogosult kiszámlázni. Jogunkban áll felfüggeszteni vagy megszüntetni az Önnek nyújtott Szolgáltatásokat, amennyiben Ügyfél a Szolgáltató által küldött – a szolgáltatások felfüggesztésére és/vagy megszüntetésére vonatkozó figyelmeztetést tartalmazó – fizetési felszólítást követően az adott határidőig nem fizeti meg a Szolgáltatások teljes díját. A felfüggesztést vagy a megszüntetést Ügyfél úgy kerülheti el, hogy a fizetési felszólítás kézhezvételét követően megfelelő időn belül teljesíti a szükséges kifizetést. Ha a hiányzó összeg minimális, a Szolgáltató más eljárást fog alkalmazni. A teljes számlaérték 2 százalékánál kisebb hiányzó összegek minden esetben minimálisnak tekintendők. A Szolgáltatásoknak a fizetés nem teljesítése miatti felfüggesztése vagy megszüntetése azt eredményezheti, hogy Ügyfél elveszíti a hozzáférését az AOC System-fiókjához és ott tárolt adataihoz, számláihoz, irataihoz.</Typography>
                <Typography className={classes.title}>XII. Megrendelés, előfizetési konstrukciók</Typography>
                <Typography className={classes.text}>12.1. A Szolgáltatások megrendelésére online úton, a www.aocsystem.com weboldalon keresztül, a szükséges regisztrációt követően van lehetőség.</Typography>
                <Typography className={classes.text}>12.2. Az Ügyfél a megrendelés során minden esetben köteles az alábbi valós adatokat megadni a Szolgáltató részére:</Typography>
                <ul>
                  <li>név/cégnévű</li>
                  <li>lakcím/székhely</li>
                  <li>érvényes adószám</li>
                  <li>kapcsolattartó neve</li>
                  <li>kapcsolattartó e-mail címe</li>
                  <li>kapcsolattartó telefonszáma</li>
                </ul>
                <Typography className={classes.text}>12.3. Ön köteles a megadott adatainak változását haladéktalanul, de legkésőbb a változástól számított 5 munkanapon belül a Szolgáltató részére bejelenteni, valamint az adataiban bekövetkezett változás tényét hitelt érdemlően igazolni. Az ennek elmulasztásából eredő károkért a Szolgáltató mindennemű felelőssége kizárt.</Typography>
                <Typography className={classes.text}>12.4. Átutalás esetén a Felhasználó Szolgáltató felé fennálló valamennyi fizetési kötelezettsége abban az időpontban minősül teljesítettnek, amikor az adott összeg a Szolgáltató bankszámláján hiánytalanul jóváírásra került. Szolgáltató a jóváírást követően aktiválja a Szolgáltatást az Ügyfélnek.</Typography>
                <Typography className={classes.text}>12.5. A Szoftverek igénybevételére az Ügyfeleknek havi, negyedéves, féléves vagy éves konstrukcióban történő előfizetéssel (továbbiakban: „Előfizetés”) van lehetőségük. A Szolgáltató fenntartja a jogot, hogy az Előfizetés lehetőségét és feltételeit egyedi elbírálás alapján határozza meg.</Typography>
                <Typography className={classes.text}>12.6. Előfizetés esetén a Szoftverekre vonatkozó felhasználási jogot a Szolgáltató határozott időtartamra biztosítja, amely a Megrendelés napjától (a megrendelés napja a továbbiakban „Fordulónap”) számítottan az előfizetéses konstrukciótól függően egy hónapra, negyedévre, fél évre (hat hónap) ill. egy évre (365 nap, azaz 12 hó) vonatkozik, az Ügyfél által megfizetett előfizetési díj ellenében. Az előfizetés megrendelésekor a Szolgáltató számlát állít ki, amelynek fizetési határideje egyben a teljesítés dátuma. A megrendelés visszaigazolása, valamint a fizetési kötelezettség teljesítése után az Előfizető jogosultságot kap a szolgáltatás eléréséhez.</Typography>
                <Typography className={classes.text}>12.7. A következő időszakra esedékes előfizetési díjra vonatkozó elektronikus díjbekérő a Szolgáltató a határozott időtartam lejárta előtt küldi meg az Ügyfél részére az Ügyfél által megadott e-mail címre. A számlát a díjbekérő megfizetését követő legkésőbb 2 napon belül állítja ki a Szolgáltató és küldi meg Ügyfél részére a fentiek szerint.</Typography>
                <Typography className={classes.text}>12.8. Amennyiben a következő időszakra esedékes előfizetési díj legkésőbb a Fordulónapig nem kerül az Ügyfél által megfizetésre, úgy a Szolgáltató a Szoftverekhez történő hozzáférést, a Fordulónapot követő naptól kezdődően felfüggeszti. (ld. Ügyfél fiókjának bezárása 5.4 pont)</Typography>
                <Typography className={classes.text}>12.9. Amennyiben a felfüggesztés kezdőnapjától számított 15 napon belül a következő évre esedékes előfizetési díj kiegyenlítésére nem kerül sor, úgy a Szolgáltató az Ügyfél hozzáférését jogosult véglegesen letiltani, adatait törölni.</Typography>
                <Typography className={classes.title}>XIII. Felelősségvállalás</Typography>
                <Typography className={classes.text}>13.1. A Szoftverek kizárólag olyan módon és célra használhatók fel, amely megfelel a vonatkozó jogszabályok előírásainak.</Typography>
                <Typography className={classes.text}>13.2. Ügyfél tudomásul veszi, hogy a Szoftvereket kizárólag a saját felelősségére használhatja, és a Szolgáltató kizár minden felelősséget az Ügyfél a Szoftverek használata során tanúsított magatartásáért. A Szoftverekkel kiállított dokumentumok tartalmi helyességéért, valamint az azok alapjául szolgáló egyes események valódiságáért a Szolgáltató semmilyen felelősséggel nem tartozik, ezen dokumentumok helyességének teljes körű ellenőrzése mindenkor az Ügyfél kizárólagos felelőssége és kötelessége.</Typography>
                <Typography className={classes.text}>13.3. Az Ügyfél tudomásul veszi, hogy a Szolgáltató köteles teljes mértékben együttműködni az eljáró hatóságokkal, az Ügyfél által esetlegesen elkövetett jogsértések vonatkozásában, így különösen – de nem kizárólagosan – köteles a hatóság megkeresése esetén az Ügyfélről, valamint az általa használt Szoftverekről, valamint a Szoftverekben tárolt és számára hozzáférhető adatokról adatot szolgáltatni.</Typography>
                <Typography className={classes.text}>13.4. A Szolgáltató nem felel az Ügyfelet ért és a Szoftverek használatából eredő közvetett vagy közvetlen károkért. A Szolgáltató az alkalmazandó jogszabályok által megengedett mértékig kizárja a felelősséget bárminemű bevétel- vagy nyereségkiesésért, meghiúsult értékesítési lehetőségért, adatvesztésért, helyettesítő termékek vagy szolgáltatások beszerzéséből fakadó költségekért, tulajdonban bekövetkezett kárért, üzleti forgalom kieséséért, üzleti információ elvesztésért, vagy bármiféle speciális, közvetlen, közvetett, eseti, gazdasági, fedezeti, vagy következmény kárért, függetlenül a károkozás mikéntjétől, és attól, hogy az szerződésből, gondatlanságból, vagy más, felelősséget megalapozó tényből ered, ha ezek a Szoftverek használatának vagy használhatatlanságának okán merültek fel, még abban az esetben is, ha az Szolgáltatót előzőleg értesítették az ilyen kár bekövetkeztének lehetőségéről. Szolgáltató továbbá nem vállal felelősséget a Szoftverek helyes vagy helytelen használata következtében, vagy vis maior esemény folytán felmerült károkért. Az Ügyfél kizárólagos felelősséggel tartozik azért, hogy a Szoftverekben kezelt adatait rendszeresen és az adatok jelentőségéhez mérten mentse, archiválja és tárolja.</Typography>
                <Typography className={classes.text}>13.5. A Szolgáltató bizonyos Szoftverek vonatkozásában térítésmentes demo (teszt) letöltési lehetőséget biztosíthat a Honlapon valamennyi Ügyfél részére annak érdekében, hogy az Ügyfélnek teljes egészében lehetősége legyen megismerni a Szoftverek működését és funkcióit. A Szolgáltató kifejezetten kizárja a felelősségét arra az esetre, ha az Ügyfél a Szoftverek megrendelését megelőzően a fenti lehetőséget elmulasztja.</Typography>
                <Typography className={classes.text}>13.6. A Szoftverek és az informatikai rendszerek komplex természetéből adódóan, a Szolgáltató nem vállal felelősséget azért, hogy a Szoftverek hibamentesen és zavartalanul működnek, illetve, hogy valamennyi informatikai rendszerrel és eszközzel kompatibilisek. A biztonságos üzemelés érdekében fontos, hogy az Ügyfél a használat megkezdése előtt és a Szoftverek kezelése során valamennyi elérhető tájékoztatást megismerjen, valamint valamennyi utasítást és tanácsot betartson. Fentiekre tekintettel, a Szolgáltató semmi esetre sem szavatolja, hogy a Szoftverek kielégítik az Ügyfél üzleti folyamatai által támasztott egyedi igényeket, valamint, hogy megfelelnek az Ügyfél valamennyi felhasználói elvárásának.</Typography>
                <Typography className={classes.text}>13.7. Az Ügyfél tudomásul veszi, hogy a Szoftverek, valamint az azokhoz kapcsolódó Szolgáltatások valamennyi díja a jelen szakaszban meghatározott felelősségkorlátozásra tekintettel került meghatározásra. Ügyfél a felelősségkorlátozást az előfizetéssel, a Szoftver használatának megkezdésével kifejezetten elfogadja.</Typography>
                <Typography className={classes.title}>XIV. A szerződés megszűnése</Typography>
                <Typography className={classes.text}>14.1. Egyik fél sem jogosult az Előfizetésre vonatkozó szerződést rendes felmondás útján megszüntetni, figyelemmel arra, hogy az Előfizetések határozott időre szólnak.</Typography>
                <Typography className={classes.text}>14.2. Bármelyik fél jogosult a jelen Szerződést azonnali hatállyal felmondani, amennyiben a másik Fél a jelen Szerződési Feltételekben foglalt kötelezettségét súlyosan megszegi (rendkívüli felmondás). Az Ügyfél a felmondásra vonatkozó nyilatkozatot köteles a Szolgáltató <a href="mailto:service@aocsystem.com" rel="noreferrer"  target='_blank' style={{textDecoration:'none', color:'#000'}}>service@aocsystem.com</a> e-mail címére is megküldeni, a mindenkori székhelycímére feladott postai küldemény mellett.</Typography>
                <Typography className={classes.text}>A szerződést a felmondás a postai úton történő kézhezvétellel szünteti meg.</Typography>
                <Typography className={classes.text}>14.3. Szolgáltató jogosult azonnali hatállyal felmondani a szerződést, amennyiben Ügyfél:</Typography>
                <ul>
                  <li>olyan módon használja a Szoftvert, amely Szolgáltató működésével, illetve vagy jelen ÁSZF IX. fejezetében foglaltakkal összeegyeztethetetlen</li>
                  <li>az Ügyfél olyan magatartást tanúsít, mellyel Szolgáltató jogos érdekét, különösen szerzői jogát, vagy jó hírnevét sérti; illetve Szolgáltatót bármely módon megtéveszti, amely által a szerződés további fenntartása Szolgáltató részéről nem várható el</li>
                  <li>a megrendelés során megadott adatok nem felelnek meg a valóságnak, és jelen ÁSZF rendelkezéseinek</li>
                </ul>
                <Typography className={classes.text}>14.4. Ügyfél jogosult azonnali hatállyal felmondani a szerződést, amennyiben Szolgáltató nem biztosítja, valamint meghibásodás esetén nem állítja helyre a Szoftver használatát a fennálló szerződés időtartama alatt több mint 2 munkanapig. Ez alól kivétel, ha a Szoftver külső ok miatt (pl. vis maior, internetszolgáltatás elmaradása, akadozása, szerverprobléma, leállás) miatt ideiglenesen nem működik.</Typography>
                <Typography className={classes.text}>14.5. Szolgáltató részéről történő azonnali hatályú megszűnés esetén a teljesített előfizetési díj Ügyfél részére nem jár vissza, amennyiben az Ügyfél a 14.4 pont szerint megszünteti szerződését, az előfizetési időszakból hátralévő időre esedékes díj arányos része visszajár.</Typography>
                <Typography className={classes.text}>14.6. A szerződés megszűnésével véget ér a Szolgáltató szoftver és kiegészítő szolgáltatás szolgáltatási kötelezettsége. Az Előfizetés bármely okból megszűnése esetén az Ügyfél az általa használt Szoftverekhez és adatbázisokhoz a továbbiakban nem fér hozzá, így köteles az Előfizetés megszűnése előtt a Szoftverekben tárolt adatai archiválásáról és mentéséről gondoskodni, ennek hiányából eredő kárért a Szolgáltató a felelősségét ezúton is kizárja.</Typography>
                <Typography className={classes.title}>XV. Adatvédelem és adatkezelés</Typography>
                <Typography className={classes.text}>15.1. Az AOC System fontosnak tartja az Ügyfél adatainak védelmét. A Szolgáltató az általa rögzített személyes adatokat bizalmasan, a hatályos magyar és uniós adatvédelmi jogszabályokkal összhangban kezeli, az Adatvédelmi Szabályzatban meghatározottak szerint.</Typography>
                <Typography className={classes.text}>15.2. Az Ügyfél a jelen ÁSZF elfogadásával kijelenti, hogy a Szolgáltató Adatvédelmi Szabályzatát megismerte és az abban foglaltakat magára nézve kötelezőnek ismeri el.</Typography>
                <Typography className={classes.text}>15.3. Szolgáltató kifejezetten felhívja az Ügyfél figyelmét, hogy olvassa el az AOC System Adatvédelmi Nyilatkozatát (a továbbiakban: az „Adatvédelmi Nyilatkozat”), mert ez tartalmazza milyen fajta adatokat gyűjtünk össze Ügyféltől és az Ügyfél eszközeiről (a továbbiakban „Adatok”), miként használjuk fel az Ügyfél Adatait, továbbá azt, hogy milyen jogi alapon kezeljük az Ügyfél Adatait.</Typography>
                <Typography className={classes.text}>Az Adatvédelmi Nyilatkozat tartalmazza azt is, hogy az AOC System miként használja fel az Ügyfél tartalmát, azaz az Ügyfél másokkal való kommunikációját; az Ügyfél által a Szolgáltatásokon keresztül az AOC System számára beküldött anyagokat; valamint azokat a fájlokat, fényképeket, dokumentumokat, hanganyagokat, digitális munkákat, amelyeket Ügyfél a Szolgáltatásokon keresztül feltölt, tárol, sugároz vagy megoszt (a továbbiakban: az „Ügyfél Tartalma”). Ha az adatkezelés alapja az Ügyfél hozzájárulása, akkor azzal, hogy Ügyfél elfogadja a jelen Feltételeket, Ügyfél hozzájárul ahhoz, hogy az AOC System a jogszabályok által megengedett mértékben, az Adatvédelmi Nyilatkozatban foglaltaknak megfelelően gyűjtse, használja és nyilvánosságra hozza az Ügyfél Tartalmát és Adatait.</Typography>
                <Typography className={classes.title}>XVI. Vegyes rendelkezések</Typography>
                <Typography className={classes.text}>16.1. Az Ügyfél a Szolgáltató tevékenységével kapcsolatos kifogásait az alábbi elérhetőségeken terjesztheti elő: <a href="mailto:service@aocsystem.com" rel="noreferrer"  target='_blank' style={{textDecoration:'none', color:'#000'}}>service@aocsystem.com</a></Typography>
                <Typography className={classes.text}>16.2. Amennyiben a Szolgáltató és Ügyfél között esetlegesen fennálló jogvita Szolgáltatóval való egyeztetések során nem rendeződik – Feleknek mindent meg kell tenni a jogvita békés rendezése érdekében.</Typography>
                <Typography className={classes.text}>Bármely további vita elbírálására, amely a jelen szerződésből vagy azzal összefüggésben, így különösen annak megszegésével, megszűnésével, érvényességével vagy értelmezésével kapcsolatban keletkezik, a felek az állami bírósági utat kizárják és alávetik magukat a Magyar Kereskedelmi és Iparkamara mellett működő Állandó Választottbíróság (Kereskedelmi Választottbíróság Budapest) kizárólagos és végleges döntésének azzal, hogy a Választottbíróság a saját (Gyorsított Eljárásra vonatkozó Alszabályzat rendelkezéseivel kiegészített) Eljárási Szabályzata szerint jár el, az eljáró választottbíró(k) száma három és az eljárás során a magyar nyelvet kell alkalmazni. A felek kizárják a választottbíráskodásról szóló 2017. évi LX. törvény IX. Fejezetében szabályozott eljárásújítás lehetőségét. A jogvita eldöntésére alkalmazandó anyagi jog a magyar jog, ide nem értve annak nemzetközi magánjogi szabályait.</Typography>
                <Typography className={classes.text}>16.3. A jelen szakasz, továbbá azok a szakaszok, amelyek a bennük foglalt feltételek jellege miatt a szerződés megszűnése után is alkalmazandók, a jelen Feltételek bármilyen felmondása vagy megszűnése után is hatályban maradnak.</Typography>
                <Typography className={classes.text}>16.4. Felek a napi kapcsolatot egymással e-mailben és/vagy szóban (telefonon, személyesen) tartják, az e-mailen elküldött üzeneteiket írásbeli bizonyítéknak kell tekinteni. E-mailben bármely nyilatkozat elküldhető joghatályosan, így pl. a felmondás előtti felszólításokra, díjfizetésekre, fizetési felszólításra vonatkozó is, kivéve a felmondására vonatkozó nyilatkozatokat, mely érvényes és hatályos postai kézbesítéssel.</Typography>
                <Typography className={classes.text}>Postai küldemény: Ha a nyilatkozatot a címzettnek a megadott címére postán küldték el, a tértivevényen feltüntetett átvételi időpontban, tértivevény nélküli ajánlott küldemény esetében pedig – az ellenkező bizonyításáig – a feladástól számított ötödik munkanapon (beleértve, ha a címzett a levél átvételét megtagadta, nem kereste, elköltözött, a megadott címen ismeretlen), kell kézbesítettnek tekinteni.</Typography>
                <Typography className={classes.text}>E-mail: munkanapokon 17:00-ig elküldött elektronikus levelet még aznap, az ezt követően, vagy munkaszüneti napon elküldött elektronikus levelet a következő munkanap 9:00 órájában kell kézbesítettnek tekinteni, függetlenül attól, hogy az olvasási/kézbesítési visszaigazolás mikor, vagy egyáltalán nem érkezik. A Szerződő Felek által a másik fél e-mail címére küldött valamennyi értesítés a fentiek szerint kézbesítettnek minősül (kézbesítési fikció) – akkor is, ha kiküldött e-mail az ún. „junk” fiókba kerül, kivéve, ha a küldő fél a levél kézbesítéséről hibajelentést kap vissza.</Typography>
                <Typography className={classes.text}>16.4. A Szolgáltató a jelen Feltételekből adódó jogaikat és kötelezettségeiket részben vagy egészben, értesítés nélkül, bármikor engedményezhetik, átruházhatják vagy egyéb módon eladhatják, amennyiben ez az engedményezés, átruházás, illetve eladás nem sérelmes az Ügyfélre nézve.</Typography>
                <Typography className={classes.text}>16.5. Az Ügyfélnek nem áll jogában a jelen Feltételeket vagy a Szolgáltatások bármilyen használati jogát más számára engedményezni, átruházni vagy más módon eladni.</Typography>
                <Typography className={classes.text}>16.6. A jelen Feltételek minden része a vonatkozó jogszabályok által megengedett keretek között a legnagyobb mértékben alkalmazandó. Ha egy bíróság vagy egy választottbíró úgy rendelkezik, hogy a jelen Feltételek egyes részei az itt rögzített formában általunk nem érvényesíthetők, akkor úgy tekintendő, hogy a kérdéses feltételek helyébe hasonló, az alkalmazandó jogszabályok keretei között érvényesíthető feltételek lépnek, de a jelen Feltételek további részei változatlan formában érvényben maradnak.</Typography>
                <Typography className={classes.text}>16.7. Az Ügyfélnek mindenkor be kell tartania a szoftverekre és/vagy a Szolgáltatásokra vonatkozó összes hazai és nemzetközi exportjogszabályt és -előírást, ideértve a rendeltetési helyre, a végfelhasználókra és a felhasználás céljára vonatkozó korlátozásokat is.</Typography>
                <Typography className={classes.text}>A jelen ÁSZF-ben nem szabályozott kérdésekben a mindenkor hatályos magyar Ptk. és más jogszabályok alkalmazandók.</Typography>
                <Typography textAlign='left' marginTop='50px'>Jelen ÁSZF rendelkezései  2020. dececember 14. napjától visszavonásig hatályosak.</Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default TermsAndConditions
