import {
  Box, Button, Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import Banner from "../Banner/Banner";
import Image from '../Image';
import imacMackBook from "../../static/public/mockups/imac-macbook.png"
import worldMap from "../../static/public/world-map-dotted-2.png"
import salesActivityLogo from "../../static/public/sales-activity-logo.svg"
import businessAndOfficeLogo from "../../static/public/business-and-office-logo.svg"
import dpoStoreLogo from "../../static/public/dpo-store-logo.svg"
import LockIconFree from '../../static/public/lockIconFree.png';
import dlogo from "../../static/public/dlogo.png"

const useStyles = makeStyles(theme => ({
  parallaxText: {
    color: ' #f2f2f2 ',
    // opacity: 1,
    fontFamily: 'Poppins, sans-serif',
    // whiteSpace: 'nowrap',
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  mainBox: {
    backgroundColor: 'black'

  },
  productsGrid: {
    marginTop: '-80px',
    marginBottom: '120px',
    padding: '10px'
  },
  image: {
  },
  button: {
    marginRight: '20px',
    padding: '10px 20px',
    borderRadius: 30
  },
  gradientTitle: {
    background: "-webkit-linear-gradient(45deg, #56cac6 30%, #07B1AB 50%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  descriptionTitle: {
    display: 'block',
    // whiteSpace: 'nowrap'
  },
  description: {
    position: 'relative',
    display: 'block',
  },
  interested: {
    opacity: '0.6',
    '&:hover': {
      transition: '0.2s',
      opacity: 1
    },
  },
  Divider: {
    marginTop: '40px'
  }
}))

export default function HomeMobile() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:380px');

  document.title = t('Title.main') + " | " + t('Title.subTitle.home')

  return (
    <React.Fragment>
      <Paper>
      <Grid
        display={"flex"}
        justifyContent={"right"}
      >
        <Link
          to="/szechenyi2020"
        >
          <img src={dlogo} width={200}>
          </img>
        </Link>
      </Grid>
        <Box
          Box className="mainClass"
          id="topHome"
          // id='main-box'
          sx={{
            // background: 'linear-gradient(172deg, rgba(255,255,255,1) 3%, rgba(60,185,184,0.6587009803921569) 66%, rgba(255,255,255,1) 92%)',
            minHeight: '0vh',
            py: 10,
            overflow: 'hidden'
          }}
        >
          <Container>
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <div>
                <Typography data-aos="fade-right" variant='h1' className={classes.gradientTitle} fontWeight='bold' marginBottom='50px' style={{ letterSpacing: '-0.1px', }}>
                  {t('pages.homePage.buyTitle')}
                </Typography>
                <Typography variant='h5' marginBottom="30px">
                  {t('pages.homePage.buyDescription1')}
                </Typography>

                <Button className={classes.button} disableElevation variant='contained' href="https://my.aocsystem.com/products" rel="noreferrer" target="_blank" >{t('common.button.buyButton')}</Button>

              </div>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={5}
              sm={10}
              xs={10}
              data-aos='fade-left'
              marginTop='50px'
              minHeight='25vh'
            >
              <div
                className={classes.image}
                style={{

                }}
              >
                <img
                  src={imacMackBook}
                  width='110%'
                  alt="macbook-ipad-imac-mockup"
                />
              </div>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              className="trigger"
            >
              <Grid
                item
                lg={12}
                xl={12}
                sm={12}
                xs={12}
                display='flex'
                justifyContent='flex-start'
                data-aos="fade-up"
              >
                <div>
                  <Typography className={classes.descriptionTitle} color='secondary' fontWeight='bold' variant='h3' marginTop={10} marginBottom={2} textAlign='left'>
                    {t('pages.homePage.description1Title')}
                  </Typography>
                  <Typography className={classes.description} variant='h5' textAlign='left'>
                    {t('pages.homePage.description1')}
                  </Typography>
                </div>
              </Grid>
              <Divider className={classes.Divider} />
              <Grid
                item
                xl={5}
                lg={5}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                justifyContent='flex-start'
                alignItems='flex-start'
                data-aos="fade-up"
              >
                <div>
                  <Typography className={classes.descriptionTitle} color='secondary' fontWeight='bold' variant='h3' marginTop={5} marginBottom={2} textAlign='left'>
                    {t('pages.homePage.description2Title')}
                  </Typography>
                  <Typography className={classes.description} variant='h5' textAlign='left'>
                    {t('pages.homePage.description2')}
                  </Typography>
                </div>
              </Grid>
              <Divider className={classes.Divider} />
              <Grid
                item
                lg={5}
                xl={5}
                md={12}
                sm={12}
                xs={12}
                data-aos="fade-up"
                mb={15}
              >

                <div>
                  <Typography className={classes.descriptionTitle} color='secondary' fontWeight='bold' variant='h3' marginTop={5} marginBottom={2} textAlign='left'>
                    {t('pages.homePage.description3Title')}
                  </Typography>
                  <Typography className={classes.description} variant='h5' textAlign='left'>
                    {t('pages.homePage.description3')}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
          <Box
            style={{ height: '350px' }}
            backgroundColor='#3cb9b8'
            display='flex'
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              id="gsap-text-main-id"
              container
              direction="column"
              display='flex'
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                // display='flex'
                // alignItems="center"
                // justifyContent="center"
                data-aos="fade-up"
              >
                <Typography p={3} id="gsap-text" variant='h1' fontSize={!matches && '2.3em'} textAlign='center'
                  className={classes.parallaxText} >
                  {t('pages.homePage.targetDevice')}
                </Typography>
                <Typography variant='h6' fontWeight="bold" color="white" textAlign="center">
                  {t('pages.homePage.buyDescription1_2')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Box>
      </Paper>
      <Box
        sx={{
          background: 'white',
          minHeight: '0vh',
          overflow: 'hidden'
        }}>
        <Container>
          <Grid
            container
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <div>
                <Typography className={classes.lastText} textAlign='center' variant='h2' marginBottom="50px" fontWeight='bold' style={{ color: '#2c3e50' }}>
                  {t('pages.homePage.buyDescription2')}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            marginBottom={-12}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{
                textAlign: 'center',
              }}
            >
              <img src={worldMap}

                width='100%'
                alt="world-map-dotted"
                style={{ opacity: 0.20 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  )
}
