import { Box, Container, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Banner from '../../components/Banner/Banner';
import barion from '../../static/public/barion-card-strip-intl_800px.png'

const useStyles = makeStyles({
  primary: {

    marginTop: "50px",
    fontWeight: 'bold'
  },
  secondary: {
    marginTop: "5px"
  }
})

function Impressum() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:900px)');

  document.title = t('Title.main') + " | " + t('Title.subTitle.impressum')

  return (
    <React.Fragment>
      <Paper>
        <Box
          className='main'
          minHeight='80vh'
          sx={{
            background: 'white',
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>

          <Grid
            container>

            <Banner background='#546e7a' marginBottom={0} style={{ height: '10vh' }} >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                alignItems='center'
                marginBottom={0}>
                <Container>
                  <Typography variant='h3' textAlign={matches ? 'left' :'center'} fontWeight='bold' color='white'>Dilato Finance Kft.</Typography>
                </Container>
              </Grid>
            </Banner>
          </Grid>
          <Container>
            <Grid
              container
              display='flex'
              justifyContent='space-between'
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                <Typography className={classes.primary} style={{ marginTop: '65px' }}>
                  Iroda <Typography className={classes.secondary}> 1062 Budapest, Délibáb utca 8.</Typography>
                </Typography>
                <Typography className={classes.primary} style={{ marginTop: '25px' }}>
                  Cégjegyzékszám <Typography className={classes.secondary}> 01 09 728751,  Fővárosi Törvényszék Cégbírósága, mint Cégbíróság</Typography>
                </Typography>
                <Typography className={classes.primary} style={{ marginTop: '25px' }}>
                  Adószám <Typography className={classes.secondary}> 13310691-2-42</Typography>
                </Typography>
                <Typography className={classes.primary} style={{ marginTop: '25px' }}>
                  E-mail <Typography className={classes.secondary}> office@dilato.eu</Typography>
                </Typography>
                <Typography className={classes.primary} style={{ marginTop: '25px' }}>
                  Kamarai tagság <Typography className={classes.secondary}> Budapesti Kereskedelmi és Iparkamara</Typography>
                </Typography>
                <Typography className={classes.primary} style={{ marginTop: '25px' }}>
                  Számlavezető bank
                </Typography>
                <ul style={{ listStyleType: 'none' }}>
                  <li style={{ listStyleType: 'none' }}><Typography className={classes.secondary}>{t('company.impressum.bankName')}</Typography></li>
                  {/* <ul style={{listStyleType: 'none'}}> */}
                  <li style={{ listStyleType: 'none' }}><Typography className={classes.secondary}>{t('company.impressum.swift')}</Typography></li>
                  <li style={{ listStyleType: 'none' }}><Typography className={classes.secondary}>{t('company.impressum.ibanHuf')}</Typography></li>
                  {/* <li style={{ listStyleType: 'none' }}><Typography className={classes.secondary}>{t('company.impressum.ibanEur')}</Typography></li> */}
                  {/* </ul> */}
                </ul>
              </Grid>
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                margin={2}
              >
                <Typography className={classes.primary}>
                  {t('company.impressum.dataProtectionOfficer')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.dataProtectionOfficerName')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.dataProtectionOfficerEmail')}
                </Typography>
                <Typography className={classes.primary}>
                  {t('company.impressum.legalRepresentation')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.legalRepresentationName')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.legalRepresentationEmail')}
                </Typography>
                <Typography className={classes.primary}>
                  {t('company.impressum.hostingService')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.hostingServiceName')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.hostingServiceLocation')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.hostingServiceCRN')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.hostingServiceTaxNum')}
                </Typography>
                {/* <Typography className={classes.primary}>
                  {t('company.impressum.cardPayment')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.cardPaymentDescription')}
                </Typography>
                <Typography className={classes.secondary}>
                  {t('company.impressum.cardPaymentData')}
                </Typography>
                <a href={'https://www.barion.com/hu/fizetesi-kapu/attekintes/'} rel="noreferrer" target='_blank'>
                  <img style={{ marginTop: '20px' }}
                    src={barion}
                    alt="barion"
                    width='40%'></img>
                </a> */}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default Impressum
