import {
  Alert,
  Box,
  Button,
  FormControl,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import React from 'react';
import theme from '../../theme/index';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/Banner/Banner';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  button: {
    padding: '10px 20px',
    borderRadius: 30,
    '&:disabled': {
      backgroundColor: '#07B1AB75',
      color: '#fff'
    }
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: 0.25,
    fontSize: 50,
  }
});

function Contact() {
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({});
  const [subject, setSubject] = React.useState('');
  const [name, setName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [mobileNumber, setMobileNumber] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [buttonText, setButtonText] = React.useState('Küldés')
  const [submitted, setSubmitted] = React.useState(false)
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:900px)');
  const { t, } = useTranslation();


  document.title = t('Title.main') + " | " + t('Title.subTitle.contact')

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeDropdown = (event) => {
    setSubject(event.target.value);
  };

  const onNameChange = (event) => {
    setName(event.target.value)
  }

  const onLastNameChange = (event) => {
    setLastName(event.target.value)
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const onMessageChange = (event) => {
    setMessage(event.target.value)
  }

  const onNumberChange = (event) => {
    setMobileNumber(event.target.value)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: "POST",
      url: "https://my.aocsystem.com:444/aoc.api/AC_CallBack/CallBackendWithoutToken",
      data: {
        EntityName: "ACCommon",
        FormName: "ContactRequest",
        FunctionName: "SendContactRequest",
        FirstName: name,
        LastName: lastName,
        Email: email,
        Subject: subject,
        Phone: mobileNumber,
        Message: message,
        DataPolicyAgreement: checked,
      }
    }).then((response) => {
      if (response.data.data.status === 'success') {
        setSubmitted(true)
        setButtonText('Elküldve')
        setOpen(true)
        setSnackbar({
          severity: "success",
          message: "Üzenet sikeresen elküldve"
        })
        // this.resetForm()
      } else if (response.data.data.status === 'fail') {
        setSnackbar({
          severity: "error",
          message: "Az üzenet küldése sikertelen!"
        })
        alert("Az üzenet küldése sikertelen!")
      }
    }).catch(function (error) {
      setOpen(true)
      setSnackbar({
        severity: "error",
        message: "Az üzenet küldése sikertelen!"
      })
      alert("Az üzenet küldése sikertelen!")
      // this.resetForm()
    });
  }

  return (
    <React.Fragment>
      <Paper>
        <Box
          className="mainClass"
          sx={{
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // background: '#f5f5f7',
            // background: '#07B1AB25',
            minHeight: '100vh',
            pt: 10,
            pb: 30,
          }}
        >
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Grid
            container>
            <Banner background='#3cb9b8' marginBottom={10} style={{ height: '20vh' }} >
              <Container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  display='flex'
                  justifyContent={matches ? 'left' : 'center'}
                  alignItems='center'
                  marginBottom={0}>
                  <Typography variant='h2' fontWeight='bold' color='white'>{t('contact.contact')}</Typography>
                </Grid>
              </Container>
            </Banner>
          </Grid>
          <Container>
            <Grid
              container
              display='flex'
              justifyContent='center'
              alignItems='center'>
              {/* <Card
                style={{ padding: '40px 40px 20px 40px', margin: "0 auto", borderRadius: 20 }}
                raised={false}
                elevation={0}
              > */}
              {/* <CardContent> */}
              <Grid container spacing={10}>
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  sm={8}
                  xs={12}
                >
                  <Grid container display='flex' flexDirection='column' alignItems='space-between'>
                    <Grid item>
                      <Typography variant='h3' fontWeight='bold' marginBottom={5} color='secondary'>
                        {t('contact.help')}
                      </Typography>
                      <Typography variant='subtitle1' color='default'>
                        {t('contact.question')}
                      </Typography>
                    </Grid>
                    {/* <Grid item>
                          <Grid container justifyContent='flex-start' alignItems='center'>
                            <EmailIcon className={classes.icon} />
                          </Grid>
                        </Grid> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={7}
                  lg={7}
                  md={7}
                  sm={12}
                  xs={12}
                >
                  <form id="contact-form" onSubmit={handleSubmit} method="POST">
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6} item>
                        <TextField placeholder={t('contact.name')}
                          label={t('contact.name')}
                          variant="outlined"
                          fullWidth
                          required
                          color='secondary'
                          onChange={onNameChange}
                        />
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <TextField placeholder={t('contact.lastName')}
                          label={t('contact.lastName')}
                          variant="outlined"
                          fullWidth
                          required
                          color='secondary'
                          onChange={onLastNameChange}
                        />
                      </Grid>
                      < Grid item xs={12}>
                        <TextField type="email" placeholder={t('contact.email')}
                          label={t('contact.email')}
                          variant="outlined"
                          fullWidth
                          required
                          color='secondary'
                          onChange={onEmailChange}
                        />
                      </Grid>
                      < Grid item xs={12}>
                        <TextField placeholder={t('contact.mobileNumber')}
                          label={t('contact.mobileNumber')}
                          variant="outlined"
                          fullWidth
                          required
                          color='secondary'
                          onChange={onNumberChange}
                        />
                      </Grid>
                      < Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-helper-label" color='secondary'>Válassz tárgyat...</InputLabel>
                          <Select fullWidth labelId={t('contact.subject')}
                            value={subject}
                            // placeholder='Válassz'
                            onChange={handleChangeDropdown}
                            color='secondary'
                            label='Válassz terméket'>
                            {/* <MenuItem value="">
                                <em>Téma választás!</em>
                              </MenuItem> */}
                            <MenuItem value="Általános információ"> {t('contact.information')} </MenuItem>
                            <MenuItem value="Demo verzió igénylése">{t('contact.demo')}</MenuItem>
                            <MenuItem value="Előfizetés"> {t('contact.payment')} </MenuItem>
                            <MenuItem value="Regisztráció"> {t('contact.registration')} </MenuItem>
                            <MenuItem value="Segítség & Támogatás">{t('contact.helpNService')}  </MenuItem>
                            <MenuItem value="Számlázás"> {t('contact.buy')} </MenuItem>
                            <MenuItem value="Egyéb">{t('contact.other')}  </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Üzenet"
                          multiline
                          rows={8}
                          placeholder="Üzenet"
                          variant="outlined"
                          fullWidth
                          color='secondary'
                          onChange={onMessageChange}
                        />
                        <FormControlLabel
                          label={
                            <div>
                              <Typography variant='caption'>
                                {t('contact.agreement')} {` `}
                              </Typography>
                              <Typography variant='caption'>
                                <a href={'#/data-management-information'} rel="noreferrer" target='_blank' color='secondary' style={{ textDecoration: 'none', color: '#07B1AB' }}>
                                  {t('contact.dataManagementInfo')}
                                </a>
                              </Typography>
                            </div>
                          }
                          control={
                            <Checkbox
                              color='secondary'
                              required
                              checked={checked}
                              onChange={handleChange}
                            // inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                        >
                        </FormControlLabel>
                      </Grid>
                      <Grid container display="flex" justifyContent="flex-end" marginTop={5}>
                        <Grid item xs={5}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            fullWidth
                            disabled={submitted}
                          >
                            {buttonText}
                            {submitted && <CheckCircleIcon style={{ marginLeft: 5 }} />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
              {/* </CardContent>
              </Card> */}
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default Contact
