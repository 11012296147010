import { useLocation } from 'react-router-dom'
import salesActivityLogo from "../static/public/sales-activity-logo.svg"
import businessAndOfficeLogo from "../static/public/business-and-office-logo.svg"
import dpoStoreLogo from "../static/public/dpo-store-logo.svg"
import aocSystemLogo from "../static/public/aoc-system-zrt-logo.svg"

export default function Logo(props) {

  const location = useLocation();
  const product = location.pathname.split('/').pop()
  
  const { height, src } = props

  const getLogo = () => {
    switch (product) {
      case 'sales-activity':
        return salesActivityLogo
      case 'business-and-office':
        return businessAndOfficeLogo
      case 'dpo-store':
        return dpoStoreLogo
      default:
        return aocSystemLogo
    }
  }

  return (
    <img
      alt={'aoc-system-logo'}
      src={getLogo()}
      height={height || 50}
      {...props}
    />
  )
}