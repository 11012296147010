import { Button, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'

function CareerFooter(props) {
  const { subject } = props;
  const { color, title } = props.children
  //console.log(color)
  return (
    <React.Fragment>
      <Container>
        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={12}
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
          marginBottom={10}
          marginTop={5}>
          <Button href={title ? `mailto:contact@aocsystem.com?subject=${title}` : 'mailto:contact@aocsystem.com'} variant='outlined' style={{ color: 'white', backgroundColor: color, borderRadius: 30, padding: '10px 20px', }}>Jelentkezem</Button>
        </Grid>
        <Grid
          item
          textAlign='justify'
          marginBottom={5}
          marginTop={10}>
          <div style={{ marginBottom: '10px' }}>
            <Typography variant='caption'>
              Az AOC System Zrt. mindig a legmegfelelőbb jelöltet igyekszik megtalálni, tekintet nélkül a jelölt faji és etnikai hovatartozására, politikai beállítottságára, vallására vagy filozófiai meggyőződésére, szakszervezeti tagságára, egészségére vagy szexuális életére.
            </Typography>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Typography variant='caption' >
              Kérjük, ne küldjön részünkre olyan önéletrajzot, motivációs levelet vagy más mellékletet, amely a következők valamelyikét vagy mindegyikét tartalmazza: fénykép, érdeklődési kör, hobbi, egyéb olyan adat, amely nem releváns a munkaviszony létesítése, teljesítése, megszűnése szempontjából.
            </Typography>
          </div>
          <div style={{ marginBottom: '10px' }} >
            <Typography variant='caption'>
              Továbbá arra kérjük, hogy ne osszon meg velünk semmilyen az előző, vagy jelenlegi munkahelyével kapcsolatos üzleti titkot vagy vállalati információt. Adatait a GDPR-nak (EU  2016/679 sz. rendelete), valamint a hatályos nemzeti jogszabályok és hatósági állásfoglalások, ajánlások alapján kezeljük a pozíció betöltéséig, vagy azt követően az Ön önkéntes hozzájárulásával a hozzájárulásban meghatározott célból és ideig.
            </Typography>
          </div>
          <div style={{ marginBottom: '10px' }} >
            <Typography variant='caption'>
              Köszönjük a közreműködését!
            </Typography>
          </div>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default CareerFooter
