
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import BasicTable from '../BasicTable/BasicTable';
import { useMediaQuery, } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    padding: '10px 35px',
    borderRadius: 30,
  },
});

export default function PricingDialog(props) {
  const classes = useStyles();
  const { plan } = props
  const matches = useMediaQuery('(min-width:700px');
  const { dialog, price, buttonText, buttonVariant, href } = plan

  return (
    <React.Fragment>
      <Grid container display='flex' flexDirection='column' alignItems='center' spacing={10} pb={10}>
        <Grid
          item
          xl={12}
          lg={12}
          sm={12}
          md={12}
          xs={12}
        >
          <Typography variant='h3' fontWeight='bold' mb={5}>{dialog.title}</Typography>
          {dialog.descriptions.map((description) => (
            <Typography textAlign='justify' variant='subtitle1' my={0}>{description}</Typography>
          ))}
        </Grid>

        {dialog.tables.map((data) => {
          return <Grid
              item
              xl={12}
              lg={12}
              sm={12}
              md={12}
              xs={12}
              minWidth={matches ? '500px' : '0px'}
            >
              <BasicTable
                rows={data.rows}
                columns={data.columns}
              />
            </Grid>
        }
        )}
        <Grid
          item
          xl={12}
          lg={12}
          sm={12}
          md={12}
          xs={12}

        >
          <Grid
            container
            display='flex'
            flexDirection='row'
            justifyContent='spaceBetween'
            alignItems='center'
            spacing={2}
          >
            <Grid
              item
              xl={10}
              lg={10}
              md={10}
              sm={8}
              xs={12}
            >
              <Grid container flexDirection='column' alignItems='flex-start' maxWidth>
                <Typography gutterBottom variant='h6'>{props.monthly ? dialog.monthlyPlan : dialog.yearlyPlan}</Typography>
                <Typography fontWeight='bold' variant='h3'>{props.monthly ? price.monthly : price.yearly}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              sm={4}
              xs={12}
            >
              <Button className={classes.button} disableElevation variant={buttonVariant} style={{ textTransform: 'none', fontSize: 20, }} target="_blank" href={href}>
                {buttonText}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xl={12}
              lg={12}
              sm={12}
              md={12}
              xs={12}
              mt={5}
            >
              {dialog.notes.map((note) => (
                <Typography variant='subtitle2' color='default' my={3}> {note}</Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment >
  );
}