import Element from '../components/Element/Element'
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import ProductNav from '../components/Navigation/NavSecondary';

function Advantages() {
  return (
    <Paper>
      <Box
        sx={{
          background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
          minHeight: '100vh',
          py: 10
        }}
      >
        {/* <ProductNav></ProductNav> */}
        <Typography variant='h3' margin={'80px 20px'} data-aos="fade-right">Advantages
        </Typography>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={7}
          >
            {[1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000].map((item, index) => {
              return <Grid
                key={index}
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                data-aos="fade-up"
              >
                <Element title={item}></Element>
              </Grid>
            })
            }
          </Grid>
        </Container>
      </Box>
    </Paper>
  )
}

export default Advantages;