import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home'
import Advantages from './pages/Advantages'
import Features from './pages/Features'
import Prices from './pages/Prices'
import Products from './pages/Products'
import NotFound from './pages/NotFound'
import BusinessAndOffice from './pages/ProductPages/BusinessAndOffice'
import DpoStore from './pages/ProductPages/DpoStore'
import SalesActivity from './pages/ProductPages/SalesActivity'
import Privacy from './pages/Privacy'
import Impressum from './pages/company/Impressum';
import AboutUs from './pages/company/AboutUs'
import Frontend from './pages/company/career/Frontend'
import Backend from './pages/company/career/Backend'
import WebDeveloper from './pages/company/career/WebDeveloper'
import Career from './pages/company/Career'
import TermsAndConditions from './pages/Information/TermsAndConditions';
import Contact from './pages/company/Contact';
import PrivacyPolicy from './pages/Information/PrivacyPolicy';
import DataManagementInformation from './pages/Information/DataManagementInformation';
import Szechenyi2020 from './pages/Information/szechenyi2020';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      {/* <Redirect exact from="/" to="/home" /> */}

      <Route exact path='/advantages' component={Advantages} />
      <Route exact path='/features' component={Features} />
      <Route exact path='/prices' component={Prices} />
      <Route exact path='/products' component={Products} />
      <Route exact path='/szechenyi2020' component={Szechenyi2020} />
      <Route exact path='/products/sales-activity' component={SalesActivity} />
      <Route exact path='/products/business-and-office' component={BusinessAndOffice} />
      {/* <Route exact path='/products/dpo-store' component={DpoStore} /> */}
      <Route exact path='/privacy' component={Privacy} />
      <Route exact path='/imprint' component={Impressum} />
      <Route exact path='/about' component={AboutUs} />

      <Route exact path='/career/webdeveloper' component={WebDeveloper} />
      <Route exact path='/career' component={Career} />
      <Route exact path='/career/frontend' component={Frontend} />
      <Route exact path='/career/backend' component={Backend} />
      <Route exact path='/career/webdeveloper' component={WebDeveloper} />
      <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
      <Route exact path='/contact' component={Contact} />

      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route exact path='/data-management-information' component={DataManagementInformation} />

      <Route path="*" to="/404" component={NotFound} />
    </Switch>
  )
}

export default Routes;
