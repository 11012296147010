import { Box, Container, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  dialogClass: {
    //background:'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
  },
  button: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen} className={classes.button}>
        {props.button}
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      // children = {children}
      >
        <Toolbar
        >
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Grid
          container
          className={classes.dialogClass}
          height="100%"
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <Container>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="90vh"

              >
                {props.children}
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}