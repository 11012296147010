import React from "react";
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Image from '../Image';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useHistory } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import Banner from "../Banner/Banner";
import imacMackBook from "../../static/public/mockups/imac-macbook.png"
import worldMap from "../../static/public/world-map-dotted-2.png"
import salesActivityLogo from "../../static/public/sales-activity-logo.svg"
import businessAndOfficeLogo from "../../static/public/business-and-office-logo.svg"
import dpoStoreLogo from "../../static/public/dpo-store-logo.svg"
import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockIconFree from '../../static/public/lockIconFree.png';
import AboutUs from '../AboutUs';
import dlogo from "../../static/public/dlogo.png"

gsap.registerPlugin(ScrollTrigger)

const useStyles = makeStyles({
  parallaxTextGridContainer: {
    marginTop: '0px',
  },
  parallaxText: {
    fontSize: '3.5rem',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    whiteSpace: 'nowrap',
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  mainBox: {
    backgroundColor: 'black'

  },
  productsGrid: {
    paddingLeft: '100px',
    paddingRight: '20px',
    paddingBottom: '25px',
    marginBottom: '30vh',
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer'
  },
  button: {
    marginRight: '20px',
    padding: '10px 20px',
    borderRadius: 30
  },
  gradientTitle: {
    background: "-webkit-linear-gradient(45deg, #56cac6 30%, #07B1AB 50%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  // gradientTitle2: {
  //   background: "-webkit-linear-gradient(45deg, #56cac6 0%, #07B1AB 75%)",
  //   WebkitBackgroundClip: "text",
  //   WebkitTextFillColor: "transparent"
  // },
  descriptionTitle: {
    display: 'block',
  },
  description: {
    position: 'relative',
    display: 'block',
  },
  interested: {
    opacity: '0.6',
    '&:hover': {
      transition: '0.2s',
      opacity: 1
    }
  },
  lastText: {
  },
})

function HomeDesktop() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:1200px');
  let history = useHistory()

  document.title = t('Title.main') + " | " + t('Title.subTitle.home')

  return (
    <React.Fragment>
  
      <Paper
      >
        <Box className="mainClass"
          id="topHome"
          sx={{
            // background: 'linear-gradient(172deg, rgba(255,255,255,1) 3%, rgba(60,185,184,0.6587009803921569) 66%, rgba(255,255,255,1) 92%)',
            background: 'linear-gradient(172deg, rgba(255,255,255,1) 5%, rgba(60,185,184,0.6000009803921569) 34%, rgba(255,255,255,1) 55%)',
            minHeight: '100vh',
            py: 10,
            overflow: 'hidden'
          }}
        >
          <Grid
            display={"flex"}
            justifyContent={"right"}
            mt={-10}
          >
            <Link
              to="/szechenyi2020"
            >
              <img src={dlogo} width={400}
              style={{
                imagerRendering : 'pixelated',
                filter: 'contrast(1.25)'
              }}
              >
              </img>
            </Link>
          </Grid>
          <Container>
            <Grid
              className='main'
              container
              spacing={3}
              marginTop='100px'
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                <div>
                  <Typography variant='h1' data-aos="fade-right" className={classes.gradientTitle} fontWeight='bold' marginTop='-100px' marginBottom='50px' style={{ letterSpacing: '-0.1px', }}>
                    {t('pages.homePage.buyTitle')}
                  </Typography>
                  {/* <div data-aos="fade-up"> */}
                  <Typography variant='h5' marginBottom="30px">
                    {t('pages.homePage.buyDescription1')}
                  </Typography>
                  {/* <Button className={classes.button} disableElevation variant='contained' href="https://my.aocsystem.com/products" rel="noreferrer" target="_blank" >{t('common.button.buyButton')}</Button> */}

                </div>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                data-aos='fade-left'
                marginTop='-120px'
              >
                <div
                  className={classes.image}
                  style={{
                    zIndex: '1',
                  }}
                >
                  <img
                    src={imacMackBook}
                    dataAos='fade-left'
                    dataAosDelay='300'
                    width='120%'
                    alt="macbook-ipad-imac-mockup"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Grid
              container
              spacing={0}
              className="trigger"
              mt={30}
            >
              <Grid
                item
                lg={8}
                xl={8}
                md={7}
                sm={12}
                xs={12}
                data-aos="fade-up"
              // marginRight='700px'
              >
                <Typography className={classes.gradientTitle2} fontWeight='bold' variant='h2' marginBottom={2} textAlign='left' color="secondary">
                  {t('pages.homePage.description1Title')}
                </Typography>
                <Typography className={classes.description} variant='h5' textAlign='left'>
                  {t('pages.homePage.description1')}
                </Typography>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // marginLeft='100px'
                // marginRight={matches ? '108px' : '370px'}
                marginLeft={matches ? '500px' : '500px'}
                data-aos="fade-up"
                display="flex"
              >
                <div>
                  <Typography className={classes.gradientTitle} fontWeight='bold' variant='h2' marginTop={15} marginBottom={2} textAlign='left'>
                    {t('pages.homePage.description2Title')}
                  </Typography>
                  <Typography className={classes.description} variant='h5' textAlign='left'>
                    {t('pages.homePage.description2')}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                lg={8}
                xl={8}
                md={7}
                sm={12}
                xs={12}
                // marginRight='700px'
                data-aos="fade-up"
              >
                <div>
                  <Typography className={classes.gradientTitle} fontWeight='bold' variant='h2' marginTop={15} marginBottom={2} textAlign='left'>
                    {t('pages.homePage.description3Title')}
                  </Typography>
                  <Typography className={classes.description} variant='h5' textAlign='left' mb={3}>
                    {t('pages.homePage.description3')}
                  </Typography>
                  <Typography className={classes.description} variant='h5' textAlign='left'>
                    {t('pages.homePage.description4')}
                  </Typography>
                </div>
              </Grid>
            </Grid>

          </Container>
          <Box
            backgroundColor='#18bdb5'
            mt={20}
            mb={-10}
            height={500}
            display='flex'
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              id="gsap-text-main-id"
              container
              direction="column"
              display='flex'
              alignItems="center"
              justifyContent="center"
              // marginBottom={isSafari ? '500px' : '0px'}
              className={classes.parallaxTextGridContainer}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <Typography id="gsap-text" className={classes.parallaxText} >
                  {t('pages.homePage.targetDevice')}
                </Typography>
              </Grid>
              <Grid
                container
                display='flex'
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  lg={10}
                  sm={10}
                  xl={10}
                  xs={10}
                  mt={10}
                >
                  <Typography variant='h3' fontWeight="bold" color="white" textAlign="center">
                    {t('pages.homePage.buyDescription1_2')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>


      <Box
        sx={{
          background: 'white',
          overflow: 'hidden'
        }}>

        <Container>
          <Grid
            container
            alignItems="center"
            justify="center"
            marginBottom='10vh'
            marginTop='10vh'
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{
                textAlign: 'center'
              }}
            >
              <div>
                <Typography className={classes.lastText} textAlign='center' variant='h3' marginBottom="50px" fontWeight='bold' style={{ color: '#607d8b' }}>
                  {t('pages.homePage.buyDescription2')}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingRight: 25
              }}
            >

              <Button className={classes.button} disableElevation variant='contained' href="https://aocsystem.com" rel="noreferrer" target="_blank" >{t('common.button.buyButton')}</Button>


            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            marginBottom={-40}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{
                textAlign: 'center',
              }}
            >
              <img src={worldMap}
                width='100%'
                alt="world-map-dotted"
                style={{ opacity: 0.20 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment >
  )
}

export default HomeDesktop;