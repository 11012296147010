import Element from '../../components/Element/Element'
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import NavSecondary from '../../components/Navigation/NavSecondary';
import { useTranslation } from 'react-i18next';
import {
  styled,
  useTheme
} from '@material-ui/core/styles';
import theme from '../../theme/dpoStoreTheme'
import { ThemeProvider } from '@material-ui/core/styles';

function BusinessAndOffice(props) {
  const { t, } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Paper>
        <Box
          sx={{
            background: 'linear-gradient(90deg, rgba(255,255,255,0.8519782913165266) 17%, rgba(77,145,206,1) 100%)',
            minHeight: '100vh',
            py: 10
          }}
        >
          {/* <NavSecondary
          title={t('products.dpoStore.title')}
          color='primary'
          anchors={
            [
              { text: t('products.salesActivity.navigation.advantages'), href: '/' },
              { text: t('products.salesActivity.navigation.workflow'), href: '/' },
              { text: t('products.salesActivity.navigation.prices'), href: '/' },
            ]
          }
        /> */}
          {/* <Typography variant='h3' margin={'80px 20px'} data-aos="fade-right">{t('products.dpoStore.title')}</Typography> */}
          <Container maxWidth={false}>
            <Grid container display='flex' flexDirection='column' alignItems='center' justifyContent='center' height={'75vh'}>
              <Typography variant="h3" color='secondary' fontWeight='bold' data-aos="fade-up">Hamarosan...</Typography>
            </Grid>
            <Grid
              container
              spacing={7}
            >
              {/* {[1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000].map((item, index) => {
              return <Grid
                key={index}
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                data-aos="fade-up"
              >
                <Element background='#4D91CE' title={item}></Element>
              </Grid>
            })
            } */}
            </Grid>
          </Container>
        </Box>
      </Paper>
    </ThemeProvider>
  )
}

export default BusinessAndOffice;