import { Box, Container, Divider, Grid, Typography, } from "@material-ui/core"
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
// import 'swiper/swiper-bundle.css';
import SwiperCore, { Pagination } from "swiper";

SwiperCore.use([Pagination]);

const costumerInfo = [
  {
    logo: null,
    companyName: 'asara',
    quote: 'I really really like this company',
    costumerName: 'Ash George',
    costumerPosition: 'director of justice ',
  },
  {
    logo: null,
    companyName: 'facebike',
    quote: 'I dont really like this company',
    costumerName: 'mark',
    costumerPosition: 'junior Developer',
  },
  {
    logo: null,
    companyName: 'gaggle',
    quote: 'I really really like this company I really really like this company I really really like this company',
    costumerName: 'Tin Lin',
    costumerPosition: 'sales guy ',
  },
  {
    logo: null,
    companyName: 'illuminati',
    quote: 'I dont really like this company I really really like this company I really really like this company I really really like this company I really really like this company I really really like this company I really really like this company',
    costumerName: 'mark',
    costumerPosition: 'junior Developer',
  },
  {
    logo: null,
    companyName: 'asara',
    quote: 'I really really like this company',
    costumerName: 'Ash George',
    costumerPosition: 'director of justice ',
  },
  {
    logo: null,
    companyName: 'facebike',
    quote: 'I dont really like this company',
    costumerName: 'mark',
    costumerPosition: 'junior Developer',
  }
]


const AboutUs = (props) => {
  const [currentCustomer, setCurrentCustomer] = useState(0)
  const ref = useRef(null)
  const [swiperRef, setSwiperRef] = useState();
  //console.log(currentCustomer, "ko")
  const handleChange = (event, value) => {
    setCurrentCustomer(value);
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };
  //console.log(costumerInfo[currentCustomer].quote.length)
  return (
    <Grid
      width={600}
    >
      <Container
      >
        <Divider />
        <Grid
          container
          width={600}
          height={300}
        >
          <Grid
            marginLeft={3}
            mt={3}
          >
            {costumerInfo[currentCustomer].logo === null
              && (
                <Box>
                  <Typography>
                    {costumerInfo[currentCustomer].companyName}
                  </Typography>
                </Box>
              )
            }
          </Grid> 
          <Grid
            container
            align-items="center"
            justifyContent="center"

          >
            <Grid
              width={500}
              height={200}
            >
              <Box>
                <Typography variant="h4" fontWeight='bold' fontSize='auto'>
                  "{costumerInfo[currentCustomer].quote}"
                </Typography>
              </Box>
              <Box
                mt={3}
                ml={4}
                sx={{ fontStyle: 'italic' }}
              >
                <Typography>
                  {costumerInfo[currentCustomer].costumerName} // {costumerInfo[currentCustomer].costumerPosition}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          sx={{
            mb: costumerInfo[currentCustomer].quote.length > 200 && 10,
            mt: costumerInfo[currentCustomer].quote.length > 200 && 10
          }}
        />
        <Grid
          display="flex"
          justifyContent='space-between'
        >
          <Grid
            display="flex"
            flexDirection="column"
            mb={50}
          >
            {  /*  <Pagination
              count={5}
              page={currentCustomer}
              onChange={handleChange}
   
        />*/}
            <Swiper
              pagination={pagination}
              modules={[Pagination]}
              centeredSlides={true}
              initialSlide={currentCustomer}
              onSwiper={(event) => setCurrentCustomer(event.realIndex)}
              className="mySwiper"
              autoplay={{
                "delay": 2500,
                "disableOnInteraction": true,
              }}
              onSlideChange={(event) => {
                console.log(event.realIndex)
                setCurrentCustomer(event.realIndex + 1)
              }}
            >
              <SwiperSlide>1</SwiperSlide>
              <SwiperSlide>2</SwiperSlide>
              <SwiperSlide>3</SwiperSlide>
              <SwiperSlide>4</SwiperSlide>
              <SwiperSlide>4</SwiperSlide>
            </Swiper>
          </Grid>
          <Grid>
            <Box>
              <Typography>
                Meet our customers
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Swiper
          loop
          // install Swiper modules
          modules={[Pagination]}
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            "delay": 2500,
            "disableOnInteraction": true,
          }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <Box sx={{ height: '100px', background: '#f6f6f7' }}>
              <Typography>Slidde 1</Typography>
            </Box>
          </SwiperSlide>
          <SwiperSlide sx={{ height: '100px' }}>Slide 2</SwiperSlide>
          <SwiperSlide sx={{ height: '100px' }}>Slide 3</SwiperSlide>
          <SwiperSlide sx={{ height: '100px' }}>Slide 4</SwiperSlide>
        </Swiper>
      </Container>
    </Grid>
  )
}
export default AboutUs;