import React, { useEffect, useState } from "react";
import { Box, Toolbar, Button, Grid, IconButton, ListItem, ListItemText, AppBar, Hidden, Typography, ClickAwayListener, Container, } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MenuDropdown from './Menu';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'
import Logo from "../Logo";


// TODO: styles
const useStyles = makeStyles(theme => ({
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 20px',
    textDecoration: 'none',
    color: '#5e5e5e',
    cursor: 'pointer',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listItemLink: {
    textDecoration: 'none',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divider: {
    width: '100%',
  },
  buttonMobile: {
    padding: '10px 10px',
    borderRadius: 30
  },
  button: {
    padding: '10px 20px',
    borderRadius: 30
  },
  glass: {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    backdropFilter: 'blur(30px)',
    border: '1px solid #FFFFFF',
  },
  glassMobile: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
    backdropFilter: 'blur(30px)',
  },
  navSm: {
    marginTop: 0,
    transition: '0.25s',
  },
  navLg: {
    marginTop: 80,
    transition: '0.25s',
  },
  toolbar: {
    height: 70,
    transition: '0.25s',
    //margin: '0px -15px 0px -25px'
  },
  open: {
    height: 220,
    transition: '0.25s'
  },
  openLink: {
    height: 150,
    width: '100%',
    transition: '0.25s'
  },
  closedLink: {
    height: 0,
    width: '100%',
    transition: '0.25s'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: '0.25s',
  },
  linkOpen: {
    opacity: 1,
    transition: '0.25s',
  },
  linkClosed: {
    opacity: 0,
    transition: '0.25s',
  },
  visible: {
    // position: 'fixed',
    // bottom: '0.1rem',
    // left: '0.1rem',
    cursor: 'pointer',
    width: '100px',
    height: '50px',
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.3s linear'
  },
  hidden: {
    // position: 'fixed',
    // bottom: '0.1rem',
    // right: '0.1rem',
    cursor: 'pointer',
    width: '100px',
    height: '50px',
    visibility: 'hidden',
    transition: 'visibility 0s 0.3s, opacity 0.3s linear',
    visibility: 'hidden',
    opacity: 0,


  }
}));

export default function NavSecondary(props) {
  const { salesActivity } = props;
  const { t, } = useTranslation();
  const classes = useStyles();
  const navigationLinks = props.anchors || []
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [open, setOpen] = useState(false);
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100)
      );
      window.addEventListener("resize", () =>
        window.innerWidth > 900 && setOpen(false)
      );
    }
  }, []);

  const renderMenu = (text, href, children, offset, index) => {
    if (children) {
      return <MenuDropdown items={children} button={text} className={classes.listItem} />
    }
    return <ScrollLink
      key={index}
      to={href}
      color="default"
      className={classes.listItem}
      spy={true}
      smooth={true}
      offset={offset}
    >
      <Typography variant='subtitle2'>
        {text}
      </Typography>
    </ScrollLink>
  }

  const scrollToTop = () => {
    scroll.scrollToTop();
  }


  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="primary" elevation={0} className={`${classes.glass} ${small ? classes.navSm : classes.navLg}`}>
        <Toolbar className={`${classes.toolbar} ${open ? classes.open : ''}`}>
          <Hidden mdDown={!salesActivity} lgDown={salesActivity}>
            <Container maxWidth='xl' >
              <Grid container justifyContent='space-between' alignContent='center'>
                <Grid item className={classes.title} onClick={scrollToTop}>
                  {/* TODO -26px is wrong solution  */}
                  <Logo height='50' className={small ? classes.visible : classes.hidden} />
                  <Typography
                    textAlign='center'
                    variant='h5'
                    ml={1}
                    // color={props.color}
                    style={{ color: '#ababab' }}
                  >
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems='center'>
                    {navigationLinks.map(({ text, href, children, offset, buyUrl }, index) => renderMenu(text, href, children, offset, buyUrl, index))}
                    <Button className={classes.button} disableElevation variant='contained' color={props.buttonColor || props.color} style={{ 'margin': 5 }} href={props.buyUrl} target="_blank" >{t('products.buy')}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Hidden>
          <Hidden mdUp={!salesActivity} lgUp={salesActivity}>
            <Grid container direction="column" justifyContent="space-between" alignItems="center" >
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item display='flex' direction='row' alignItems="center" onClick={scrollToTop}>
                  <ListItem>
                    <Typography variant='h6' color={props.color}>{props.title}</Typography>
                  </ListItem>
                </Grid>
                <Grid item>
                  <IconButton onClick={toggleDrawer(!open)}>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Button className={classes.buttonMobile} disableElevation variant='contained' color={props.buttonColor || props.color} href={props.buyUrl} target="_blank" >Vásárlás</Button>
                </Grid>
              </Grid>
              <Grid container className={`${classes.links} ${open ? classes.openLink : classes.closedLink}`} >
                {/* <Grid container className={`${classes.links} ${open ? classes.openLink : classes.closedLink}`} > */}
                {navigationLinks.map(({ text, href, offset }, index) => <ScrollLink offset={offset} smooth={true} key={index} to={href} className={`${classes.listItem} ${open ? classes.linkOpen : classes.linkClosed}`} style={{ 'width': '100%' }}>
                  <ListItem button key={index} divider={index < navigationLinks.length - 1}>
                    <ListItemText primary={text} />
                  </ListItem>
                </ScrollLink>)}
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
