import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import FullScreenDialog from '../Modals/FullScreenDialog';
import ScrollList from '../ScrollList/ScrollList';

const useStyles = makeStyles(theme => ({
  root: {

  },
  targetCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 200,
    height: 130
  },
  circle: {
    border: '2px dashed white',
    transition: '0.35s',
    opacity: 1,
    borderRadius: 100,
    height: 60,
    width: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  node: {
    '&:hover': {
      opacity: 0.5
    }
  }
}));


export default function TreeView(props) {
  const classes = useStyles();
  const nodeSize = { 'x': 300, 'y': 200, }

  const renderForeignObjectNode = (
    rd3tProps,
    {
      nodeDatum,
      //toggleNode,
    }) => <g>
      {nodeDatum.last && nodeDatum.name && <svg overflow='visible'>
        <path class={props.startAnimation ? "custom-link-animation" : "custom-link"} fill="transparent" d="M -200 0 C -50 0 -50 -200 100 -200"></path>
      </svg>}
      <rect
        width='200'
        height='130'
        x='-200'
        y='-65'
        stroke={'#07B1AB'}
        strokeWidth={0}
        fill='#56cac6'
        rx='5'
      />
      {nodeDatum.name ?
        <g className={classes.node}>
          <foreignObject height='125' width='200' x='-200' y='-65'>
            <FullScreenDialog
              button={<foreignObject height='125' width='200' x='-200' y='-65'>
                <div style={{ pointerEvents: 'auto', height: 125, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant='body1' color='white' style={{ 'textAlign': 'center', }}>{nodeDatum.name} </Typography>
                </div></foreignObject>}
              title={nodeDatum.name}
            >
              <ScrollList startIndex={rd3tProps.hierarchyPointNode.depth - 1} />
            </FullScreenDialog>
          </foreignObject>
        </g>
        : <g>
          {nodeDatum.last ? <g>

            <rect
              width='200'
              height='130'
              x='1900'
              y='135'
              stroke={'#07B1AB'}
              strokeWidth={0}
              fill='#56cac6'
              rx='5'
            />

            <foreignObject height='125' width='200' x='1900' y='135'>
              <div style={{ height: 125, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='body1' color='white' style={{ 'textAlign': 'center', }}>Készen is van!</Typography>
              </div>
            </foreignObject>

            <svg overflow='visible'>
              <path class={props.startAnimation ? "custom-link-animation" : "custom-link"} fill="transparent" d="M 0 0 C 419.839 0 419.839 200 839.679 200 L 1900 200"></path>
            </svg>
            <foreignObject height='125' width='200' x='-200' y='-65'>
              <div style={{ height: 125, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Grid
                  item
                  lg={4}
                  sm={4}
                  xl={4}
                  xs={4}
                  className={classes.targetCard}
                  id='targetCard'
                >
                  <div className={classes.circle}>
                    <Typography color='white' variant='h5'>+</Typography>
                  </div>
                </Grid>
              </div>
            </foreignObject>
          </g>

            : <foreignObject height='125' width='200' x='-200' y='-65'>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={4}
                className={classes.targetCard}
                id='targetCard'
              >
                <div className={classes.circle}>
                  <Typography color='white' variant='h5'>+</Typography>
                </div>
              </Grid>
            </foreignObject>}
        </g>}
    </g>

  const renderForeignObjectNodeMobile = (rd3tProps, {
    nodeDatum,
    toggleNode,
  }) => <g>
      {nodeDatum.last && nodeDatum.name && <svg overflow='visible'>
        <path class={props.startAnimation ? "custom-link-animation" : "custom-link"} fill="transparent" d="M -184.67 32.878 C -122.567 32.878 -122.567 183.386 -60.464 183.386 L -17 183.386" transform="matrix(0, 1, -1, 0, 30.083, 186.180992)"></path>
      </svg>}
      <rect
        width='150'
        height='80'
        x={'-75'}
        y={'-75'}
        stroke={'#07B1AB'}
        strokeWidth={0}
        fill='#56cac6'
        rx='5'
        zIndex='100'
      />
      {nodeDatum.name ?
        <g>
          <foreignObject height='125' width='200' x='-100' y='-100'>
            <FullScreenDialog
              button={<foreignObject height='125' width='200' x='-200' y='-100'>
                <div style={{ height: 125, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                  <Typography variant='body1' color='white' style={{ 'textAlign': 'center', maxWidth: 125 }}>{nodeDatum.name} </Typography>
                </div></foreignObject>}
              title={nodeDatum.name}
            >
              <ScrollList startIndex={rd3tProps.hierarchyPointNode.depth - 1} />
            </FullScreenDialog>
          </foreignObject>
        </g>
        : <g>
          {nodeDatum.last ? <g>

            <rect
              width='150'
              height='80'
              x={'0'}
              y={'1070'}
              stroke={'#07B1AB'}
              strokeWidth={0}
              fill='#56cac6'
              rx='5'
            />

            <foreignObject height='125' width='200' x='-25' y='1050'>
              <div style={{ height: 125, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='body1' color='white' style={{ 'textAlign': 'center', }}>Készen is van!</Typography>
              </div>
            </foreignObject>

            <svg overflow='visible'>
              <path class={props.startAnimation ? "custom-link-animation" : "custom-link"} fill="transparent" d="M -317.217 393.428 C -167.217 393.428 -167.217 323.428 -17.217 323.428 L 750 323.428" transform="matrix(0, 1, -1, 0, 395.345001, 321.511017)"></path>
            </svg>
            <foreignObject height='125' width='200' x='-100' y='-100'>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={4}
                className={classes.targetCard}
                id='targetCard'
              >
                <div className={classes.circle}>
                  <Typography color='white' variant='h5'>+</Typography>
                </div>
              </Grid>
            </foreignObject>
          </g>

            : <foreignObject height='125' width='200' x='-100' y='-100'>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={4}
                className={classes.targetCard}
                id='targetCard'
              >
                <div className={classes.circle}>
                  <Typography color='white' variant='h5'>+</Typography>
                </div>
              </Grid>
            </foreignObject>}
        </g>}
    </g>

  return (
    <div id="treeWrapper"
      style={{
        width: '95%',
        height: '90%',
        pointerEvents: 'none'
      }
      }>
      <Tree
        zoom={props.zoom}
        orientation={props.orientation}
        data={props.data}
        enableLegacyTransitions
        zoomable={false}
        rootNodeClassName="node__root"
        branchNodeClassName="node__branch"
        leafNodeClassName="node__leaf"
        pathClassFunc={() => ['custom-link', props.startAnimation && ('custom-link-animation')].join(' ')}
        // nodeSize={nodeSize}
        // translate={
        //   props.orientation === 'horizontal'
        //     ? { 'x': window.innerWidth / 7.5, 'y': window.innerHeight / 3 }
        //     : { 'x': window.innerWidth / 2, 'y': window.innerHeight / 8 }}
        translate={
          props.orientation === 'horizontal'
            ? { 'x': 250, 'y': 350 }
            : { 'x': window.innerWidth / 2, 'y': window.innerHeight / 8 }}
        renderCustomNodeElement={(rd3tProps) => props.orientation === 'vertical' ? renderForeignObjectNodeMobile(rd3tProps, { ...rd3tProps, }) : renderForeignObjectNode(rd3tProps, { ...rd3tProps, })}
        nodeSize={props.nodeSize || nodeSize}
        separation={props.orientation === 'horizontal'
          ? {
            'siblings': 2,
            'nonSiblings': 2,
          }
          : {
            'siblings': 1,
            'nonSiblings': 1,
          }}
      />
    </div >
  );
}