import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Box, Toolbar, Button, Grid, IconButton, ListItem, Avatar, ListItemText, Drawer, AppBar, CssBaseline, List, Divider, Typography, Container, } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import theme from '../../theme/index'
import Routes from "../../routes";
import {
  styled,
  // useTheme 
} from '@material-ui/core/styles';
import { ChevronLeftRounded } from '@material-ui/icons/';
import Logo from '../Logo';
import Hidden from '@material-ui/core/Hidden';
import { Link as ScrollLink } from 'react-scroll'
import MenuDropdown from './Menu';

import ListItemButton from '@material-ui/core/ListItemButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import salesActivityLogo from "../../static/public/sales-activity-logo.svg"
import businessAndOfficeLogo from "../../static/public/business-and-office-logo.svg"
import dpoStoreLogo from "../../static/public/dpo-store-logo.svg"
import dlogo from "../../static/public/dlogo.png"
// TODO: styles
const useStyles = makeStyles(({
  main: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0px 20px',
    textDecoration: 'none',
    color: '#5e5e5e',
    cursor: 'pointer',
  },
  listItemLink: {
    textDecoration: 'none',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  divider: {
    width: '100%',
  },
  button: {
    padding: '10px 20px',
    borderRadius: 30
  },
  glass: {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    backdropFilter: 'blur(25px)',
  },
  // glassMobile: {
  //   backgroundColor: 'rgba(255, 255, 255, .75)',
  //   backdropFilter: 'blur(30px)',
  // },
  toolbar: {
    height: 80,
    transition: '0.25s',
  },
  small: {
    height: 70,
    transition: '0.25s',
  },
  center: {
    // display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // width: '50%',
  },
  // selectedListItem: {
  //   backgroundColor: 'red',
  //   '&selected': {
  //     backgroundColor: 'black'
  //   }
  // }
}));

const drawerWidth = 320;

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(10),
  }),
);

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


export default function Navigation(props) {
  const { t, } = useTranslation();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    //console.log(index, selectedIndex)
    if (index === selectedIndex) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(index);
    }
  };


  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedIndex(0);
  };

  const [expand, setExpand] = React.useState(false);

  // TODO: Only open clicked menu
  const handleClick = (e) => {
    setExpand(!expand);
  };

  const renderMenu = (text, href, children, index) => {
    if (children) {
      return <MenuDropdown key={index} items={children} button={text} className={classes.listItem} />
    }
    return <Link key={index} to={href} color="default" className={classes.listItem}>
      <Typography variant='subtitle2'>
        {text}
      </Typography>
    </Link>
  }

  const navigationLinks = [
    { text: t('navigation.home2'), href: '/' },
    // { text: t('navigation.advantages'), href: '/advantages' },
    // {
    //   text: t('navigation.products'), href: '/products', children: [
    //     { text: t('navigation.salesActivity'), href: '/products/sales-activity', logo: salesActivityLogo },
    //     { text: t('navigation.businessAndOffice'), href: '/products/business-and-office', logo: businessAndOfficeLogo },
    //     //{ text: t('navigation.dpoStore'), href: '/products/dpo-store', logo: '/dpo-store-logo.svg' },
    //     { text: t('navigation.comingSoon'), href: '/products/dpo-store', logo: dpoStoreLogo, disabled: true },
    //   ]
    // },
    // { text: t('navigation.prices'), href: '/prices' },
    {
      // text: t('navigation.about'), href: '/about', children: [
      //   // { text: t('navigation.aboutUs'), href: '/about' },
      //   // { text: t('navigation.career'), href: '/career' },
      //   // { text: t('navigation.contact'), href: '/contact' },
      //   // { text: t('navigation.imprint'), href: '/imprint' },
      //   // { text: t('navigation.requestTrial'), href: '/request-trial' },
      //   // { text: t('navigation.requestTrial'), href: '/contact' },
      // ]
    },
    // { text: t('navigation.features'), href: '/features' },
  ]

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100)
      );
    }
  }, []);

  return (
    <StyledBox sx={{ flexGrow: 1 }}>
      <Divider />

      <CssBaseline />
      <StyledAppBar position="fixed" open={open} color="primary" elevation={0} className={classes.glass}>
        <Container maxWidth='xl' disabledgutters>
          <Toolbar className={`${classes.toolbar} ${small ? classes.small : ""}`} id="top">
            <Hidden mdDown>
              {/* <Grid item sx={{ flexGrow: 1 }}>
                <ScrollLink activeClass="active" to="topHome" spy={true} smooth={true} offset={-50} duration={500}>
                  <Link to="/">
                    <Logo height='50' marginRight='30' style={{transform: 'rotate(90deg)' }}/>
                  </Link>
                </ScrollLink>
              </Grid> */}
              <Grid item sx={{ flexGrow: 20 }}>
                <Grid container className={classes.main}>
                  {navigationLinks.map(({ text, href, children }, index) => renderMenu(text, href, children, index))}

                </Grid>

              </Grid>



            </Hidden>
            <Hidden mdUp>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <IconButton
                    color="default"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                    sx={{ ...(open && { display: 'none' }) }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Link to="/" className={classes.center}
                    style={{
                      textDecoration: 'none',
                      color: "default"
                    }}
                  >
                    <Typography color="text.primary" style={{ textTransform: 'uppercase ',fontWeight:'bold' }}
                    >
                      Dilato
                    </Typography>
                    {/* <Logo height='50' /> */}
                  </Link>
                </Grid>
                <Grid item>
                  {/* <Button className={classes.button} disableElevation variant='contained' color='secondary' href="https://my.aocsystem.com/products" target="_blank" >{t('navigation.registration')}</Button> */}
                </Grid>
              </Grid>
            </Hidden>
          </Toolbar>
        </Container>
      </StyledAppBar>
      <Drawer
        classes={{ paper: classes.glassMobile }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        //variant="temporary"
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{ position: 'absolute', right: 10 }}>
            {<ChevronLeftRounded />}
          </IconButton>
        </DrawerHeader>
        {/* <Divider />
        <List>
          <a href="https://my.aocsystem.com/login" target="_blank" className={classes.listItem}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={t('navigation.login')} />
            </ListItem>
          </a>
          <a href="https://my.aocsystem.com/products" target="_blank" className={classes.listItem}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={t('navigation.registration')} />
            </ListItem>
          </a>
        </List>
        <Divider /> */}
        <List>
          {navigationLinks.map(({ text, href, children }, index) => <div key={index}>
            {!children
              ? <Link key={index} to={href} className={classes.listItem}>

                <ListItem button key={index} onClick={handleDrawerClose}>
                  <ListItemText primary={text} />
                </ListItem>
              </Link>
              : <div>
                <ListItemButton
                  // className={classes.selectedListItem}
                  style={{ backgroundColor: 'white' }}
                  // onClick={handleClick}
                  onClick={(event) => handleListItemClick(event, index)}
                  selected={selectedIndex === index}>
                  <ListItemText primary={text} />
                  {selectedIndex === index ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {children.map((child) => <Link key={child.index} className={classes.listItem} to={child.disabled ? '#' : child.href}>
                      <ListItem disabled={child.disabled} button key={index} onClick={handleDrawerClose}>
                        {child.logo && <Logo src={child.logo} height={20} />}
                        <ListItemText primary={child.text} style={{ 'marginLeft': 10 }} />
                      </ListItem>
                    </Link>
                    )}
                  </List>
                </Collapse>
              </div>}
          </div>)}
        </List>
      </Drawer>
    </StyledBox >
  );
}

Navigation.propTypes = {
  className: PropTypes.string,
}